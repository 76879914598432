import { ColumnProps } from 'components/Table/types'
import { memo } from 'react'
import { If } from 'react-extras'
import { Container, Header, StyledTable, Subheader } from './styles'

interface StatisticTableProps {
  header: string
  subheader?: string
  data: any
  columns: ColumnProps[]
}

const StatisticTable = ({
  header,
  subheader,
  data,
  columns
}: StatisticTableProps) => {
  if (!data?.length) return null
  return (
    <Container>
      <Header>{header}</Header>
      <If condition={subheader?.length ? true : false}>
        <Subheader>{subheader}</Subheader>
      </If>
      <StyledTable data={data} columns={columns} />
    </Container>
  )
}

export default memo(StatisticTable)
