import { isEmpty } from 'lodash'
import { createContext, useCallback, useEffect, useState } from 'react'
import { Seasons } from 'store/ducks'
import StatisticFilters, {
  FilterDataProps
} from '../containers/Statistics/Filters'
import { StatisticProps, getStatistics } from '../services/statistic.service'

interface StatisticPageContextProps {
  statistics: StatsProps
  renderFilters: () => JSX.Element
  isLoading: boolean
  filterData: FilterDataProps
  activeTab: Seasons
  setActiveTab: (season: Seasons) => void
}
interface StatsProps {
  [Seasons.WINTER]: StatisticProps
  [Seasons.SUMMER]: StatisticProps
}

interface StatisticPageProviderProps {
  children: JSX.Element
}

export const StatisticPageContext = createContext(
  {} as StatisticPageContextProps
)

StatisticPageContext.displayName = 'Contact Page Context'

const StatisticPageProvider = ({ children }: StatisticPageProviderProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<Seasons>(Seasons.WINTER)
  const { data: filterData, renderFilters } = StatisticFilters()
  const [statistics, setStatistics] = useState({
    [Seasons.WINTER]: {} as StatisticProps,
    [Seasons.SUMMER]: {} as StatisticProps
  })

  const loadStatistics = useCallback(async (filters?: any) => {
    if (isEmpty(filters)) return
    setIsLoading(true)
    const winterStats = await getStatistics({
      ...filters,
      filterSeason: Seasons.WINTER
    })

    const summerStats = await getStatistics({
      ...filters,
      filterSeason: Seasons.SUMMER
    })
    setStatistics(statistics => {
      return {
        ...statistics,
        [Seasons.WINTER]: winterStats,
        [Seasons.SUMMER]: summerStats
      }
    })

    setIsLoading(false)
  }, [])

  useEffect(() => {
    loadStatistics()
  }, [loadStatistics])

  useEffect(() => {
    const winter = statistics?.[Seasons.WINTER]
    const summer = statistics?.[Seasons.SUMMER]
    const isWinterEmpty = !winter?.totalInjuries && summer?.totalInjuries
    const isSummerEmpty = !summer?.totalInjuries && winter?.totalInjuries
    const notEmpty = winter?.totalInjuries && summer?.totalInjuries
    if (notEmpty) return
    if (isWinterEmpty) return setActiveTab(Seasons.SUMMER)
    if (isSummerEmpty) return setActiveTab(Seasons.WINTER)
  }, [statistics])

  useEffect(() => {
    const { minAge, maxAge, endDate, startDate } = filterData

    const resortId = filterData.resort !== 'ALL' ? filterData.resort : ''
    const gender = filterData.gender !== 'ALL' ? filterData.gender : ''
    const filterSeason = filterData.season
    const nationality =
      filterData.nationality !== 'ALL' ? filterData.nationality : ''

    loadStatistics({
      resortId,
      gender,
      filterSeason,
      nationality,
      minAge,
      maxAge,
      endDate,
      startDate
    })
  }, [filterData, loadStatistics])

  const value: StatisticPageContextProps = {
    statistics,
    renderFilters,
    isLoading,
    filterData,
    activeTab,
    setActiveTab
  }

  return (
    <StatisticPageContext.Provider value={value}>
      {children}
    </StatisticPageContext.Provider>
  )
}

export default StatisticPageProvider
