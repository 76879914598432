import { IconShowHideSidebar } from 'assets/images'
import Sidebar from 'components/Sidebar'
import { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { theme } from 'styles/theme'

interface ILayout {
  children: ReactNode
}

const LayoutContainer = styled.section<{ expanded: boolean }>`
  transition: margin-left 0.5s;
  margin-left: ${({ expanded }) =>
    expanded ? `${theme.SIDEBAR_WIDTH_MAX}px` : `${theme.SIDEBAR_WIDTH_MIN}px`};
  height: 100vh;
  position: relative;
`

const TogglerContainer = styled.div<{ $expanded: boolean }>`
  width: 20px;
  cursor: pointer;
  padding: 1rem 0 1rem 1rem;
  &:hover {
    svg {
      opacity: 0.6;
    }
  }
  ${props =>
    props.$expanded
      ? {
          svg: {
            transform: 'initial'
          }
        }
      : {
          svg: {
            transform: ' rotate(180deg)'
          }
        }}
`

const Layout = ({ children }: ILayout) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  return (
    <>
      <Sidebar expanded={expanded} />
      <LayoutContainer expanded={expanded}>
        <TogglerContainer
          onClick={() => setExpanded(!expanded)}
          $expanded={expanded}
        >
          <IconShowHideSidebar fill="#000" />
        </TogglerContainer>
        {children}
      </LayoutContainer>
    </>
  )
}

export default Layout
