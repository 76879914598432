import { IconExit } from 'assets/images'
import { Button, Logo } from 'components'
import useAuthSession from 'hooks/useAuthSession'
import useLogout from 'hooks/useLogout'
import { t } from 'i18n'
import {
  LogoContainer,
  PendingAccountContainer,
  PendingAccountSubtitle,
  PendingAccountTitle,
  SignOutContainer
} from './styled'

const PendingAccountPage = () => {
  const { mutate: logout } = useLogout()
  const { isAuthLoading } = useAuthSession()

  return (
    <PendingAccountContainer>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <PendingAccountTitle>{t('pendingAccountTitle')}</PendingAccountTitle>
      <PendingAccountSubtitle>
        {t('pendingAccountSubtitle')}
      </PendingAccountSubtitle>
      <SignOutContainer>
        <Button
          variant="primary"
          icon={<IconExit />}
          onClick={() => logout()}
          disabled={isAuthLoading}
          type="button"
          label="logout"
        />
      </SignOutContainer>
    </PendingAccountContainer>
  )
}

export default PendingAccountPage
