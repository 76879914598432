import { AppBar, Box, Button, Dialog, IconButton, Toolbar } from '@mui/material'
import { IconClose } from 'assets/images'
import { IconGoogleMapPin } from 'assets/images/google-map-pin'
import { t } from 'i18n'
import { isEmpty } from 'lodash'
import {
  FullImgPreview,
  SmallImgPreview,
  StyledLoading
} from 'pages/Slopes/styled'
import { useCallback, useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { uploadHeatmap } from 'services'
import { ApplicationState } from 'store'
import { MarkerPosition, ReportState, Seasons, UserInfo } from 'store/ducks'
import { removeHeatmapLocation } from 'store/ducks/report/actions'
import styled from 'styled-components'

interface Props {
  user: UserInfo
  report: ReportState
  handleOnChange: (
    coordinates: MarkerPosition & { reportId: string | undefined }
  ) => void
  handleClearPosition: () => void
  coordinates: MarkerPosition
}

type ImageDimensions =
  | {
      width: number
      height: number
    }
  | Record<string, never>

const ImageWrapper = styled(Box)<{ $width: number }>`
  width: ${({ $width }) => $width}px;
  height: 100%;
  background-color: #eeeeee;
  position: relative;
`

const HeatMapWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  align-items: flex-end;
`

const AddLocationHeatmap = ({
  user,
  report,
  handleOnChange,
  handleClearPosition,
  coordinates
}: Props) => {
  const [preview, setPreview] = useState('')
  const [loading, setLoading] = useState(false)
  const [imageDimensions, setImageDimensions] = useState<ImageDimensions>({})
  const [open, setOpen] = useState(false)
  const [displayWidth, setDisplayWidth] = useState(450)
  const [displayHeight, setDisplayHeight] = useState(0)
  const [markerPosition, setMarkerPosition] =
    useState<MarkerPosition>(coordinates)
  const imgRef = useRef<HTMLImageElement>(null)
  const dispatch = useDispatch()

  const {
    data: { SK, season }
  } = report || {}
  const { resort } = user || {}

  let fileName = ''
  if (season === Seasons.WINTER) {
    fileName = resort?.heatmapBySeasons?.winter?.fileName || ''
  } else if (season === Seasons.SUMMER) {
    fileName = resort?.heatmapBySeasons?.summer?.fileName || ''
  }

  useEffect(() => {
    if (open) {
      const handleResize = () => {
        if (imgRef.current) {
          const height = imgRef.current.offsetHeight
          setDisplayHeight(height)
        }

        const originalImageWith = imageDimensions.width
        const windowWidth = window.innerWidth
        const width =
          originalImageWith >= windowWidth ? windowWidth : originalImageWith

        setDisplayWidth(width)
      }

      window.addEventListener('resize', handleResize)

      return () => window.removeEventListener('resize', handleResize)
    }
  }, [imageDimensions.width, open])

  const handleUpload = useCallback(async () => {
    setLoading(true)
    if (fileName) {
      try {
        const body = {
          fileName,
          type: 'GET_OBJECT',
          relatedRecordType: 'RESORT',
          season: season?.toLowerCase()
        }
        const data = await uploadHeatmap(body)
        setPreview(data.url)
        const img = new Image()
        img.src = data.url

        img.onload = () => {
          setImageDimensions({
            height: img.height,
            width: img.width
          })
          setDisplayHeight(img.height)
        }
      } catch (error: any) {
        toast.error(error.response.data.message, {
          position: 'top-right'
        })
      } finally {
        setLoading(false)
      }
    }
  }, [fileName, season])

  useEffect(() => {
    const handleUploadData = async () => {
      await handleUpload()
    }

    handleUploadData().catch(error => {
      console.error(error)
    })
  }, [handleUpload])

  if (!imageDimensions.width || !imageDimensions.height) return null

  const handleClickOpen = () => {
    setOpen(true)
    const originalImageWith = imageDimensions.width
    const windowWidth = window.innerWidth
    const width =
      originalImageWith >= windowWidth ? windowWidth : originalImageWith
    setDisplayWidth(width)
  }

  const saveCoordinates = ({ x, y }: { x: number; y: number }) => {
    const coordinates = {
      reportId: SK,
      x,
      y
    }
    setMarkerPosition({ x, y })
    handleOnChange(coordinates)
  }

  const handleClose = () => {
    setOpen(false)
    setDisplayWidth(450)
  }

  const clearPosition = () => {
    setMarkerPosition({})
    handleClearPosition()
    dispatch(removeHeatmapLocation())
  }

  const columns = 50
  const cellSize = displayWidth / columns
  const rows = Math.ceil(displayHeight / cellSize)
  const cells = Array(columns * rows).fill(null) as never[]

  const mapPinProps = () => {
    const width = 26
    const height = 45
    return {
      style: {
        position: 'absolute' as const,
        top: markerPosition.y * cellSize + cellSize / 2 - height,
        left: markerPosition.x * cellSize + cellSize / 2 - width / 2,
        zIndex: 9999
      },
      width,
      height
    }
  }

  return (
    <div>
      <h2>{t('resortMapLocation')}</h2>
      <HeatMapWrapper>
        <ImageWrapper $width={displayWidth}>
          {loading && <StyledLoading />}
          <Dialog open={open} fullScreen>
            <AppBar
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingLeft: '2rem'
              }}
            >
              <span>{t('clickOnTheMapToRegister')}</span>
              <Toolbar>
                <IconButton
                  autoFocus
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <IconClose />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div
              style={{
                display: 'flex',
                margin: '0 auto',
                position: 'relative'
              }}
            >
              <FullImgPreview ref={imgRef} src={preview} alt={t('preview')} />
              {cells.map((_, index) => {
                const row = Math.floor(index / columns)
                const col = index % columns
                return (
                  <div
                    key={index}
                    style={{
                      position: 'absolute',
                      top: row * cellSize,
                      left: col * cellSize,
                      width: cellSize,
                      height: cellSize
                    }}
                    onClick={() => saveCoordinates({ x: col, y: row })}
                  />
                )
              })}
              {!isEmpty(markerPosition) && (
                <IconGoogleMapPin {...mapPinProps()} />
              )}
            </div>
          </Dialog>
          <div onClick={handleClickOpen} style={{ cursor: 'pointer' }}>
            <SmallImgPreview
              src={preview}
              alt={t('preview')}
              style={{ position: 'relative' }}
            />
            {!isEmpty(markerPosition) && (
              <IconGoogleMapPin {...mapPinProps()} />
            )}
          </div>
        </ImageWrapper>
        {!isEmpty(markerPosition) && (
          <Button onClick={clearPosition} variant="contained" size="small">
            {t('removeLocation')}
          </Button>
        )}
      </HeatMapWrapper>
    </div>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.auth.data,
  report: state.report
})

export default connect(mapStateToProps, null)(AddLocationHeatmap)
