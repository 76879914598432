import { t } from 'i18n'
import { ChangeEvent } from 'react'
import { MaskedInputContainer } from '../MaskedInput/styled'
import { InputTimeGroup, InputTimeLabel } from './styled'

interface ITimeInput {
  label: string
  name: string
  value?: string
  onChange: (name: string, value: string) => void
}

const TimeInput = ({ label, name, value, onChange }: ITimeInput) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = event
    const regex = /[0-9].|\./
    if (!regex.test(value)) return

    onChange(name, value)
  }

  return (
    <InputTimeGroup>
      <InputTimeLabel>{t(label)}</InputTimeLabel>
      <MaskedInputContainer
        type="text"
        name={name}
        value={value}
        mask="99:99"
        placeholder="  :  "
        onChange={handleChange}
        pattern="^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"
      />
    </InputTimeGroup>
  )
}

export { TimeInput }
