import styled from 'styled-components'

const CommentItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const CommentItemTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #2b2b2b;
  padding: 15px 0;
`

const CommentItemDescription = styled.div`
  font-size: 16px;
  color: #2b2b2b;
`

export { CommentItemContainer, CommentItemDescription, CommentItemTitle }
