import { t } from 'i18n'
import { ChangeEvent } from 'react'
import { CheckboxGroupContainer, CheckboxGroupItemLabel } from './styled'

interface ICheckboxInputProps {
  name: string
  value?: boolean
  label: string
  onChange: (name: string, value: boolean) => void
}

const CheckboxInput = ({
  name,
  value,
  label,
  onChange
}: ICheckboxInputProps) => {
  const handleCheck = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { checked }
    } = event
    onChange(name, checked)
  }

  return (
    <CheckboxGroupContainer>
      <CheckboxGroupItemLabel htmlFor={name} key={name} active={value ?? false}>
        <input
          id={name}
          type="checkbox"
          name={name}
          onChange={handleCheck}
          checked={value ?? false}
        />
        <span className="checkmark" />
        <p>{t(label)}</p>
      </CheckboxGroupItemLabel>
    </CheckboxGroupContainer>
  )
}

export { CheckboxInput }
