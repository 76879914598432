import styled from 'styled-components'

const TabBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`

const TabBarList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
`

const TabBarItem = styled.li<{ active: boolean; color?: string }>`
  display: flex;
  text-align: center;
  border-bottom: ${props =>
    props.active ? `2px solid ${props.color ?? '#274392'}` : 'none'};
  color: ${props => (props.active ? `${props.color ?? '#274392'}` : '#2b2b2b')};
  padding-bottom: 5px;
  margin-right: 40px;
  cursor: pointer;
`

const TabBarItemContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const TabBarText = styled.div`
  font-size: 20px;
  font-weight: 500;
`

const TabBarBadge = styled.div<{ count?: number }>`
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: red;
  margin-left: 10px;

  &::after {
    content: '${props => props.count}';
    color: #fff !important;
    font-size: 12px;
  }
`

export {
  TabBarBadge,
  TabBarContainer,
  TabBarItem,
  TabBarItemContent,
  TabBarList,
  TabBarText
}
