import { t } from 'i18n'
import {
  InputGroup,
  ReactSliderContainer,
  ReactSliderContent,
  ReactSliderLabel,
  ReactSliderMark,
  ReactSliderThumb,
  ReactSliderTrack
} from './styled'

interface IRangeInputProps {
  name: string
  value: number
  label: string
  onChange: (name: string, value: number) => void
  marks: number
  min: number
  max: number
  step: number
}

const RangeInput = ({
  label,
  name,
  marks,
  min,
  max,
  step,
  onChange,
  value
}: IRangeInputProps) => {
  const handleChange = (value: number | readonly number[]) => {
    onChange(name, value as number)
  }

  return (
    <InputGroup style={{ marginRight: -1 }}>
      <ReactSliderLabel htmlFor={name}>{t(label, { value })}</ReactSliderLabel>
      <ReactSliderContent>
        <p>{min}</p>
        <ReactSliderContainer
          renderTrack={ReactSliderTrack}
          renderThumb={ReactSliderThumb}
          renderMark={ReactSliderMark}
          marks={marks}
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={handleChange}
        />
        <p>{max}</p>
      </ReactSliderContent>
    </InputGroup>
  )
}

export { RangeInput }
