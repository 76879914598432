export const breakpoints = {
  mobile: '500px',
  phablet: '767px',
  tablet: '1024px',
  laptop: '1280px',
  desktop: '9999px'
}

export const colors = {
  primary: '#2b478e',
  black: '#000',
  white: '#fff',
  gray: '#2b2b2b',
  lightGray: '#919191',
  lighterGray: '#d2d2d2',
  darkerGray: '#252525',
  yellow: '#ffd400',
  darkYellow: '#ecc606',
  warning: '#8b2c2c',
  red: '#cf1515',
  blue: '#385ebf',
  green: '#17e27d'
}

export const textSizes = {
  smaller: '0.75rem',
  small: '1rem',
  normal: '1.25rem',
  large: '1.5rem',
  largest: '2.1875rem',
  h1: '2.75rem'
}
