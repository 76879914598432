import { t } from 'i18n'
import {
  InputDateContainer,
  InputError,
  InputGroup,
  InputLabel
} from './styled'

interface IDateInputProps {
  label?: string
  format?: string
  name: string
  onChange: (name: string, value: Date) => void
  value: Date
  minDate?: Date
  meta?: {
    error?: string
    touched?: boolean
  }
}

const DateInput = ({
  name,
  value,
  minDate,
  onChange,
  label,
  meta,
  format = 'dd/MM/y'
}: IDateInputProps) => {
  return (
    <InputGroup>
      {label && <InputLabel htmlFor={name}>{t(label)}</InputLabel>}
      <InputDateContainer
        calendarIcon={null}
        clearIcon={null}
        name={name}
        value={value}
        minDate={minDate}
        format={format}
        onChange={(date: Date) => onChange(name, date)}
      />
      {meta?.touched && meta?.error && <InputError>{t(meta.error)}</InputError>}
    </InputGroup>
  )
}

export { DateInput }
