import { put } from 'redux-saga/effects'
import { getUsers } from 'services'
import { getUsersFailure, getUsersSuccess } from './actions'
import { User } from './types'

export function* getUsersSaga() {
  try {
    const response: User[] = yield getUsers()
    yield put(getUsersSuccess(response))
  } catch (err: any) {
    yield put(getUsersFailure(err))
  }
}
