import { put } from 'redux-saga/effects'
import { getReport, postReport } from 'services'
import {
  clearReportSuccess,
  createReportFailure,
  createReportRequest,
  createReportSuccess,
  getReportFailure,
  getReportFailureOverride,
  getReportRequest,
  getReportRequestOverride,
  getReportSuccess,
  getReportSuccessOverride
} from './actions'
import { Report } from './types'

export function* getReportSaga({
  payload
}: ReturnType<typeof getReportRequest>) {
  try {
    const response: Report = yield getReport(payload)
    yield put(getReportSuccess(response))
  } catch (err: any) {
    yield put(getReportFailure(err))
  }
}

export function* getReportSagaOverride({
  payload
}: ReturnType<typeof getReportRequestOverride>) {
  try {
    const response: Report = yield getReport(payload)
    yield put(getReportSuccessOverride(response))
  } catch (err: any) {
    yield put(getReportFailureOverride(err))
  }
}

export function* clearReportSaga() {
  yield put(clearReportSuccess())
}

export function* postReportSaga({
  payload
}: ReturnType<typeof createReportRequest>) {
  try {
    const response: Report = yield postReport(payload)
    yield put(createReportSuccess(response))
  } catch (err: any) {
    yield put(createReportFailure(err))
  }
}
