import styled from 'styled-components'
import { theme } from 'styles/theme'

const ArchiveWrapper = styled.div`
  max-width: 40%;
  margin: auto;
  h1 {
    margin: 20px;
    size: ${theme.textSizes.largest};
    color: ${theme.colors.gray};
  }
`

const ArchiveContainer = styled.div`
  display: flex;
  padding: 20px;
`

export { ArchiveContainer, ArchiveWrapper }
