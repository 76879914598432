import { Link } from 'react-router-dom'

const NotFoundPage = () => {
  return (
    <div>
      Not Found
      <br />
      <Link to="/">Back to home</Link>
    </div>
  )
}

export default NotFoundPage
