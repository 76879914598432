import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom'
import RouteMiddleware from './RouteMiddleware'
import routeData from './routeData'

const AppRouter = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        {routeData.map((route, index) => (
          <Route
            {...route}
            key={index}
            element={
              <RouteMiddleware {...route}>{route.element}</RouteMiddleware>
            }
          />
        ))}
      </Route>
    )
  )

  return <RouterProvider router={router} />
}

export default AppRouter
