import { ReactNode } from 'react'
import { ContainerWrapper } from './styled'

interface IContainerProps {
  children: ReactNode
  flexDirection?: 'column' | 'row'
  align?: 'flex-start' | 'flex-end' | 'center' | 'end' | 'start' | 'stretch'
  justify?:
    | 'start'
    | 'center'
    | 'end'
    | 'left'
    | 'right'
    | 'flex-start'
    | 'flex-end'
    | 'stretch'
    | 'space-between'
    | 'space-evenly'
    | 'space-around'
}

const Container = ({ children, ...props }: IContainerProps) => {
  return <ContainerWrapper {...props}>{children}</ContainerWrapper>
}

export { Container }
