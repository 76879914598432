import { CheckboxGroupWithCommentInput } from 'components'
import {
  ASSUMED_INJURY_OPTIONS,
  INJURED_BODY_PART_OPTIONS,
  INJURED_SUMMER_BODY_PART_OPTIONS
} from 'constants/options'
import { useFormikContext } from 'formik'
import { t } from 'i18n'
import { Report, Seasons, SectionKey } from 'store/ducks'
import FormTitle from '../../FormTitle'
import { FlexRow, FormSectionSubtitle, RowItem } from '../../styled'

const InjurySection = () => {
  const {
    values: { injury, season },
    setFieldValue
  } = useFormikContext<Report>()

  return (
    <>
      <FormTitle title="injury" sectionKey={SectionKey.INJURY} />
      <FormSectionSubtitle>{t`assumedInjury`}</FormSectionSubtitle>
      <FlexRow>
        <RowItem>
          <CheckboxGroupWithCommentInput
            name="injury.assumedInjury"
            value={injury?.assumedInjury}
            commentName="otherAddComment"
            options={ASSUMED_INJURY_OPTIONS}
            onChange={setFieldValue}
          />
        </RowItem>
      </FlexRow>
      <FormSectionSubtitle>{t`injuredBodypart`}</FormSectionSubtitle>
      <FlexRow>
        <RowItem>
          <CheckboxGroupWithCommentInput
            name="injury.injuredBodyPart"
            value={injury?.injuredBodyPart}
            commentName="otherAddComment"
            options={
              season === Seasons.WINTER
                ? INJURED_BODY_PART_OPTIONS
                : INJURED_SUMMER_BODY_PART_OPTIONS
            }
            onChange={setFieldValue}
          />
        </RowItem>
      </FlexRow>
    </>
  )
}

export default InjurySection
