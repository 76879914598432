import { Modal } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Box = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 2rem;
  max-width: 500px;

  h3 {
    margin-top: 0;
  }
`
interface ModalProps {
  children: React.ReactNode
  isOpen: boolean
  onClose?:
    | ((event: object, reason: 'backdropClick' | 'escapeKeyDown') => void)
    | undefined
}

const MaterialModal = ({ isOpen, onClose, children, ...rest }: ModalProps) => {
  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      {...rest}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>{children}</Box>
    </StyledModal>
  )
}

export { MaterialModal }
