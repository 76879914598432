import styled from 'styled-components'
import { theme } from 'styles/theme'

const AllFilesModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.lightGray};
  color: ${theme.colors.gray};
  padding: 20px;
  width: 80%;
  max-height: 80vh;
  overflow-y: auto;
`

const AllFilesContainer = styled.div`
  display: flex;
  box-shadow: rgb(0 0 0 / 7%) 0px 1px 1px, rgb(0 0 0 / 7%) 0px 2px 2px,
    rgb(0 0 0 / 7%) 0px 4px 4px, rgb(0 0 0 / 7%) 0px 2px 8px,
    rgb(0 0 0 / 7%) 0px 0px 0px;
  padding: 10px;
`

const AllFilesSection = styled.div`
  display: flex;
  width: 50%;
`

export { AllFilesContainer, AllFilesModalContainer, AllFilesSection }
