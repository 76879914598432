import { ChangeEvent, RefObject, useRef, useState } from 'react'
import { Media } from 'store/ducks'
import {
  AudioPlayerContainer,
  AudioPlayerItem,
  AudioTracker,
  PlayPauseButton
} from './styled'

interface IAudioPlayerProps {
  audio: Media
}

// TODO:: This could be refactored to use duration from Media object
// If it works, remove [ duration, setDuration ] state
const AudioPlayer = ({ audio }: IAudioPlayerProps) => {
  const audioPlayer: RefObject<any> = useRef()

  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [duration, setDuration] = useState<number>(0)
  const [trackProgress, setTrackProgress] = useState<number>(0)

  const updateDuration = () => {
    setDuration(audioPlayer.current.duration)
  }

  const updateCurrentTime = () => {
    setTrackProgress(audioPlayer.current.currentTime)
  }

  const playAudio = () => {
    audioPlayer?.current?.play()
    setIsPlaying(true)
  }

  const pauseAudio = () => {
    audioPlayer?.current?.pause()
    setIsPlaying(false)
  }

  const stopAudio = () => {
    audioPlayer?.current?.pause()
    setIsPlaying(false)
    setTrackProgress(0)
    audioPlayer.current.currentTime = 0
  }

  const onScrub = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = event
    if (Number(value) === duration) {
      stopAudio()
    }
    audioPlayer.current.currentTime = value
    setTrackProgress(audioPlayer.current.currentTime)
  }

  const onScrubEnd = () => {
    if (!isPlaying) {
      setIsPlaying(true)
    }
  }

  return (
    <AudioPlayerContainer>
      <h3>{audio.fileName}</h3>
      <small>{audio.duration}</small>
      <AudioPlayerItem>
        <PlayPauseButton
          isPlaying={isPlaying}
          onClick={isPlaying ? pauseAudio : playAudio}
        />
        <audio
          ref={audioPlayer}
          src={audio.path}
          onLoadedData={updateDuration}
          onTimeUpdate={updateCurrentTime}
          onEnded={stopAudio}
        />
        <AudioTracker
          type="range"
          step={1}
          min={0}
          max={typeof duration === 'number' ? duration : 0}
          value={trackProgress}
          onChange={onScrub}
          onMouseUp={onScrubEnd}
          onKeyUp={onScrubEnd}
        />
      </AudioPlayerItem>
    </AudioPlayerContainer>
  )
}

export { AudioPlayer }
