import { Label, WeatherIcon } from 'components'
import { t } from 'i18n'
import { Weather } from 'store/ducks'
import { WeatherCardCol, WeatherCardContainer, WeatherCardRow } from './styled'

interface IWeatherProps {
  weather?: Weather
}

const WeatherCard = ({ weather }: IWeatherProps) => {
  return (
    <WeatherCardContainer>
      <WeatherCardRow>
        <WeatherCardCol>
          <Label style={{ paddingBottom: 30, fontWeight: 'bold' }}>
            {t('temperature')}
          </Label>
          <Label style={{ marginRight: 20, fontSize: 46 }}>
            {weather?.temperature?.toFixed() ?? '-'}℃
          </Label>
          <Label>&nbsp;</Label>
        </WeatherCardCol>
        <WeatherCardCol>
          <Label style={{ paddingBottom: 10, fontWeight: 'bold' }}>
            {t('weatherConditions')}
          </Label>
          <WeatherCardCol>
            <WeatherIcon
              widthImg="50px"
              heightImg="50px"
              marginImg="-10px 0 0 -8px"
              icon={
                (weather &&
                  weather?.weatherInfo &&
                  weather?.weatherInfo[0].icon) ||
                ''
              }
            />
            <Label>
              {weather?.weatherInfo && weather?.weatherInfo[0].description}
            </Label>
          </WeatherCardCol>
        </WeatherCardCol>
      </WeatherCardRow>
      <WeatherCardRow>
        <WeatherCardCol>
          <Label style={{ fontWeight: 'bold' }}>{t('comments')}</Label>
          <Label style={{ marginTop: 20, fontWeight: 'normal' }}>
            {weather?.comment}
          </Label>
        </WeatherCardCol>
      </WeatherCardRow>
    </WeatherCardContainer>
  )
}

export { WeatherCard }
