import { BackButton, Button, Container, TextInput } from 'components'
import { PageTitle } from 'components/PageHeader/styled'
import { t } from 'i18n'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { createSlope } from 'services'
import { Difficulties, Seasons } from 'store/ducks'
import {
  BigLabel,
  FilledInput,
  FormContainer,
  PageHeaderWrapper,
  RadioInputLabel,
  SlopeTypeContainer
} from './styled'

interface FormDataProps {
  name: string
  difficulty: Difficulties
  types: Seasons[]
}

const SlopeCreatePage = () => {
  const defaultData = {
    name: '',
    difficulty: '' as Difficulties,
    types: []
  }
  const [formData, setFormData] = useState<FormDataProps>(defaultData)

  const navigate = useNavigate()

  const isDataValid =
    formData.name.length && formData.types.length && formData.difficulty.length

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, type: inputType, name } = e.currentTarget

    if (inputType === 'radio') {
      return setFormData({
        ...formData,
        difficulty: name as Difficulties
      })
    }

    if (inputType === 'checkbox') {
      const index = formData.types.indexOf(value as Seasons)

      if (index < 0) {
        return setFormData({
          ...formData,
          types: [...formData.types, value as Seasons]
        })
      }

      return setFormData({
        ...formData,
        types: [
          ...formData.types.slice(0, index),
          ...formData.types.slice(index + 1)
        ]
      })
    }

    return setFormData({ ...formData, [name]: value })
  }

  const handleOnSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!isDataValid) return null

    try {
      await createSlope({ ...formData })
      setFormData(defaultData)
      toast.success(`${formData.name} ${t('createSuccess')}`)
    } catch (error) {
      console.error(error)
      toast.error(t('createNewSlopeFailed'))
    }
  }

  const difficultiesArr = [
    {
      name: Difficulties.GREEN,
      label: t('green')
    },
    {
      name: Difficulties.BLUE,
      label: t('blue')
    },
    {
      name: Difficulties.RED,
      label: t('red')
    },
    {
      name: Difficulties.BLACK,
      label: t('black')
    },
    {
      name: Difficulties.NS,
      label: t('notSpecified')
    }
  ]

  return (
    <Container>
      <PageHeaderWrapper>
        <PageTitle>{t('newSlope')}</PageTitle>
        <BackButton goBack={() => navigate('/slopes')} />
      </PageHeaderWrapper>
      <FormContainer onSubmit={handleOnSubmit}>
        <TextInput
          type="text"
          name="name"
          label="name"
          value={formData.name}
          onChange={handleOnChange}
          placeholder={`${t('name')} (e.g. Bruløypa)`}
        />
        <SlopeTypeContainer>
          <BigLabel>
            {t('season')}
            {t('s')}
          </BigLabel>
          <FilledInput
            className={formData.types.includes(Seasons.SUMMER) ? `active` : ''}
          >
            <input
              type="checkbox"
              name={Seasons.SUMMER}
              id={Seasons.SUMMER}
              value={Seasons.SUMMER}
              onChange={handleOnChange}
            />
            {t('summer')}
          </FilledInput>
          <FilledInput
            className={formData.types.includes(Seasons.WINTER) ? `active` : ''}
          >
            <input
              type="checkbox"
              name={Seasons.WINTER}
              id={Seasons.WINTER}
              value={Seasons.WINTER}
              onChange={handleOnChange}
            />
            {t('winter')}
          </FilledInput>
        </SlopeTypeContainer>
        <SlopeTypeContainer>
          <BigLabel>{t('difficulty')}</BigLabel>
          {difficultiesArr.map(diff => (
            <RadioInputLabel
              key={diff.name}
              $bgColor={
                diff.name === Difficulties.NS
                  ? undefined
                  : diff.name.toLowerCase()
              }
              className={formData.difficulty === diff.name ? `active` : ''}
            >
              <input
                type="radio"
                name={diff.name}
                id={diff.name}
                onClick={handleOnChange}
              />
              {diff.label}
            </RadioInputLabel>
          ))}
        </SlopeTypeContainer>
        <Button type="submit" label="save" disabled={!isDataValid} />
      </FormContainer>
    </Container>
  )
}

export default SlopeCreatePage
