import { IconArrowRight } from 'assets/images'
import moment from 'moment'
import { AudioItemContainer, AudioItemText, AudioRecordDot } from './styled'

interface Props {
  onPress: () => void
  duration?: string
  date?: Date
}

const AudioItem = ({ onPress, duration, date }: Props) => {
  return (
    <AudioItemContainer onClick={onPress}>
      <AudioRecordDot />
      <AudioItemText>{moment(date).format('DD.MM.YY')}</AudioItemText>
      <AudioItemText>{duration && duration.substring(0, 5)}</AudioItemText>
      <IconArrowRight
        className="no-print"
        width={16}
        height={16}
        fill={'#2b2b2b'}
      />
    </AudioItemContainer>
  )
}

export { AudioItem }
