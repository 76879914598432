import { zodResolver } from '@hookform/resolvers/zod'
import {
  BackButton,
  Container,
  DateInput,
  RadioButton,
  TimeAndDate
} from 'components'
import { ButtonContainer } from 'components/Button/styled'
import Input from 'components/Inputs/Input'
import PhoneNumberInput from 'components/Inputs/PhoneNumberInput'
import { ErrorMessage } from 'components/Inputs/styles'
import { PageTitle } from 'components/PageHeader/styled'
import { t } from 'i18n'
import moment from 'moment'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
  isValidPhoneNumber
} from 'react-phone-number-input'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { adminCreateUser } from 'services'
import { Agreement, AgreementType, Role, User, UserAccess } from 'store/ducks'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'
import { z } from 'zod'
import {
  Separator,
  UserEditAccessDate,
  UserEditLabel,
  UserProfileOptions
} from '../../containers/UserEditForm/styled'
import { newUserSchema } from './newUser.schema'
import {
  NewUserAccessDisclaimer,
  NewUserContainer,
  NewUserContentContainer,
  NewUserFormContainer,
  NewUserLabel,
  NewUserSaveContainer,
  PageHeaderWrapper
} from './styled'

type NewUserDataProp = z.infer<typeof newUserSchema>

const NewUserPage = () => {
  const FormHook = useForm<NewUserDataProp>({
    resolver: zodResolver(newUserSchema)
  })

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = FormHook

  const [access, setAccess] = useState<UserAccess>({
    default: true,
    role: 'USER',
    startsOn: moment().toDate(),
    endsOn: moment().add(1, 'year').toDate()
  } as UserAccess)

  const [phoneNumberError, setPhoneNumberError] = useState('')
  const [apiError, setApiError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const navigate = useNavigate()

  // Watch for changes in the form
  watch(data => {
    setApiError('')
    if (data.phoneNumber) setPhoneNumberError('')
  })

  const onFormSubmit = handleSubmit(async data => {
    const numExist = isPossiblePhoneNumber(data.phoneNumber)
    const numValid = isValidPhoneNumber(data.phoneNumber)
    const numFormat = formatPhoneNumberIntl(data.phoneNumber)

    if (!numExist || !numValid || !numFormat) {
      setIsSubmitting(false)
      return setPhoneNumberError('provided is invalid phone number.')
    }

    const agreement: Agreement = {
      id: uuid(),
      agreementType: AgreementType.PRIVACY_POLICY,
      accepted: false
    }

    const user: User = {
      id: undefined as unknown as string,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      userStatus: 'ACTIVE',
      resortId: '',
      access: [access],
      agreements: [agreement]
    }

    try {
      await adminCreateUser(user)
      navigate('/users')
      toast.success(t('userCreated'))
    } catch (error: any) {
      console.dir(error)
      setApiError(error.response.data.message)
    }
  })

  const setRole = (value: Role) => {
    setApiError('')
    const _access = {
      ...access,
      role: value
    } as UserAccess

    setAccess(_access)
  }

  const setStartDate = (value: Date) => {
    setApiError('')
    const _access = {
      ...access,
      startsOn: value
    } as UserAccess
    setAccess(_access)
  }

  const setEndDate = (value: Date) => {
    setApiError('')
    const _access = {
      ...access,
      endsOn: value
    } as UserAccess
    setAccess(_access)
  }

  return (
    <form onSubmit={onFormSubmit}>
      <Container>
        <NewUserContainer>
          <PageHeaderWrapper>
            <PageTitle>{t('addNewUser')}</PageTitle>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end'
              }}
            >
              <BackButton goBack={() => navigate('/users')} />
              <TimeAndDate />
            </div>
          </PageHeaderWrapper>
          <NewUserContentContainer>
            <NewUserFormContainer>
              <NewUserLabel>{t('personalInformation')}</NewUserLabel>
              <Input
                type="text"
                name="firstName"
                errMessage={errors.firstName?.message ?? null}
                register={register}
                defaultValue={''}
              />
              <Input
                type="text"
                name="lastName"
                errMessage={errors.lastName?.message ?? null}
                register={register}
                defaultValue={''}
              />
              <Input
                type="email"
                name="email"
                errMessage={errors.email?.message ?? null}
                register={register}
                defaultValue={''}
              />
              <PhoneNumberInput
                errMessage={errors.phoneNumber?.message ?? null}
                invalidNumberError={phoneNumberError}
                name="phoneNumber"
                control={control}
              />
              <NewUserSaveContainer>
                <SubmitButton type="submit" disabled={isSubmitting}>
                  {t('saveNewUser')}
                </SubmitButton>
              </NewUserSaveContainer>
              <ErrorMessage>{apiError}</ErrorMessage>
            </NewUserFormContainer>
            <NewUserFormContainer style={{ marginLeft: 100 }}>
              <UserEditLabel style={{ marginBottom: 20 }}>
                {t('userSetting')}
              </UserEditLabel>
              <UserProfileOptions>
                <RadioButton
                  label="User"
                  checked={access && access.role === Role.USER}
                  handleCheckedChange={() => setRole(Role.USER)}
                />
                <RadioButton
                  label={t(Role.RESORT_ADMINISTRATOR)}
                  checked={access && access.role === Role.RESORT_ADMINISTRATOR}
                  handleCheckedChange={() => setRole(Role.RESORT_ADMINISTRATOR)}
                />
              </UserProfileOptions>
              <UserEditLabel>{t('timePeriod')}</UserEditLabel>
              <UserEditAccessDate>
                <DateInput
                  label="start"
                  name="access.startsOn"
                  minDate={new Date(access?.startsOn ?? moment().toDate())}
                  onChange={(name, value) => setStartDate(value)}
                  value={
                    access?.startsOn ? new Date(access?.startsOn) : new Date()
                  }
                />
                <Separator />
                <DateInput
                  label="end"
                  name="access?.endsOn"
                  minDate={moment().toDate()}
                  onChange={(name, value) => setEndDate(value)}
                  value={access?.endsOn ? new Date(access?.endsOn) : new Date()}
                />
              </UserEditAccessDate>
              <NewUserAccessDisclaimer>
                {t('newUserDisclaimer')}
              </NewUserAccessDisclaimer>
            </NewUserFormContainer>
          </NewUserContentContainer>
        </NewUserContainer>
      </Container>
    </form>
  )
}

export default NewUserPage

const SubmitButton = styled(ButtonContainer)`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  margin: 1rem 0;
`
