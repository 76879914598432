import styled, { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: ${props => props.theme.colors.white};
    font: 400 16px Roboto, sans-serif;
    color: ${props => props.theme.colors.darkerGray};
  }

  input, button {
    outline: none;
  }

  /* NOTE:: This is custom solution for report header datepicker,
   since I couldn't find solutinon with styled component
   */
  .react-datepicker-popper{
    margin-top: -30px;
    padding-top: 0 !important;
   }
  .react-datepicker__day--keyboard-selected {
    background-color: ${props => props.theme.colors.primary};
}
`

export const ModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
`

export const CenterContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
