import { Label } from 'components'
import { t } from 'i18n'
import { LiveClock } from '../LiveClock'
import { TimeAndDateContainer, TimeAndDateContentWrapper } from './styled'

interface Props {
  value?: Date
  vertical?: boolean
  dateFormat?: string
  hourFormat?: string
}
const TimeAndDate = ({
  vertical,
  value,
  dateFormat = 'DD.MM.YY',
  hourFormat = 'HH:mm:ss'
}: Props) => {
  return (
    <TimeAndDateContainer vertical={vertical}>
      <TimeAndDateContentWrapper>
        <Label>{t('time')}</Label>
        <LiveClock
          value={value}
          staticTime={value ? true : false}
          format={hourFormat}
        />
      </TimeAndDateContentWrapper>
      <TimeAndDateContentWrapper>
        <Label>{t('date')}</Label>
        <LiveClock
          value={value}
          staticTime={value ? true : false}
          format={dateFormat}
        />
      </TimeAndDateContentWrapper>
    </TimeAndDateContainer>
  )
}

export { TimeAndDate }
