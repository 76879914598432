import InputMask from 'react-input-mask'
import styled from 'styled-components'
import { theme } from 'styles/theme'

const MaskedInputContainer = styled(InputMask)`
  max-width: 80px;
  height: 40px;
  margin: 9px 0 0;
  border-radius: 4px;
  border: solid 1px ${theme.colors.gray};
  text-align: center;
  font-size: ${theme.textSizes.normal};
  color: ${theme.colors.gray};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  &[type='file'] {
    display: none;
  }
`

export { MaskedInputContainer }
