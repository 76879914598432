import { IconComment } from 'assets/images'
import { RadioGroupInput } from 'components'
import { t } from 'i18n'
import { ChangeEvent, useState } from 'react'
import { SkiEquipment, SkiEquipmentType } from 'store/ducks'
import {
  CommentContainer,
  InputCollapsableContainer,
  InputGroup,
  TextAreaContainer
} from './styled'

type OptionItem = {
  id: string | boolean
  label: string | number
}

interface ISkiEquipmentInputProps {
  name: string
  value?: SkiEquipment
  options: OptionItem[]
  onChange: (name: string, value: string | boolean | null) => void
}

const SkiEquipmentInput = ({
  name,
  value,
  options,
  onChange
}: ISkiEquipmentInputProps) => {
  const [openComment, setOpenComment] = useState<boolean>(
    value?.otherSkiEquipment && value.type === 'Other' ? true : false
  )

  const toggleOpenComment = () => {
    if (!openComment) {
      onChange(`${name}.type`, SkiEquipmentType.OTHER)
    }
    setOpenComment(!openComment)
  }

  const handleUpdateComment = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(`${name}.otherSkiEquipment`, event.target.value)
    onChange(`${name}.type`, SkiEquipmentType.OTHER)
  }

  return (
    <>
      <RadioGroupInput
        name={`${name}.type`}
        value={value?.type}
        options={options}
        onChange={(name: string, value: string | boolean) => {
          onChange(`${name}.otherSkiEquipment`, undefined as unknown as string)
          setOpenComment(false)
          onChange(name, value)
        }}
      />
      <CommentContainer>
        <InputGroup>
          <InputCollapsableContainer
            active={openComment}
            onClick={toggleOpenComment}
          >
            <p>{t`otherAddComment`}</p>
            <IconComment />
          </InputCollapsableContainer>
          {openComment && (
            <TextAreaContainer
              value={value?.otherSkiEquipment}
              rows={12}
              onChange={handleUpdateComment}
            />
          )}
        </InputGroup>
      </CommentContainer>
    </>
  )
}

export { SkiEquipmentInput }
