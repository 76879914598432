import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'
import AppRouter from 'routes'
import { ThemeProvider } from 'styled-components'
import { theme } from 'styles/theme'
import { persistor, store } from './store'
import GlobalStyle from './styles/global'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false // default: true
    }
  }
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ToastContainer position="top-center" theme="dark" autoClose={3000} />
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <AppRouter />
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App
