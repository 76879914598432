import { Coordinate, Slope } from '../auth/types'

export enum GetReportTypes {
  GET_REPORT_REQUEST = '@report/GET_REPORT_REQUEST',
  GET_REPORT_SUCCESS = '@report/GET_REPORT_SUCCESS',
  GET_REPORT_FAILURE = '@report/GET_REPORT_FAILURE',
  GET_REPORT_REQUEST_OVERRIDE = '@report/GET_REPORT_REQUEST_OVERRIDE',
  GET_REPORT_SUCCESS_OVERRIDE = '@report/GET_REPORT_SUCCESS_OVERRIDE',
  GET_REPORT_FAILURE_OVERRIDE = '@report/GET_REPORT_FAILURE_OVERRIDE'
}

export enum CreateReportTypes {
  CREATE_REPORT_REQUEST = '@report/CREATE_REPORT_REQUEST',
  CREATE_REPORT_SUCCESS = '@report/CREATE_REPORT_SUCCESS',
  CREATE_REPORT_FAILURE = '@report/CREATE_REPORT_FAILURE'
}

export enum ClearReportTypes {
  CLEAR_REPORT = '@report/CLEAR_REPORT',
  CLEAR_REPORT_SUCCESS = '@report/CLEAR_REPORT_SUCCESS',
  CLEAR_REPORT_HEATMAP_LOCATION = '@report/CLEAR_REPORT_HEATMAP_LOCATION'
}

export enum Seasons {
  WINTER = 'Winter',
  SUMMER = 'Summer'
}

export enum EventSource {
  SEQUENCE_OF_EVENTS = 'Sequence of events',
  INJURY = 'Injury',
  GENERAL = 'General',
  ARCHIVE = 'Archive',
  INVESTIGATION_AND_MONITORING = 'Invention and monitoring',
  PERSONAL_INFO_WRITTEN_CONSENT = 'Written consent to save personal info'
}

export enum SectionKey {
  SEQUENCE_OF_EVENTS = 'Sequence of events',
  INJURY = 'Injury',
  GENERAL = 'General',
  ARCHIVE = 'Archive',
  INVESTIGATION_AND_MONITORING = 'Invention and monitoring',
  PERSONAL_INFO_WRITTEN_CONSENT = 'Written consent to save personal info'
}

export enum RelativeActionType {
  EDIT = 'edit',
  DELETE = 'delete',
  ADD = 'add'
}

export enum SectionStatus {
  COMPLETED = 'Completed',
  PARTIALLY_DONE = 'Partially done',
  BLANK = 'Blank'
}

export enum MediaTypes {
  VIDEO = 'VIDEO',
  PICTURE = 'PICTURE'
}

export enum SkillLevel {
  EXPERT = 'EXPERT',
  GOOD = 'GOOD',
  BEGINNER = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE'
}

export enum Consciousness {
  AWAKED_AND_CLEAR = 'Awaked and clear',
  AWAKED_AND_UNCLEAR = 'Awaked and unclear',
  REACTS_TO_PAIN = 'Reacts to pain',
  DOESNT_REACT = "Doesn't react"
}

export enum Bleedings {
  YES = 'Yes',
  NO = 'No'
}

export enum HighEnergy {
  YES = 'Yes',
  NO = 'No',
  UNKNOWN = 'Unknown'
}

export enum Intoxicated {
  YES = 'Yes',
  NO = 'No',
  UNKNOWN = 'Unknown'
}

export enum Breathing {
  UNAFFECTED = 'Unaffected',
  STRAINED = 'Strained',
  PAINFUL_WHILE_BREATHING = 'Painful while taking a breath',
  OTHER = 'Other'
}

export enum Pulse {
  NORMAL_PULSE = 'Normal pulse',
  WEAK_PULSE = 'Weak pulse',
  CANT_DETECT_PULSE = 'Cant detect pulse'
}

export enum PulseSourceEnum {
  THROAT = 'Throat',
  WRIST = 'Wrist'
}

export enum TreatedByEnum {
  SKI_PATROL = 'Ski patrol',
  AUXILIARY_CROSS = 'Auxiliary cross',
  OTHER_EMPLOYEE = 'Other employee',
  DOCTOR_HOSPITAL = 'Doctor/Hospital',
  EMERGENCY_ROOM_AMK = 'Emergency room / AMK',
  CONTACT_DOCTOR_IF_CONDITION_WORSENS = 'Contact doctor if condition worsens'
}

export enum PersonalInformationConsent {
  PERMISSION_CONSENTED = 'Permission consented',
  NOT_RESPONSIVE_CANT_GET_CONSENT = "Not responsive, can't get consent",
  CONSENT_TO_WRITTEN_REPORT = 'Consent to written report',
  PATIENT_HAS_NOT_GIVEN_CONSENT = 'Patient has not given consent'
}

export enum MonitoringState {
  UNCHANGED = 'Unchanged',
  BETTER = 'Better',
  WORSE = 'Worse'
}

export enum MonitoringExamination {
  NO = 'No',
  PARTLY = 'Partly',
  YES_NO_FINDS = 'Yes, no finds',
  YES_WITH_FINDS = 'Yes, with finds'
}

export enum ReportStatus {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
  ARCHIVED = 'ARCHIVED',
  ALL = 'ALL'
}

export interface TransportAtReport {
  atv?: boolean
  own?: boolean
  pulk?: boolean
  snowScooter?: boolean
  scooterPulk?: boolean
  walkingCyclingYourself?: boolean
  stretcher?: boolean
  fireAndRescue?: boolean
  atvUtv?: boolean
  other?: boolean
  otherAddComment?: string
}

export interface WeatherInfo {
  id: number
  main: string
  description: string
  icon: string
}

export interface LocalConditions {
  wet?: boolean
  dry?: boolean
  iceSnowFrost?: boolean
}

export interface Weather {
  temperature?: number
  feelsLike?: number
  precipitationRate?: number
  relativeHumidity?: number
  windFromDirection?: number
  windSpeed?: number
  windSpeedOfGust?: number
  weatherInfo?: WeatherInfo[]
  comment?: string
  time?: Date
  localConditions?: LocalConditions
}

export interface FurtherTransport {
  airAmbulance?: boolean
  ambulance?: boolean
  taxi?: boolean
  privateCar?: boolean
  stayingAtSkiResort?: boolean
  stiPTrspEmergencyRoom?: boolean
  other?: boolean
  otherAddComment?: string
}

export enum SkiEquipmentType {
  SLALOM_SKI = 'Slalom ski',
  TELEMARK = 'Telemark',
  SNOWBOARD = 'Snowboard',
  TWIN_TIP = 'Twin tip',
  OTHER = 'Other'
}

export enum IsRentedEnum {
  YES = 'Yes',
  NO = 'No',
  DONT_KNOWN = 'Dont know'
}

export enum ReportSection {
  LIVE_REPORT = 'Live report',
  PERSONAL_INFORMATION = 'Personal information',
  INJURY = 'Injury',
  STATE = 'State',
  SEQUENCE_OF_EVENTS = 'Sequence of events',
  TREATMENT_CHECKLIST = 'Treatment checklist',
  TIME_STAMPS = 'Timestamps',
  TREATED_BY = 'Treated by',
  TRANSPORTATION = 'Transportation',
  INVESTIGATION_AND_MONITORING = 'Investigation and monitoring',
  ARCHIVE = 'Archive',
  REPORT = 'Report'
}

export enum BikeEquipmentType {
  BALANCE_BIKE = 'Balance bike',
  CHILDRENS_BIKE = 'Childrens bike',
  HALF_MUTED = 'Half muted',
  FULLY_DAMPED = 'Fully damped',
  OTHER = 'Other'
}

export interface Location {
  geo?: {
    lat: number
    lng: number
  }
  includeLocation?: boolean
  slope?: string
}

export interface Circulation {
  pale?: boolean
  clammyCold?: boolean
  thirsty?: boolean
}

export interface Comment {
  id: string
  section?: string
  subsection?: string
  title?: string
  comment?: string
  source: EventSource
}

export interface InjuryLocation {
  terrainParkAtElement?: boolean
  preparedDownSpeed?: boolean
  outsidePreparedDownSpeed?: boolean
  closedDownSpeed?: boolean
  upwardsLift?: boolean
  whenBoarding?: boolean
  whenDisembarking?: boolean
  adaptedPath?: boolean
  naturalPath?: boolean
  roadGravelRoad?: boolean
  jump?: boolean
  other?: boolean
  pumptrack?: boolean
  tractionElement?: boolean
  injuryLocationOther?: string
}
export interface TreatedBy {
  skiPatrol?: boolean
  auxiliaryCross?: boolean
  otherEmployee?: boolean
  doctorHospital?: boolean
  emergencyRoomAmk?: boolean
  contactDoctorIfConditionsWorsens?: boolean
  seeDoctorIfConditionsWorsens?: boolean
  pathPatrol?: boolean
  emergencyRoom?: boolean
  ambulanceOnSite?: boolean
  doctorOnSite?: boolean
}

export interface Transport {
  transportAtResort: TransportAtReport
  furtherTransport: FurtherTransport
}

export interface Skill {
  level: SkillLevel
  introduction?: boolean
}

export interface SafetyEquipment {
  helmet: boolean
  backProtector: boolean
  wristProtector: boolean

  // bikes
  halfHelmet: boolean
  gloves: boolean
  backPlate: boolean
  armor: boolean
  legKneeProtector: boolean
  sack: boolean
  elbowProtection: boolean
  neckCollar?: boolean
}

export interface SkiEquipment {
  type?: SkiEquipmentType
  otherSkiEquipment?: string
}

export interface BikeEquipment {
  type?: BikeEquipmentType
  otherBikeEquipment?: string
}

export interface Equipment {
  isRented?: IsRentedEnum
  safetyEquipments?: SafetyEquipment
  skiEquipment?: SkiEquipment
  bikeEquipment?: BikeEquipment
}
export interface Relative {
  id?: string
  fullName?: string
  phoneNumber?: string
  email?: string
  role?: string
}

export interface PersonalInformation {
  personalInformationConsent: PersonalInformationConsent
  personalInformationDeniedExplanation?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  nationality?: string
  birthDate?: Date
  gender?: string
  address?: string
  postalCode?: string
  place?: string
  country?: string
  legalGuardian?: string
  skills?: Skill
  equipments?: Equipment
  permissionDenied?: boolean
  relatives?: Relative[]
  equipmentsCompleted?: boolean
  skillsCompleted?: boolean
  personalInfoFormCompleted: boolean
  writtenConsent?: Media[]
  stepCompleted?: boolean
  allergies?: string
  medications?: string
}

export interface Checklist {
  clearAirways?: boolean
  hlr?: boolean
  oxygen?: boolean
  bandaging?: boolean
  fracture?: boolean
  splint?: boolean
  stretchSplint?: boolean
  neckCollar?: boolean
  vacuumMattress?: boolean
  price?: boolean
  cryo?: boolean
  backboard?: boolean
  woundCleanser?: boolean
  other?: boolean
  otherAddComment?: string
}

export interface TimeStamps {
  notificationOfInjuryTime?: string
  skiPatrolAtInjurySite?: string
  pulkArrivedTime?: string
  departureFromInjurySiteTime?: string
  arrivedAtInjuryRoomTime?: string
  amkWarningTime?: string
  amisNumberTime?: string
  patientDeliveredTime?: string
  policeWarnedTime?: string
  pathPatrolAtInjurySite?: string
}

export interface PulseSource {
  throat?: boolean
  wrist?: boolean
}

export interface BleedingsSource {
  internal?: boolean
  external?: boolean
}
export interface State {
  breathFrequency?: number
  highEnergy?: HighEnergy
  intoxicated?: Intoxicated
  consciousness?: Consciousness
  bigBleedings?: boolean
  bleedingsSource?: BleedingsSource
  breathing?: Breathing
  breathingCommentOther?: string
  heartbeat?: number
  pulse?: Pulse
  pulseSource?: PulseSource
  painLevel?: number
  painLevelText?: string
  circulation?: Circulation
  headInjury?: boolean
  neckInjury?: boolean
  backInjury?: boolean
}

export interface AssumedInjury {
  fracture?: boolean
  dislocated?: boolean
  sprain?: boolean
  impactShock?: boolean
  cutsWounds?: boolean
  concussion?: boolean
  acuteSickness?: boolean
  dead?: boolean
  other?: boolean
  otherAddComment?: string
}

export interface InjuredBodyPart {
  head?: boolean
  throat?: boolean
  neck?: boolean
  shoulder?: boolean
  arm?: boolean
  wrist?: boolean
  hand?: boolean
  finger?: boolean
  stomach?: boolean
  breast?: boolean
  back?: boolean
  buttock?: boolean
  thigh?: boolean
  knee?: boolean
  calf?: boolean
  ankleFoot?: boolean
  hip?: boolean
  other?: boolean
  collarbone?: boolean
  otherAddComment?: string
}

export interface Injury {
  assumedInjury?: AssumedInjury
  injuredBodyPart?: InjuredBodyPart
  injuryType?: string
  assumedInjuryCompleted?: boolean
  injuryBodyPartCompleted?: boolean
  pictures?: Media[]
  recordings?: Media[]
}
export interface Witness {
  id?: string
  fullName?: string
  phoneNumber?: string
  explanation?: string
  signature?: string
}

export interface Archive {
  paperPicture?: Media
  injuryLocation?: string
  injuryDate?: string
  paperInjuryReportNumber?: string
}
export interface SequenceOfEvents {
  injuryLocation?: InjuryLocation
  eventExplanation?: string
  eventExplanationLocation?: string
  witnesses?: Witness[]
  injuryLocationCompleted?: boolean
  eventExplanationCompleted?: boolean
  witnessCompleted?: boolean
  recordings?: Media[]
  pictures?: Media[]
  fallYourself?: boolean
  hitByAnotherPerson?: boolean
  runOverAnotherPerson?: boolean
  collisionWithObject?: boolean
  lostControl?: boolean
  drivenOutOfBerm?: boolean
  crashObjectOnTrail?: boolean
  lostBalanceAfterJump?: boolean
  hitByAnotherCyclist?: boolean
}

export interface Monitoring {
  id?: string
  breathFrequency?: string
  monitoringHour?: Date
  state?: MonitoringState
  conductedExamination?: MonitoringExamination
  time?: Date
  glasgowScore?: string
  pulse?: string
  bloodPressure?: string
  bodyTemperature?: number
  pain?: number
  readonly?: boolean
}

export interface InvestigationAndMonitoring {
  monitoring: Monitoring[]
  currentMonitoring?: Monitoring
}

export interface Creator {
  fullName?: string
  id?: string
  numericId?: number
}

export interface Resort {
  PK: string
  SK: string
  name: string
  address?: string
  country?: string
  zipCode?: string
  location?: Location['geo']
  slopes?: Slope[]
  defaultSeason: Seasons
  heatmapBySeasons?: {
    winter?: { fileName: string; path: string; coordinates?: Coordinate[] }
    summer?: { fileName: string; path: string; coordinates?: Coordinate[] }
  }
}

export interface RelatedReport {
  reportId?: number
  PK?: string
  SK?: string
  name?: string
  type?: 'RELATED' | 'VERSION'
  version?: number
}
export interface GeoLocation {
  lat: number
  lng: number
}

export interface TrailMap {
  geoLocation?: GeoLocation
  includeLocation?: boolean
  slope?: string
}

export interface ReportItem {
  PK?: string
  SK?: string
  reportId?: number
  name: string
  userId?: string
  mainCreator?: Creator
  reportStatus: string
  createdAt: Date
  reportDate: Date
  updatedAt: Date
  relatedReports: RelatedReport[]
}
export interface Report {
  PK?: string
  SK?: string
  reportId?: number
  version: number
  reportDate?: Date | string
  skiResort?: string
  patientName?: string
  trailMap?: TrailMap
  includeLocation?: boolean
  currentStep: number
  subStep?: number
  currentImage?: Media
  currentAudio?: Media
  personalInformation: PersonalInformation
  checklist?: Checklist
  timestamps?: TimeStamps
  sequenceOfEvents?: SequenceOfEvents
  state?: State
  injury?: Injury
  transport?: Transport
  treatedBy?: TreatedBy
  investigationAndMonitoring: InvestigationAndMonitoring
  personalInfoCompleted: boolean
  injuryCompleted: boolean
  sequenceOfEventsCompleted: boolean
  timeStampsCompleted: boolean
  checklistCompleted: boolean
  stateCompleted: boolean
  transportationCompleted: boolean
  treatedByCompleted: boolean
  surveillanceCompleted: boolean
  investigationAndMonitoringCompleted: boolean
  isArchive?: boolean
  archive?: Archive
  section?: ReportSection
  weather?: Weather
  mainCreator?: Creator
  additionalCreators?: Creator[]
  relatedReports?: RelatedReport[]
  isInformationCorrect?: boolean
  pictures: Media[]
  audio: Media[]
  comments?: Comment[]
  resort?: Resort
  reportStatus?: string
  createdAt?: Date
  updatedAt?: Date
  userLocation?: { latitude?: number; longitude?: number }
  season?: Seasons
  appVersion?: string
  metadata?: Metadata
  timestamp?: number
  heatmap?: {
    coordinates: {
      x: number
      y: number
    }
  }
}

export interface Metadata {
  os?: string
  appVersion?: string
  osVersion?: string | number
  timestamp?: number
  ipAddress?: string
  hash?: string
  sync?: boolean
  deviceId?: string
  deviceModel?: string
}

export interface Therapist {
  id: string
  fullName: string
  resortId: string
  numericId: number
}

export interface Media {
  id?: string
  source?: EventSource
  fileName?: string
  fileSize?: number
  section?: string
  path: string
  uri?: string
  url?: string
  type?: string
  fileDate?: Date
  duration?: string
  uploaded?: boolean
  isRemote?: boolean
}

export interface IReportListData {
  PK: string
  SK: string
  reportId?: number
  name: string
  state: string
  userId: string
  resortId: string
  reportStatus: string
  currentStep: number
  reportDate: string
  mainCreator: Creator
  createdAt: string
  updatedAt: string
  version: number
  relatedReports: RelatedReport[]
}

export type MarkerPosition =
  | {
      x: number
      y: number
    }
  | Record<string, never>

// State Type

export interface ReportState {
  readonly data: Report
  readonly loading: boolean
  readonly error: boolean
  readonly errorStack: any
}
