import { z } from 'zod'
import { signInSchema } from './signIn.schema'

import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18n-js'
import React from 'react'
import { If } from 'react-extras'
import { useForm } from 'react-hook-form'
import {
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
  isValidPhoneNumber
} from 'react-phone-number-input'
import { Link } from 'react-router-dom'
import { ErrorMessage, LinkWrapper, SubmitButton } from '../styled'

import Input from 'components/Inputs/Input'
import PhoneNumberInput from 'components/Inputs/PhoneNumberInput'
import useLogin from 'hooks/useLogin'
import { SignInFormContainer } from './styled'

type SignInFormDataProps = z.infer<typeof signInSchema>

const SignInForm = () => {
  const FormHook = useForm<SignInFormDataProps>({
    resolver: zodResolver(signInSchema)
  })

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = FormHook

  const { mutate: login } = useLogin()

  const [phoneNumberError, setPhoneNumberError] = React.useState('')
  const [apiError, setApiError] = React.useState('')
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  // Watch for changes in the form
  watch(() => setApiError(''))

  const onSubmit = handleSubmit(async data => {
    const username = data.phoneNumber
    const password = data.password

    if (!username) return
    setIsSubmitting(true)
    setPhoneNumberError('')

    const numExist = isPossiblePhoneNumber(username)
    const numValid = isValidPhoneNumber(username)
    const numFormat = formatPhoneNumberIntl(username)

    if (!numExist || !numValid || !numFormat) {
      setIsSubmitting(false)
      return setPhoneNumberError('Invalid phone number')
    }

    login(
      { username, password },
      {
        onError: (error: any) => {
          setApiError(error.message)
          setIsSubmitting(false)
          return
        },
        onSuccess: () => {
          setIsSubmitting(false)
          return
        }
      }
    )
  })

  return (
    <SignInFormContainer>
      <form onSubmit={onSubmit}>
        <PhoneNumberInput
          errMessage={errors.phoneNumber?.message ?? null}
          invalidNumberError={phoneNumberError}
          name="phoneNumber"
          control={control}
        />
        <Input
          errMessage={errors.password?.message ?? null}
          type="password"
          name="password"
          register={register}
          defaultValue={''}
        />
        <If condition={!!apiError}>
          <ErrorMessage $textAlign="center" $width="100%" $noWrap>
            {apiError}
          </ErrorMessage>
        </If>
        <SubmitButton type="submit" disabled={isSubmitting}>
          {t('login')}
        </SubmitButton>
        <LinkWrapper>
          <Link to="/forgot-password">{t('forgotPassword')}?</Link>
        </LinkWrapper>
      </form>
    </SignInFormContainer>
  )
}

export default SignInForm
