import { CheckboxGroupWithCommentInput } from 'components'
import {
  FURTHER_TRANSPORT_OPTIONS,
  FURTHER_TRANSPORT_SUMMER_OPTIONS,
  TRANSPORT_AT_RESORT_OPTIONS,
  TRANSPORT_AT_RESORT_SUMMER_OPTIONS
} from 'constants/options'
import { useFormikContext } from 'formik'
import { t } from 'i18n'
import { Report, Seasons } from 'store/ducks'
import FormTitle from '../FormTitle'
import { FlexRow, FormSectionSubtitle, RowItem } from '../styled'

const TransportationSection = () => {
  const {
    values: { transport, season },
    setFieldValue
  } = useFormikContext<Report>()

  return (
    <>
      <FormTitle title="transport" />
      <FormSectionSubtitle>{t`atResort`}</FormSectionSubtitle>
      <FlexRow>
        <RowItem>
          <CheckboxGroupWithCommentInput
            name="transport.transportAtResort"
            value={transport?.transportAtResort}
            commentName="otherAddComment"
            options={
              season === Seasons.WINTER
                ? TRANSPORT_AT_RESORT_OPTIONS
                : TRANSPORT_AT_RESORT_SUMMER_OPTIONS
            }
            onChange={setFieldValue}
          />
        </RowItem>
      </FlexRow>
      <FormSectionSubtitle>{t`furtherTransport`}</FormSectionSubtitle>
      <FlexRow>
        <RowItem>
          <CheckboxGroupWithCommentInput
            commentName="otherAddComment"
            name="transport.furtherTransport"
            value={transport?.furtherTransport}
            options={
              season === Seasons.WINTER
                ? FURTHER_TRANSPORT_OPTIONS
                : FURTHER_TRANSPORT_SUMMER_OPTIONS
            }
            onChange={setFieldValue}
          />
        </RowItem>
      </FlexRow>
    </>
  )
}

export default TransportationSection
