import { IconClose } from 'assets/images'
import { t } from 'i18n'
import { useEffect, useState } from 'react'
import { getFileUrl } from 'services'
import {
  EmptyImageContainer,
  FullSizeContainer,
  ImageGalleryItemContainer
} from './styled'

interface IImageGalleryItemProps {
  path: string
}

const ImageGalleryItem = ({ path }: IImageGalleryItemProps) => {
  const [imageUrl, setImageUrl] = useState<string>('')
  const [fullSize, setFullSize] = useState<boolean>(false)

  useEffect(() => {
    const getImage = async () => {
      const url = await getFileUrl(path)
      setImageUrl(url)
    }
    getImage()
  }, [path])

  return (
    <>
      {imageUrl ? (
        <ImageGalleryItemContainer
          onClick={() => setFullSize(true)}
          src={imageUrl}
          alt={t`pictures`}
        />
      ) : (
        <EmptyImageContainer />
      )}
      {fullSize && (
        <FullSizeContainer>
          <IconClose
            onClick={() => setFullSize(false)}
            style={{ cursor: 'pointer' }}
          />
          <img alt="" src={imageUrl} />
        </FullSizeContainer>
      )}
    </>
  )
}

export { ImageGalleryItem }
