import { t } from 'i18n'
import { InputHTMLAttributes } from 'react'
import { InputContainer, InputError, InputGroup, InputLabel } from './styled'

interface ITextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  type: 'text' | 'number' | 'password' | 'email'
  meta?: {
    error?: string
    touched?: boolean
  }
  onFormikChange?: (name: string, value: string) => void
}

const TextInput = ({
  label,
  meta,
  onChange,
  onFormikChange,
  className,
  ...rest
}: ITextInputProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value, name }
    } = event

    if (onFormikChange) {
      onFormikChange(name, value)
    } else if (onChange) {
      onChange(event)
    }
  }
  return (
    <InputGroup className={className}>
      <InputLabel htmlFor={rest.name}>{t(label)}</InputLabel>
      <InputContainer
        {...rest}
        onWheel={e => e.currentTarget.blur()}
        onChange={handleChange}
      />
      {meta?.touched && meta?.error && <InputError>{t(meta.error)}</InputError>}
    </InputGroup>
  )
}

export { TextInput }
