import { action } from 'typesafe-actions'
import { Report } from '../report/types'
import { GetReportsTypes } from './types'

export const getReportsRequest = (key: string) =>
  action(GetReportsTypes.GET_REPORTS_REQUEST, key)

export const getReportsSuccess = (data: Report[]) =>
  action(GetReportsTypes.GET_REPORTS_SUCCESS, data)

export const getReportsFailure = (error: Error) =>
  action(GetReportsTypes.GET_REPORTS_FAILURE, { errorStack: error })
