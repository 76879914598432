import { Auth } from '@aws-amplify/auth'
import { AxiosResponse } from 'axios'
import { UserInfo } from 'store/ducks'
import api from './baseApi'

export const getSessionStatus = async (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(session => {
        resolve(session.isValid())
      })
      .catch(err => {
        if (err === 'No current user') {
          resolve(false)
        } else {
          reject(err)
        }
      })
  })
}

export const UserLogin = async (
  username: string,
  password: string
): Promise<UserInfo> => {
  await Auth.signIn(username, password)

  const response: AxiosResponse<UserInfo> = await api.get('user')
  const userInfo = response.data
  userInfo.isLogged = await getSessionStatus()
  return userInfo
}

// export const logoff = async (): Promise<any> => {
//   Auth.signOut({ global: true })
//     .then(data => {
//       console.log(data)
//     })
//     .catch(error => {
//       console.log(error)
//     })
//     .finally(() => true)
// }

export const logoff = async (): Promise<any> => {
  return new Promise((resolve, reject) => {
    Auth.signOut({ global: true })
      .then(data => {
        resolve(data)
      })
      .catch(err => {
        Auth.signOut()
        reject(err)
      })
  })
}

export const forgotPassword = async ({
  userName
}: {
  userName: string
}): Promise<any> => {
  return new Promise((resolve, reject) => {
    Auth.forgotPassword(userName)
      .then(data => resolve(data))
      .catch(err => reject(err))
  })
}

export const forgotPasswordSubmit = async ({
  userName,
  code,
  newPassword
}: {
  userName: string
  code: string
  newPassword: string
}): Promise<any> => {
  return new Promise((resolve, reject) => {
    Auth.forgotPasswordSubmit(userName, code, newPassword)
      .then(data => resolve(data))
      .catch(err => reject(err))
  })
}

export const getUserInfo = async (): Promise<UserInfo> => {
  const response: AxiosResponse<UserInfo> = await api.get('user')
  const userInfo = response.data
  userInfo.isLogged = await getSessionStatus()
  return userInfo
}

export const updateUserInfo = async (userInfo: UserInfo) => {
  try {
    await api.put('user', { ...userInfo })
  } catch (error) {
    console.error(error)
  }
}

export const deleteUser = async () => {
  try {
    await api.delete('user')
  } catch (error) {
    console.error(error)
  }
}
