import React from 'react'
import { If } from 'react-extras'
import {
  TabBarBadge,
  TabBarItem,
  TabBarItemContent,
  TabBarText
} from './styled'

interface ITabProps {
  active: boolean
  title: string
  name: string
  count?: number
  color?: string
  handleActive: (name: string) => void
  children?: React.ReactNode
}

const Tab = ({
  title,
  active,
  name,
  count,
  color,
  children,
  handleActive
}: ITabProps) => {
  const onPressTab = () => {
    handleActive(name)
  }

  return (
    <TabBarItem color={color} active={active} onClick={onPressTab}>
      <TabBarItemContent>
        <TabBarText>{title}</TabBarText>
        <If condition={count ? true : false}>
          <TabBarBadge count={count} />
        </If>
        {children}
      </TabBarItemContent>
    </TabBarItem>
  )
}

export default Tab
