import { t } from 'i18n'
import { PersonalInformation } from 'store/ducks'
import { FlexRow, ReportSummaryContainer } from '../styled'

interface ISummarySectionProps {
  reportId?: string
  personalInformation?: PersonalInformation
}

const SummarySection = ({
  reportId,
  personalInformation
}: ISummarySectionProps) => {
  return (
    <ReportSummaryContainer>
      <div>
        <small>{t`injuryReportNumber`}</small>
        <h4>{reportId}</h4>
      </div>
      <FlexRow>
        <div style={{ margin: '10px 40px' }}>
          <h4>{t`patient`}</h4>
          <small>{t`name`}</small>
          <p>
            {personalInformation?.firstName}&nbsp;
            {personalInformation?.lastName}
          </p>
          <small>{t`phoneNumber`}</small>
          <p>{personalInformation?.phoneNumber}</p>
          <small>{t`address`}</small>
          <p>{personalInformation?.address}</p>
          <p>{personalInformation?.postalCode}</p>
          <p>{personalInformation?.country}</p>
        </div>
        {personalInformation?.relatives?.[0] && (
          <div style={{ margin: '10px 40px' }}>
            <h4>{t`relatives`}</h4>
            <small>{t`name`}</small>
            <p>{personalInformation.relatives[0].fullName}</p>
            <small>{t`phoneNumber`}</small>
            <p>{personalInformation.relatives[0].phoneNumber}</p>
            <small>{t`email`}</small>
            <p>{personalInformation.relatives[0].email}</p>
          </div>
        )}
      </FlexRow>
    </ReportSummaryContainer>
  )
}

export default SummarySection
