import TableBody from './TableBody'
import TableHeader from './TableHeader'
import { TableContainer } from './styles'
import { ColumnProps } from './types'

interface TableProps {
  columns: ColumnProps[]
  data: any
  externalLink?: ExternalLinkProps | null
  className?: string
}

interface ExternalLinkProps {
  label: string
  url: string
}

const Table = ({ columns, data, className }: TableProps) => {
  return (
    <TableContainer className={className}>
      <TableHeader columns={columns} />
      <TableBody columns={columns} data={data} />
    </TableContainer>
  )
}

export { Table }
