import { IconMinus, IconPlusCircle, IconSave } from 'assets/images'
import { Button, PhoneInput, TextInput } from 'components'
import { t } from 'i18n'
import { ChangeEvent, useState } from 'react'
import { Witness } from 'store/ducks'
import { v4 as uuid } from 'uuid'
import { InputCollapsableContainer, InputGroup } from './styled'

interface IWitnessInputProps {
  label: string
  patientName: string
  add: (witness: Witness) => void
  update: (index: number, witness: Witness) => void
  remove: (index: number) => void
  witnesses: Witness[]
}

const initialWitness: Witness = {
  fullName: '',
  phoneNumber: '',
  explanation: '',
  signature: ''
}

const WitnessInput = ({
  label,
  patientName,
  witnesses,
  add,
  update,
  remove
}: IWitnessInputProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false)
  const [newWitness, setNewWitness] = useState<Witness>(initialWitness)
  const [editWitness, setEditWitness] = useState<Witness>(
    undefined as unknown as Witness
  )

  const handleChangeNewWitness = (
    key: string,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { value }
    } = event
    setNewWitness({
      id: newWitness.id ?? uuid(),
      ...newWitness,
      [key]: value
    })
  }
  const handleChangeEditWitness = (
    key: string,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { value }
    } = event
    setEditWitness({
      ...editWitness,
      [key]: value
    })
  }

  const toggleOpen = () => {
    setOpen(!open)
  }

  const handleSave = () => {
    setOpen(false)
    add(newWitness)
    setNewWitness(initialWitness)
  }

  const handleEditSave = (index: number) => {
    setIsEditOpen(false)
    update(index, editWitness)
    setNewWitness(undefined as unknown as Witness)
  }

  const handleEdiWitness = (witness: Witness) => {
    setIsEditOpen(!isEditOpen)
    if (!isEditOpen) {
      setEditWitness(witness)
    } else {
      setEditWitness(undefined as unknown as Witness)
    }
  }

  return (
    <InputGroup>
      <InputCollapsableContainer active={open} onClick={toggleOpen}>
        {open ? (
          <>
            <p>
              {t`patient`}: {patientName}
            </p>
            <IconMinus />
          </>
        ) : (
          <>
            <p>{t(label)}</p>
            <IconPlusCircle />
          </>
        )}
      </InputCollapsableContainer>
      {open && (
        <>
          <TextInput
            type="text"
            label="fullName"
            name="fullName"
            onChange={event => handleChangeNewWitness('fullName', event)}
          />
          <PhoneInput
            label="phoneNumber"
            name="phoneNumber"
            onChange={event => handleChangeNewWitness('phoneNumber', event)}
          />
          <TextInput
            type="text"
            label="explanation"
            name="explanation"
            onChange={event => handleChangeNewWitness('explanation', event)}
          />
          {/* <TextInput
            type="text"
            label="signature"
            name="signature"
            onChange={event => handleChangeNewWitness('signature', event)}
          /> */}
          <Button
            type="button"
            label="addWitness"
            textSize="small"
            onClick={handleSave}
            icon={<IconSave />}
          />
        </>
      )}
      {witnesses.map((witness, index) => {
        return (
          <div key={index}>
            <InputCollapsableContainer
              onClick={() => handleEdiWitness(witness)}
              key={index}
              active={false}
            >
              <p>{witness?.fullName}</p>
              <IconMinus onClick={() => remove(index)} />
            </InputCollapsableContainer>
            {isEditOpen && witness?.id === editWitness.id && (
              <>
                <TextInput
                  type="text"
                  label="fullName"
                  name="fullName"
                  value={editWitness?.fullName}
                  onChange={event => handleChangeEditWitness('fullName', event)}
                />
                <PhoneInput
                  label="phoneNumber"
                  name="phoneNumber"
                  value={editWitness?.phoneNumber}
                  onChange={event =>
                    handleChangeEditWitness('phoneNumber', event)
                  }
                />
                <TextInput
                  type="text"
                  label="explanation"
                  value={editWitness?.explanation}
                  name="explanation"
                  onChange={event =>
                    handleChangeEditWitness('explanation', event)
                  }
                />
                <TextInput
                  type="text"
                  label="signature"
                  name="signature"
                  value={editWitness?.signature}
                  onChange={event =>
                    handleChangeEditWitness('signature', event)
                  }
                />
                <Button
                  type="button"
                  label="saveInformation"
                  textSize="small"
                  onClick={() => handleEditSave(index)}
                  icon={<IconSave />}
                />
              </>
            )}
          </div>
        )
      })}
    </InputGroup>
  )
}

export { WitnessInput }
