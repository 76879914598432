import styled from 'styled-components'

interface IStyleProps {
  flexDirection?: 'column' | 'row'
  align?: 'flex-start' | 'flex-end' | 'center' | 'end' | 'start' | 'stretch'
  justify?:
    | 'start'
    | 'center'
    | 'end'
    | 'left'
    | 'right'
    | 'flex-start'
    | 'flex-end'
    | 'stretch'
    | 'space-between'
    | 'space-evenly'
    | 'space-around'
}

const ContainerWrapper = styled.div<IStyleProps>`
  display: flex;
  flex-direction: ${props => props.flexDirection ?? 'column'};
  align-items: ${props => props.align ?? 'flex-start'};
  justify-content: ${props => props.justify ?? 'flex-start'};
  padding: 40px 200px 40px 40px;
`

export { ContainerWrapper }
