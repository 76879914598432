import { IconArrowRight } from 'assets/images'
import { Button } from 'components'
import { FlexRow, FormContainer, RowItem } from '../styled'
import AutomaticInfoSection from './AutomaticInfoSection'
import InjurySection from './InjurySection'
import PersonalInformationSection from './PersonalInformationSection'
import SequenceOfEventsSection from './SequenceOfEventsSection'
import StateSection from './StateSection'
import TimeStampsSection from './TimeStampsSection'

const ReportFormStep1 = () => {
  return (
    <FormContainer>
      <PersonalInformationSection />
      <StateSection />
      <TimeStampsSection />
      <SequenceOfEventsSection />
      <InjurySection />
      <AutomaticInfoSection />
      <FlexRow style={{ justifyContent: 'flex-end', padding: '80px 0' }}>
        <RowItem style={{ width: '50%' }}>
          <Button
            type="submit"
            label="continue"
            variant="primary"
            icon={<IconArrowRight />}
          />
        </RowItem>
      </FlexRow>
    </FormContainer>
  )
}

export default ReportFormStep1
