import { CheckboxGroupInput } from 'components'
import { CHECKLIST_OPTIONS, CHECKLIST_SUMMER_OPTIONS } from 'constants/options'
import { useFormikContext } from 'formik'
import { Report, Seasons } from 'store/ducks'
import FormTitle from '../FormTitle'
import { FlexRow, RowItem } from '../styled'

const ChecklistSection = () => {
  const {
    values: { checklist, season },
    setFieldValue
  } = useFormikContext<Report>()

  return (
    <>
      <FormTitle title="treatmentChecklist" />
      <FlexRow>
        <RowItem>
          <CheckboxGroupInput
            name="checklist"
            value={checklist}
            options={
              season === Seasons.WINTER
                ? CHECKLIST_OPTIONS
                : CHECKLIST_SUMMER_OPTIONS
            }
            onChange={setFieldValue}
          />
        </RowItem>
      </FlexRow>
    </>
  )
}

export default ChecklistSection
