import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { t } from 'i18n'
import { isEmpty } from 'lodash'
import { useContext } from 'react'
import { If } from 'react-extras'
import { StatisticProps } from 'services'
import styled from 'styled-components'
import { StatisticPageContext } from '../../../context/StatisticPageContext'
import BarChart from './BarChart'
import Tabs from './Tabs'

const Container = styled.div`
  width: 100%;
`

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin: 2rem 0;
`

const OneColumn = styled.div`
  margin: 2rem 0;
`

interface ChartDataProps {
  title: string
  labels: string[]
  data: number[]
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ArcElement,
  BarElement,
  ChartDataLabels
)

ChartJS.defaults.font.size = 12
ChartJS.defaults.font.weight = '300'
ChartJS.defaults.color = '#919191'
ChartJS.defaults.elements.point.pointStyle = 'circle'

const Charts = () => {
  const { filterData, statistics, activeTab } = useContext(StatisticPageContext)

  const getChartDataProps = (
    titleKey: string,
    key: keyof StatisticProps
  ): ChartDataProps | null => {
    if (statistics?.[activeTab]) {
      const selectedItem = statistics[activeTab][key]

      if (selectedItem && Array.isArray(selectedItem)) {
        const title = t(titleKey)
        let labels: string[] = []
        let data: number[] = []

        selectedItem.forEach(item => {
          labels = [...labels, item.name]
          data = [...data, item.total]
          return
        })

        return {
          title,
          labels,
          data
        }
      }
    }
    return null
  }

  const injuriesByAccidentSitesProps = getChartDataProps(
    'injuriesByAccidentSites',
    'injuriesByPlace'
  )

  const transport = getChartDataProps('transport', 'transportOutsideResort')
  const safetyEquipmentUsed = getChartDataProps(
    'safetyEquipements',
    'injuriesBySafetyEquipments'
  )
  const commonInjuriesProps = getChartDataProps(
    'injuriesByBodyPart',
    'commonInjuries'
  )
  const injuriesByGenderProps = getChartDataProps(
    'injuriesByGender',
    'injuriesByGender'
  )
  const injuriesBySkillLevelProps = getChartDataProps(
    'skillLevel',
    'injuriesBySkillLevel'
  )
  const injuriesDuringIntroductionProps = getChartDataProps(
    'didTheInjuryHappenWithTheGuide',
    'injuriesDuringIntroduction'
  )
  const injuriesByRentedProps = getChartDataProps(
    'equipmentsOwnership',
    'injuriesByRented'
  )

  const injuriesByRangeAgeProps = getChartDataProps(
    'injuriesByAgeRange',
    'injuriesByRangeAge'
  )

  const injuriesByEquipmentProps = getChartDataProps(
    'injuriesByEquipment',
    'injuriesByEquipment'
  )
  const transportInsideResortProps = getChartDataProps(
    'transportInsideResort',
    'transportInsideResort'
  )
  const transportOutsideResortProps = getChartDataProps(
    'transportOutsideResort',
    'transportOutsideResort'
  )
  const treatedBy = getChartDataProps('treatedBy', 'treatedBy')

  const renderTabs = (dataVar: ChartDataProps) => {
    if (dataVar === null || isEmpty(dataVar)) return null
    const filteredNumbers = dataVar.data.filter(
      value => typeof value === 'number'
    )
    const sum = filteredNumbers.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    )
    if (sum === 0) return null
    return <Tabs {...dataVar} />
  }

  const renderBarChart = (dataVar: ChartDataProps) => {
    if (dataVar === null || isEmpty(dataVar)) return null
    if (dataVar.labels.length === 0) return null

    const objWithTranslatedName = {
      ...dataVar,
      labels: dataVar.labels.map(label => t(label)).reverse()
    }

    const objWithReversedData = {
      ...dataVar,
      labels: dataVar.labels.reverse(),
      data: dataVar.data.reverse()
    }

    const dataToRender =
      dataVar.title === t('injuriesByAgeRange')
        ? objWithReversedData
        : objWithTranslatedName

    return <BarChart {...dataToRender} />
  }

  return (
    <Container>
      <OneColumn>
        {renderBarChart(injuriesByAccidentSitesProps as ChartDataProps)}
      </OneColumn>
      <TwoColumns>
        {renderTabs(injuriesBySkillLevelProps as ChartDataProps)}

        {renderTabs(transport as ChartDataProps)}
      </TwoColumns>
      <OneColumn>
        {renderBarChart(commonInjuriesProps as ChartDataProps)}
      </OneColumn>
      <TwoColumns>
        <If condition={filterData.gender === 'ALL'}>
          {renderTabs(injuriesByGenderProps as ChartDataProps)}
        </If>
        {renderTabs(injuriesDuringIntroductionProps as ChartDataProps)}
      </TwoColumns>{' '}
      <OneColumn>
        {renderBarChart(injuriesByRangeAgeProps as ChartDataProps)}
      </OneColumn>
      <TwoColumns>
        {renderTabs(safetyEquipmentUsed as ChartDataProps)}
        {renderTabs(injuriesByRentedProps as ChartDataProps)}
      </TwoColumns>
      <OneColumn>
        {renderBarChart(injuriesByEquipmentProps as ChartDataProps)}
      </OneColumn>
      <TwoColumns>
        {renderTabs(transportInsideResortProps as ChartDataProps)}
        {renderTabs(transportOutsideResortProps as ChartDataProps)}
      </TwoColumns>
      <OneColumn>{renderBarChart(treatedBy as ChartDataProps)}</OneColumn>
    </Container>
  )
}

export default Charts
