import { CheckboxGroupInput } from 'components'
import {
  TREATED_BY_OPTIONS,
  TREATED_BY_SUMMER_OPTIONS
} from 'constants/options'
import { useFormikContext } from 'formik'
import { Report, Seasons } from 'store/ducks'
import FormTitle from '../FormTitle'
import { FlexRow, RowItem } from '../styled'

const TreaterSection = () => {
  const {
    values: { treatedBy, season },
    setFieldValue
  } = useFormikContext<Report>()

  return (
    <>
      <FormTitle title="treatedBy" />
      <FlexRow>
        <RowItem>
          <CheckboxGroupInput
            name="treatedBy"
            value={treatedBy}
            options={
              season === Seasons.WINTER
                ? TREATED_BY_OPTIONS
                : TREATED_BY_SUMMER_OPTIONS
            }
            onChange={setFieldValue}
          />
        </RowItem>
      </FlexRow>
    </>
  )
}

export default TreaterSection
