import { IconCircleX, IconExit, IconReset } from 'assets/images'
import { Container, MaterialModal } from 'components'
import { PageTitle } from 'components/PageHeader/styled'
import { useRole } from 'hooks'
import useLogout from 'hooks/useLogout'
import { t } from 'i18n'
import { useEffect, useState } from 'react'
import { If } from 'react-extras'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Dispatch, bindActionCreators } from 'redux'
import { deleteUser } from 'services'
import { Role } from 'store/ducks'
import * as AuthActions from 'store/ducks/auth/actions'
import { ApplicationState } from '../../store'
import MyPageForm from './Form'
import {
  BUTTON_COLOR,
  Button,
  ButtonContainer,
  ColumnContainer,
  ColumnTitle,
  FlexContainer,
  SmallTitle
} from './styled'

const SettingsPage = () => {
  const navigate = useNavigate()
  const [isUserEdited, setIsUserEdited] = useState<boolean>(false)
  const [role, setRole] = useState(t('employee'))
  const [openModal, setOpenModal] = useState<boolean>(false)
  const user = useSelector((state: ApplicationState) => state.auth.data)

  const { mutate: logout } = useLogout()

  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  const isSysAdmin = useRole([Role.SYS_ADMIN])
  const isResortAdmin = useRole([Role.RESORT_ADMINISTRATOR])

  useEffect(() => {
    if (isSysAdmin && isResortAdmin) return setRole(t('sysAndResortAdmin'))
    if (isSysAdmin) return setRole(t('sysAdmin'))
    if (isResortAdmin) return setRole(t('resortAdmin'))
  }, [isSysAdmin, isResortAdmin])

  const handleResetPassword = async () => {
    navigate('/forgot-password')
  }

  const handleDeleteUser = async () => {
    await deleteUser()
    logout()
  }

  const formProps = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phoneNumber: user.phoneNumber
  }

  return (
    <Container>
      <PageTitle>{t('myPage')}</PageTitle>
      <MaterialModal isOpen={openModal} onClose={handleCloseModal}>
        <ColumnTitle>You are about to delete your account.</ColumnTitle>
        <p>You can&apos;t regain access once it&apos;s deleted.</p>
        <ButtonContainer>
          <Button
            onClick={handleDeleteUser}
            $bgColor={BUTTON_COLOR.RED}
            $bordered
          >
            {t('deleteAccount')} <IconCircleX />
          </Button>

          <Button onClick={handleCloseModal} $bgColor={BUTTON_COLOR.BLUE}>
            {t('cancel')} <IconCircleX />
          </Button>
        </ButtonContainer>
      </MaterialModal>
      <FlexContainer>
        <ColumnContainer>
          <ColumnTitle>{t('personalInformation')}</ColumnTitle>
          <MyPageForm
            user={formProps}
            setIsUserEdited={setIsUserEdited}
            isUserEdited={isUserEdited}
          />
        </ColumnContainer>
        <ColumnContainer>
          <ColumnTitle>{t('yourConnection')}</ColumnTitle>
          <SmallTitle>{user?.resort?.name}</SmallTitle>
          <p>{role}</p>
          <ColumnTitle>{t('administer')}</ColumnTitle>
          <If condition={!isUserEdited}>
            <Button onClick={handleOpenModal} $bgColor={BUTTON_COLOR.RED}>
              {t('deleteAccount')} <IconCircleX />
            </Button>
            <Button onClick={handleResetPassword} $bgColor={BUTTON_COLOR.RED}>
              {t('resetPassword')} <IconReset />
            </Button>
          </If>
          <Button onClick={() => logout()} $bgColor={BUTTON_COLOR.BLUE}>
            {t('logout')} <IconExit />
          </Button>
        </ColumnContainer>
      </FlexContainer>
    </Container>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.auth.data
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(AuthActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage)
