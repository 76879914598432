import styled from 'styled-components'

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
`

const ReportCardContainer = styled(FlexDiv)`
  background-color: #385ebf;
  padding: 25px;
  min-width: 360px;
  min-width: 360px;
  margin-right: 40px;
`

const ReportCardHeaderContainer = styled(FlexDiv)`
  justify-content: space-between;
  flex-direction: row;
`

const ReportInfo = styled(FlexDiv)<{ justify?: string }>`
  justify-content: ${props => props.justify ?? 'flex-start'};
`

const ReportCardBodyContainer = styled(FlexDiv)`
  justify-content: space-between;
  flex-direction: row;
  margin-top: 20px;
`

const CardActionButton = styled.button`
  border: none;
  background: transparent;
  outline: none;
  with: 45px;
  height: 30px;
  cursor: pointer;
`

export {
  CardActionButton,
  ReportCardBodyContainer,
  ReportCardContainer,
  ReportCardHeaderContainer,
  ReportInfo
}
