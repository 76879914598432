import { z } from 'zod'

import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18n-js'
import { useState } from 'react'
import { If } from 'react-extras'
import { useForm } from 'react-hook-form'
import {
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
  isValidPhoneNumber
} from 'react-phone-number-input'
import { Link, useSearchParams } from 'react-router-dom'
import { ErrorMessage, LinkWrapper, SubmitButton } from '../styled'

import { IconArrowRight } from 'assets/images'
import PhoneNumberInput from 'components/Inputs/PhoneNumberInput'
import useAuthSession from 'hooks/useAuthSession'
import { useRequestCode } from 'hooks/useForgotPassword'
import { forgotPasswordSchema } from './forgotPassword.schema'
import { ForgotPasswordFormContainer } from './styled'

type SignInFormDataProps = z.infer<typeof forgotPasswordSchema>

const SignInForm = () => {
  const FormHook = useForm<SignInFormDataProps>({
    resolver: zodResolver(forgotPasswordSchema)
  })

  const [searchParams, setSearchParams] = useSearchParams()

  const { mutate: reqCode } = useRequestCode()
  const { isAuthenticated } = useAuthSession()

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = FormHook

  const [userNameError, setUserNameError] = useState<string>('')
  const [apiError, setApiError] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  // Watch for changes in the form
  watch(() => setApiError(''))

  const onSubmit = handleSubmit(async data => {
    if (!data.userName) return
    setIsSubmitting(true)
    setUserNameError('')

    const numExist = isPossiblePhoneNumber(data.userName)
    const numValid = isValidPhoneNumber(data.userName)
    const numFormat = formatPhoneNumberIntl(data.userName)

    if (!numExist || !numValid || !numFormat) {
      setIsSubmitting(false)
      return setUserNameError('Invalid phone number')
    }

    reqCode(
      { username: data.userName },
      {
        onSuccess: () => {
          setSearchParams({
            ...searchParams,
            step: '2',
            userName: data.userName
          })
          return setIsSubmitting(false)
        },
        onError: error => {
          setApiError(error.message)
          return setIsSubmitting(false)
        }
      }
    )
  })

  return (
    <ForgotPasswordFormContainer>
      <form onSubmit={onSubmit}>
        <PhoneNumberInput
          errMessage={errors.userName?.message ?? null}
          invalidNumberError={userNameError}
          name="userName"
          control={control}
          label="phoneNumber"
        />
        <If condition={!!apiError}>
          <ErrorMessage $textAlign="center" $width="100%" $noWrap>
            {apiError}
          </ErrorMessage>
        </If>
        <SubmitButton type="submit" disabled={isSubmitting}>
          {t('next')}
          <IconArrowRight />
        </SubmitButton>
        <If condition={!isAuthenticated}>
          <LinkWrapper>
            <Link to="/sign-in">{t('login')}</Link>
          </LinkWrapper>
        </If>
      </form>
    </ForgotPasswordFormContainer>
  )
}

export default SignInForm
