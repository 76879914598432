import { t } from 'i18n'
import { InputHTMLAttributes } from 'react'
import { InputError, InputGroup, InputLabel, TextAreaContainer } from './styled'

interface ITextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  label: string
  placeholder?: string
  meta?: {
    error?: string
    touched?: boolean
  }
}

const TextAreaInput = ({
  label,
  meta,
  placeholder,
  ...props
}: ITextAreaProps) => {
  return (
    <InputGroup>
      <InputLabel htmlFor={props.name}>{t(label)}</InputLabel>
      <TextAreaContainer placeholder={placeholder} {...props} rows={12} />
      {meta?.touched && meta?.error && <InputError>{t(meta.error)}</InputError>}
    </InputGroup>
  )
}

export { TextAreaInput }
