import { ButtonContainer } from 'components/Button/styled'
import PhoneInput from 'react-phone-number-input'
import styled from 'styled-components'
import theme from 'styles/theme'

interface InputProps {
  $isError?: boolean
}

const PhoneNumberInput = styled(PhoneInput)<InputProps>`
  position: relative;
  width: 100%;

  .PhoneInputCountry {
    top: 1.2rem;
    left: 0.8rem;
    position: absolute;
    align-self: stretch;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }

  .PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
  }

  .PhoneInputCountryIcon--border {
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow:
      0 0 0 1px rgba(0, 0, 0, 0.5),
      inset 0 0 0 1px rgba(0, 0, 0, 0.5);
  }

  .PhoneInputCountryIconImg {
    display: block;
    height: 19px;
  }

  .PhoneInputInput {
    width: calc(100% - 3.8rem);
    padding: 0.8rem 0.8rem 0.8rem 3rem;
    border: ${({ $isError }) =>
      $isError ? '1px solid red' : '1px solid rgb(43, 43, 43)'};
    border-radius: 4px;
    margin-top: 0.5rem;
  }
`

const InputWrapper = styled.div`
  margin-bottom: 1rem;
  font-size: 1rem;
`

const Label = styled.label`
  font-size: 0.8rem;
`

const Input = styled.input<InputProps>`
  width: calc(100% - 1.6rem);
  padding: 0.8rem;
  border: ${({ $isError }) =>
    $isError ? '1px solid red' : '1px solid rgb(43, 43, 43)'};

  border-radius: 4px;
  margin-top: 0.5rem;
`

interface ErrorMessageProps {
  $textAlign?: 'center' | 'left' | 'right'
  $width?: 'auto' | '100%'
  $noWrap?: boolean
}

const ErrorMessage = styled.span<ErrorMessageProps>`
  display: inline-block;
  color: ${theme.colors.red};
  font-size: 0.8rem;
  text-align: ${({ $textAlign }) => $textAlign || 'left'};

  width: ${({ $width }) => $width || 'auto'};
  &::before {
    content: ${({ $noWrap }) => ($noWrap ? '' : '(')};
  }

  &::after {
    content: ${({ $noWrap }) => ($noWrap ? '' : ')')};
  }
`

const LinkWrapper = styled.div`
  font-size: 0.8rem;
  width: 100%;
  text-align: center;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
`

const SubmitButton = styled(ButtonContainer)`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  margin: 1rem 0;
`

export {
  ErrorMessage,
  Input,
  InputWrapper,
  Label,
  LinkWrapper,
  PhoneNumberInput,
  SubmitButton
}
