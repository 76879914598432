import { t } from 'i18n'
import {
  RadioGroupContainer,
  RadioGroupItemLabel,
  RadioGroupLabel
} from './styled'

type OptionItem = {
  id: string | boolean
  label: string | number
}

interface IRadioGroupInputProps {
  name: string
  value?: string | number | boolean
  label?: string
  options: OptionItem[]
  onChange: (name: string, value: string | boolean) => void
}

const RadioGroupInput = ({
  name,
  value,
  label,
  onChange,
  options
}: IRadioGroupInputProps) => {
  const handleChange = (value: string | boolean): void => {
    let retVal = value
    if (value && typeof value === 'string') {
      if (value.toLowerCase() === 'true') {
        retVal = true
      }
      if (value.toLowerCase() === 'false') {
        retVal = false
      }
    }
    onChange(name, retVal)
  }
  return (
    <RadioGroupContainer>
      {label && <RadioGroupLabel>{t(label)}</RadioGroupLabel>}
      <div>
        {options.map(({ id, label }, index) => (
          <RadioGroupItemLabel key={index} active={id === value}>
            <input
              type="radio"
              name={name}
              onChange={() => handleChange(id)}
              value={id.toString()}
              checked={id === value}
            />
            {t(label)}
          </RadioGroupItemLabel>
        ))}
      </div>
    </RadioGroupContainer>
  )
}

export { RadioGroupInput }
