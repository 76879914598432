import { t } from 'i18n-js'
import { If } from 'react-extras'
import styled from 'styled-components'
import { ErrorMessage, InputWrapper, Label } from './styles'

interface Props {
  errMessage: string | null
  name: string
  label?: string
  register: any
  [x: string]: any
}

const Input = ({ errMessage, name, label, register, ...rest }: Props) => {
  const isError = errMessage !== null || !!errMessage

  return (
    <InputWrapper>
      <Label>
        {t(label ?? name)}{' '}
        <If condition={isError}>
          <ErrorMessage>{errMessage}</ErrorMessage>
        </If>
        <StyledInput
          $isError={isError}
          placeholder={t(label ?? name)}
          {...register(name)}
          {...rest}
        />
      </Label>
    </InputWrapper>
  )
}

export default Input

interface InputProps {
  $isError?: boolean
}

const StyledInput = styled.input<InputProps>`
  width: calc(100% - 1.6rem);
  padding: 0.8rem;
  border: ${({ $isError }) =>
    $isError ? '1px solid red' : '1px solid rgb(43, 43, 43)'};

  border-radius: 4px;
  margin-top: 0.5rem;
`
