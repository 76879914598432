import { IconClose, IconComment, IconSave } from 'assets/images'
import { Button, CheckboxInput, WeatherIcon } from 'components'
import { TextAreaContainer } from 'components/form-fields/styled'
import { useFormikContext } from 'formik'
import { t } from 'i18n'
import { useState } from 'react'
import { If } from 'react-extras'
import { formatDate } from 'services'
import { Report, Seasons } from 'store/ducks'
import { ModalOverlay } from '../../../../../../styles/global'
import { FlexRowSpaceBetween } from '../../../../../Report/Landing/LandingReportHeader/styled'
import {
  FlexRow,
  IconCloseContainer,
  RowItem,
  SaveButtonContainer,
  WeatherAndLocationActionIconContainer,
  WeatherAndLocationContainer,
  WeatherLabel,
  WeatherModal,
  WeatherModalLeftColumn,
  WeatherModalRightColumn,
  WeatherTemperatureValue,
  WeatherValue
} from '../../styled'

const AutomaticInfoWeather = () => {
  const [open, setOpen] = useState<boolean>(false)

  const {
    values: { weather, season },
    setFieldValue,
    handleChange,
    handleBlur
  } = useFormikContext<Report>()

  const toggleOpen = () => {
    setOpen(!open)
  }

  return (
    <WeatherAndLocationContainer>
      <FlexRowSpaceBetween>
        <If condition={weather && weather?.weatherInfo ? true : false}>
          <WeatherIcon
            widthImg="50px"
            heightImg="50px"
            marginImg="-5px 0 0 10px"
            icon={
              (weather &&
                weather?.weatherInfo &&
                weather?.weatherInfo[0].icon) ||
              ''
            }
          />
        </If>
        <If condition={!weather}>
          <div></div>
        </If>
        <p>{weather?.windSpeed?.toFixed(2) ?? '-'} m/s</p>
      </FlexRowSpaceBetween>
      <FlexRowSpaceBetween
        style={{
          minHeight: '60%',
          justifyContent: 'flex-end',
          alignItems: 'flex-end'
        }}
      >
        <WeatherTemperatureValue>
          {weather?.temperature?.toFixed() ?? '-'}℃
        </WeatherTemperatureValue>
        <WeatherAndLocationActionIconContainer>
          <IconComment onClick={toggleOpen} />
        </WeatherAndLocationActionIconContainer>
      </FlexRowSpaceBetween>
      {open && (
        <ModalOverlay>
          <WeatherModal>
            <FlexRow>
              <WeatherModalLeftColumn>
                <WeatherLabel>{t`date`}</WeatherLabel>
                <WeatherValue>{formatDate(weather?.time)}</WeatherValue>
                <WeatherLabel>{t`temperature`}</WeatherLabel>
                <h2>{weather?.temperature?.toFixed() ?? '-'}℃</h2>
              </WeatherModalLeftColumn>
              <WeatherModalRightColumn>
                <WeatherLabel>{t`wind`}</WeatherLabel>
                <WeatherValue>
                  {weather?.windSpeed?.toFixed(2) ?? '-'} m/s
                </WeatherValue>
              </WeatherModalRightColumn>
              <IconCloseContainer onClick={toggleOpen}>
                <WeatherLabel>{t`close`}</WeatherLabel>
                <IconClose />
              </IconCloseContainer>
            </FlexRow>
            {/* TODO:: Either extend weather property on api with comment,
            or change name and value property with fitting property name, when api is extended
            */}
            <If condition={season === Seasons.SUMMER}>
              <WeatherLabel>{t('otherRelationships')}</WeatherLabel>
              <FlexRow>
                <RowItem width={30} style={{ display: 'flex' }}>
                  <CheckboxInput
                    name="weather.localConditions.dry"
                    label="dry"
                    value={weather?.localConditions?.dry}
                    onChange={setFieldValue}
                  />
                  <CheckboxInput
                    name="weather.localConditions.wet"
                    label="wet"
                    value={weather?.localConditions?.wet}
                    onChange={setFieldValue}
                  />
                  <CheckboxInput
                    name="weather.localConditions.iceSnowFrost"
                    label="iceSnowFrost"
                    value={weather?.localConditions?.iceSnowFrost}
                    onChange={setFieldValue}
                  />
                </RowItem>
              </FlexRow>
            </If>
            <WeatherLabel>{t('comments')}</WeatherLabel>
            <TextAreaContainer
              placeholder={t`addComment`}
              name="weather.comment"
              value={weather?.comment}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <SaveButtonContainer>
              <Button
                buttonStyle={{ borderRadius: '8px' }}
                type="button"
                variant="primary"
                label="save"
                icon={<IconSave />}
                onClick={toggleOpen}
              />
            </SaveButtonContainer>
          </WeatherModal>
        </ModalOverlay>
      )}
    </WeatherAndLocationContainer>
  )
}

export default AutomaticInfoWeather
