import { Pagination } from '@mui/material'
import { UserTable } from 'components'
import { chunk } from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PaginatorHolder } from '../styled'
import { PendingUsersContainer } from './styled'

interface IPendingUsersProps {
  content: TableItem[]
}

const Pending = ({ content }: IPendingUsersProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const navigate = useNavigate()

  const handlePageChange = (_: unknown, page: number) => {
    setCurrentPage(page)
  }

  const userEdit = (userId?: string | number) => {
    navigate(`/user/${userId}`)
  }

  const [itemsChunk, setItemsChunk] = useState<TableItem[][]>([])
  const [pages, setPages] = useState<number[]>([])

  const configPages = (content: TableItem[]) => {
    const _chunk = chunk(content, 5)
    const _pages = []
    setItemsChunk(_chunk)
    for (const i of _chunk) {
      _pages.push(_chunk.indexOf(i) + 1)
    }
    setPages(_pages)
  }

  useEffect(() => {
    configPages(content)
  }, [content])

  return (
    <PendingUsersContainer>
      <UserTable
        keyIndex={4}
        showIndex={false}
        onEdit={userEdit}
        columns={[
          'Name',
          'Phone',
          'Email',
          'InquirySent',
          null as unknown as string
        ]}
        items={itemsChunk[currentPage - 1]}
      />
      <PaginatorHolder>
        {pages.length > 0 && (
          <Pagination count={pages.length} onChange={handlePageChange} />
        )}
      </PaginatorHolder>
    </PendingUsersContainer>
  )
}

export default Pending
