import { DateTimePickerContainer } from './styled'

interface IReportHeaderDateTimePickerProps {
  open: boolean
  toggleOpen: () => void
  value?: string | Date
  onChange: (value: string) => void
}

const ReportHeaderDateTimePicker = ({
  open,
  toggleOpen,
  value,
  onChange
}: IReportHeaderDateTimePickerProps) => {
  return (
    <DateTimePickerContainer
      showTimeSelect
      onChange={value =>
        onChange(value ? value.toString() : new Date().toString())
      }
      selected={value ? new Date(value) : new Date()}
      timeFormat="HH:mm"
      open={open}
      onClickOutside={toggleOpen}
      customInput={null}
    />
  )
}

export default ReportHeaderDateTimePicker
