import styled from 'styled-components'

export const EventMonitoringContainer = styled.div`
  max-width: 20vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  margin: 10px 0;
  padding: 0 10px;
  justify-content: space-between;
  background-color: #e5e5e5;
  cursor: pointer;

  &:first-child {
    margin-top: 25px;
  }

  &:last-child {
    margin-bottom: 25px;
  }
`

export const EventMonitoringText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  min-width: 60px;
`

export const EventMonitoringIcon = styled.div`
  display: flex;
  flex-direction: column;
  width: 30px;
  align-items: center;
  justify-content: center;
`
