import styled from 'styled-components'

const UserEditContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const UserEditContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const UserEditArchive = styled.div`
  margin-left: 15px;
`
const ArchiveButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  height: 35px;
  align-items: center;
  & span {
    margin-left: 10px;
  }
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  flex-grow: 1;
`

export {
  ArchiveButton,
  RowContainer,
  UserEditArchive,
  UserEditContainer,
  UserEditContentContainer
}
