import { IconCircleX } from 'assets/images'
import { BackButton, Container, Modal, TimeAndDate } from 'components'
import { PageTitle } from 'components/PageHeader/styled'
import { useRole } from 'hooks'
import { t } from 'i18n'
import { PageHeaderWrapper } from 'pages/NewUser/styled'
import { useCallback, useEffect, useState } from 'react'
import { If } from 'react-extras'
import { useNavigate, useParams } from 'react-router-dom'
import { deleteUserById, getUserById, updateUserById } from 'services'
import { Role, User } from 'store/ducks'
import { UserEditForm } from '../../containers/UserEditForm'
import {
  ArchiveButton,
  RowContainer,
  UserEditArchive,
  UserEditContainer,
  UserEditContentContainer
} from './styled'

const UserEditPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [user, setUser] = useState<User>()

  const isSysAdmin = useRole([Role.SYS_ADMIN])

  const [showModal, setShowModal] = useState<boolean>(false)
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false)

  const adminGetUser = useCallback(async () => {
    const _user = await getUserById(id)
    setUser(_user)
  }, [id])

  const archiveAccount = async () => {
    if (user) {
      user.userStatus = 'ARCHIVED'
    }
    await updateUserById(user?.id, user)
    setShowModal(false)
    navigate(`/users`)
  }

  const deleteAccount = async () => {
    await deleteUserById(user?.id)
    setShowModalDelete(false)
    navigate(`/users`)
  }

  useEffect(() => {
    adminGetUser()
  }, [adminGetUser])

  if (showModal) {
    return (
      <Modal
        type="CONFIRM"
        title={t('archiveAccount')}
        onDismiss={() => setShowModal(false)}
        onConfirm={archiveAccount}
        description={t('doYouWantToArchiveThisUserAccount')}
      />
    )
  }
  if (showModalDelete) {
    return (
      <Modal
        type="CONFIRM"
        title={t('deleteAccount')}
        onDismiss={() => setShowModalDelete(false)}
        onConfirm={deleteAccount}
        description={t('doYouWantToDeleteThisUserAccount')}
      />
    )
  }

  return (
    <Container>
      <UserEditContainer>
        <PageHeaderWrapper>
          <PageTitle>
            {user ? user?.firstName + ' ' + user?.lastName : ''}
          </PageTitle>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end'
            }}
          >
            <BackButton goBack={() => navigate('/users')} />
            <TimeAndDate />
          </div>
        </PageHeaderWrapper>
        <UserEditContentContainer>
          <UserEditForm user={user} />
          <RowContainer>
            <UserEditArchive>
              <ArchiveButton onClick={() => setShowModal(true)}>
                <IconCircleX />
                <span>{t('archiveAccount')}</span>
              </ArchiveButton>
            </UserEditArchive>
            <If condition={isSysAdmin}>
              <UserEditArchive>
                <ArchiveButton onClick={() => setShowModalDelete(true)}>
                  <IconCircleX />
                  <span>{t('deleteAccount')}</span>
                </ArchiveButton>
              </UserEditArchive>
            </If>
          </RowContainer>
        </UserEditContentContainer>
      </UserEditContainer>
    </Container>
  )
}

export default UserEditPage
