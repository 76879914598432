import {
  ChangeEvent,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState
} from 'react'
import { getFileUrl, getSignedUrl, putFileS3 } from 'services'
import { Media, SectionKey } from 'store/ducks'
import {
  ArchiveImage,
  ArchiveImageInputContainer,
  InputContainer
} from './styled'

type SignedURL = {
  url: string
}

interface IArchiveImageInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  reportId?: string
  image?: Media
  setImage: (image: Media) => void
  meta?: {
    error?: string
    touched?: boolean
  }
}

const ArchiveImageInput = ({
  reportId,
  setImage,
  image,
  ...props
}: IArchiveImageInputProps) => {
  const [imageUrl, setImageUrl] = useState<string>('')

  useEffect(() => {
    const getImage = async (path: string) => {
      const url = await getFileUrl(path)
      setImageUrl(url)
    }
    if (image?.path) {
      getImage(image?.path)
    }
  }, [image?.path])

  const imageRef = useRef<HTMLInputElement>(null)

  const handleOpen = () => {
    imageRef?.current?.click()
  }

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0]
    try {
      if (!file || !reportId) return
      const imagePath = `images/${reportId}/${file?.name}`
      const signedUrl: SignedURL = await getSignedUrl(imagePath)
      await putFileS3(file, signedUrl.url)
      const newImage: Media = {
        fileName: file?.name,
        type: 'PICTURE',
        fileSize: file?.size,
        path: imagePath,
        uploaded: true,
        section: SectionKey.ARCHIVE
      }
      setImage(newImage)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div>
      <InputContainer
        type="file"
        accept=".png, .jpg, .jpeg"
        ref={imageRef}
        onChange={handleUpload}
        {...props}
      />
      {imageUrl ? (
        <ArchiveImage src={imageUrl} alt="Image" />
      ) : (
        <ArchiveImageInputContainer onClick={handleOpen} />
      )}
    </div>
  )
}

export { ArchiveImageInput }
