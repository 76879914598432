import { Loading } from 'components'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Dispatch, bindActionCreators } from 'redux'
import { Report, ReportState } from 'store/ducks'
import * as ReportActions from 'store/ducks/report/actions'
import { ApplicationState } from '../../../store'
import ReportFormStep1 from '../components/form-components/Step1'
import ReportFormStep2 from '../components/form-components/Step2'
import ReportFormStep3 from '../components/form-components/Step3'
import ReportFormStep4 from '../components/form-components/Step4'
import StepArchive from '../components/form-components/StepArchive'
import ReportHeader from '../components/report-header'

interface IReportArchiveProps {
  report: ReportState
  getReportRequest: (reportId: number) => void
  createReportRequest: (data: Report) => void
}

const ReportArchive = ({
  report,
  getReportRequest,
  createReportRequest
}: IReportArchiveProps) => {
  const [formStep, setFormStep] = useState<number>(1)

  const { reportId } = useParams()
  const { loading, data } = report

  useEffect(() => {
    getReportRequest(Number(reportId))
  }, [getReportRequest, reportId])

  const handleSubmit = (values: Report) => {
    createReportRequest(values)
    switch (formStep) {
      case 1:
        setFormStep(2)
        return
      case 2:
        setFormStep(3)
        return
      case 3:
        setFormStep(4)
        return
      case 4:
        setFormStep(5)
        return
      default:
        return
    }
  }

  const handleBack = () => {
    switch (formStep) {
      case 1:
        setFormStep(1)
        return
      case 2:
        setFormStep(1)
        return
      case 3:
        setFormStep(2)
        return
      case 4:
        setFormStep(3)
        return
      default:
        return
    }
  }

  if (loading) return <Loading />
  return (
    <Formik initialValues={data} onSubmit={handleSubmit}>
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <ReportHeader formStep={formStep} />
          {formStep === 1 && <StepArchive />}
          {formStep === 2 && <ReportFormStep1 />}
          {formStep === 3 && <ReportFormStep2 handleBack={handleBack} />}
          {formStep === 4 && (
            <ReportFormStep3
              handleBack={handleBack}
              setFormStep={setFormStep}
            />
          )}
          {formStep === 5 && (
            <ReportFormStep4
              handleBack={handleBack}
              setFormStep={setFormStep}
            />
          )}
        </Form>
      )}
    </Formik>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  report: state.report
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(ReportActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ReportArchive)
