import { colors, textSizes } from './variables'

const SIDEBAR_WIDTH_MIN = 80
const SIDEBAR_WIDTH_MAX = 340

const theme = {
  colors,
  textSizes,
  SIDEBAR_WIDTH_MIN,
  SIDEBAR_WIDTH_MAX
}

export default theme

export { theme }
