import { IconArrowRight } from 'assets/images'
import { ArchiveImageInput, Button, DateInput, TextInput } from 'components'
import { useFormikContext } from 'formik'
import { t } from 'i18n'
import { Report } from 'store/ducks'
import { ArchiveContainer, ArchiveWrapper } from './styled'

// TODOO:: This component is implemented completelly blindly, because api was not ready
// Please adjust this according to api implementation
const StepArchive = () => {
  const {
    values: { SK, archive },
    handleChange,
    handleBlur,
    setFieldValue
  } = useFormikContext<Report>()

  return (
    <ArchiveWrapper>
      <h1>{t`archiveReport`}</h1>
      <ArchiveContainer>
        <div style={{ width: '40%' }}>
          <ArchiveImageInput
            reportId={SK}
            name={'archive.image'}
            image={archive?.paperPicture}
            setImage={picture => setFieldValue('archive.image', picture)}
          />
        </div>
        <div style={{ width: '60%' }}>
          <DateInput
            label="date"
            name="archive.createdAt"
            onChange={setFieldValue}
            value={
              archive?.injuryDate ? new Date(archive?.injuryDate) : new Date()
            }
          />
          <TextInput
            type="text"
            label="pictureName"
            name="archive.name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={archive?.paperPicture?.fileName || ''}
            required
          />
        </div>
      </ArchiveContainer>
      <Button
        type="submit"
        label="continue"
        variant="primary"
        icon={<IconArrowRight />}
      />
    </ArchiveWrapper>
  )
}

export default StepArchive
