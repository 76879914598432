import { Report } from '../report/types'

export enum GetReportsTypes {
  GET_REPORTS_REQUEST = '@report/GET_REPORTS_REQUEST',
  GET_REPORTS_SUCCESS = '@report/GET_REPORTS_SUCCESS',
  GET_REPORTS_FAILURE = '@report/GET_REPORTS_FAILURE'
}

export interface ReportsState {
  readonly data: Report[]
  readonly loading: boolean
  readonly error: boolean
  readonly errorStack: any
}
