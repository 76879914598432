import { IconSpinner } from 'assets/images'
import React from 'react'
import styled from 'styled-components'

const SpinnerContainer = styled.div`
  margin: 5px;
`

interface ISpinnerProps {
  style?: React.CSSProperties
}

const Spinner = ({ style }: ISpinnerProps) => {
  return (
    <SpinnerContainer style={style}>
      <IconSpinner />
    </SpinnerContainer>
  )
}

export { Spinner }
