import {
  emailSchema,
  nameSchema,
  phoneNumberSchema
} from 'schema/common.schema'
import { z } from 'zod'

export const newUserSchema = z.object({
  firstName: nameSchema,
  lastName: nameSchema,
  email: emailSchema,
  phoneNumber: phoneNumberSchema
})
