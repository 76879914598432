import { IconComment } from 'assets/images'
import { t } from 'i18n'
import { ChangeEvent, useState } from 'react'
import {
  CheckboxGroupContainer,
  CheckboxGroupItemLabel,
  CheckboxGroupLabel,
  CheckboxWrapContainer,
  CommentContainer,
  InputCollapsableContainer,
  InputGroup,
  TextAreaContainer
} from './styled'

type OptionItem = {
  id: string
  label: string | number
}

interface ICheckboxGroupWithCommentInputProps {
  name: string
  commentName: string
  value: any
  label?: string
  options: OptionItem[]
  onChange: (name: string, value: string | boolean) => void
}

const CheckboxGroupWithCommentInput = ({
  name,
  commentName,
  value,
  label,
  onChange,
  options
}: ICheckboxGroupWithCommentInputProps) => {
  const isCommentOpen =
    value && value[commentName] && value[commentName].length > 0 ? true : false

  const [openComment, setOpenComment] = useState<boolean>(isCommentOpen)

  const handleCheck = (event: ChangeEvent<HTMLInputElement>, key: string) => {
    const {
      target: { checked }
    } = event
    onChange(`${name}.${key}`, checked)
  }

  const toggleOpenComment = (): void => {
    setOpenComment(!openComment)
  }

  const handleUpdateComment = (
    event: ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const {
      target: { value }
    } = event
    onChange(`${name}.other`, !!value)
    onChange(`${name}.${commentName}`, value)
  }

  return (
    <CheckboxGroupContainer>
      {label && <CheckboxGroupLabel>{t(label)}</CheckboxGroupLabel>}
      <CheckboxWrapContainer>
        {options.map(({ id, label }, index) => (
          <CheckboxGroupItemLabel
            htmlFor={id}
            key={index}
            active={(value && value[id]) ?? false}
          >
            <input
              id={id}
              type="checkbox"
              name={name}
              onChange={e => handleCheck(e, id)}
              checked={(value && value[id]) ?? false}
            />
            <span className="checkmark" />
            <p>{t(label)}</p>
          </CheckboxGroupItemLabel>
        ))}
      </CheckboxWrapContainer>
      <CommentContainer>
        <InputGroup>
          <InputCollapsableContainer
            active={openComment}
            onClick={toggleOpenComment}
          >
            <p>{t`otherAddComment`}</p>
            <IconComment />
          </InputCollapsableContainer>
          {openComment && (
            <TextAreaContainer
              value={value ? value[commentName] : ''}
              rows={12}
              onChange={handleUpdateComment}
            />
          )}
        </InputGroup>
      </CommentContainer>
    </CheckboxGroupContainer>
  )
}

export { CheckboxGroupWithCommentInput }
