import { z } from 'zod'
import {
  passwordSchema,
  phoneNumberSchema
} from '../../../schema/common.schema'

export const newPasswordSchema = z.object({
  code: z
    .string()
    .min(6, 'requires 6-digits number.')
    .max(6, 'requires 6-digits number.'),
  newPassword: passwordSchema
})

export const forgotPasswordSchema = z.object({
  userName: phoneNumberSchema
})
