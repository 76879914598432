import { IconLoader } from 'assets/images'
import { CSSProperties } from 'styled-components'
import { LoaderContainer } from './styled'

interface ILoaderProps {
  style?: CSSProperties
}

const Loading = ({ ...props }: ILoaderProps) => {
  return (
    <LoaderContainer {...props}>
      <IconLoader />
    </LoaderContainer>
  )
}

export { Loading }
