import { action } from 'typesafe-actions'
import {
  ClearReportTypes,
  CreateReportTypes,
  GetReportTypes,
  Report
} from './types'

export const getReportRequest = (id: number) =>
  action(GetReportTypes.GET_REPORT_REQUEST, id)

export const getReportSuccess = (data: Report) =>
  action(GetReportTypes.GET_REPORT_SUCCESS, data)

export const getReportFailure = (error: Error) =>
  action(GetReportTypes.GET_REPORT_FAILURE, { errorStack: error })

export const createReportRequest = (formData: Report) =>
  action(CreateReportTypes.CREATE_REPORT_REQUEST, formData)

export const createReportSuccess = (data: Report) =>
  action(CreateReportTypes.CREATE_REPORT_SUCCESS, data)

export const createReportFailure = (error: Error) =>
  action(CreateReportTypes.CREATE_REPORT_FAILURE, { errorStack: error })

export const clearReport = () => action(ClearReportTypes.CLEAR_REPORT)

export const clearReportSuccess = () =>
  action(ClearReportTypes.CLEAR_REPORT_SUCCESS)

export const getReportRequestOverride = (id: number) =>
  action(GetReportTypes.GET_REPORT_REQUEST_OVERRIDE, id)

export const getReportSuccessOverride = (data: Report) =>
  action(GetReportTypes.GET_REPORT_SUCCESS_OVERRIDE, data)

export const getReportFailureOverride = (error: Error) =>
  action(GetReportTypes.GET_REPORT_FAILURE_OVERRIDE, { errorStack: error })

export const removeHeatmapLocation = () =>
  action(ClearReportTypes.CLEAR_REPORT_HEATMAP_LOCATION)
