import { IconArrowBack, IconArrowRight } from 'assets/images'
import { Button } from 'components'
import { useFormikContext } from 'formik'
import { t } from 'i18n'
import { Report } from 'store/ducks'
import {
  FlexRow,
  RowItem,
  ValidationContainer,
  ValidationDescription,
  ValidationTitle
} from '../styled'
import HeaderSection from './HeaderSection'
import SummarySection from './SummarySection'
import ValidateSection from './ValidateSection'

interface IReportFormStep3 {
  setFormStep: (step: number) => void
  handleBack?: () => void
}

const ReportFormStep3 = ({ setFormStep, handleBack }: IReportFormStep3) => {
  const { values } = useFormikContext<Report>()

  return (
    <>
      <HeaderSection
        title="summary"
        goBack={() => setFormStep(2)}
        reportDate={values?.reportDate}
      />
      <SummarySection
        reportId={values?.SK}
        personalInformation={values?.personalInformation}
      />
      <ValidationContainer>
        <ValidationTitle>{t`incompleteOverview`}</ValidationTitle>
        <ValidationDescription>{t`youCanSubmitIncomplete`}</ValidationDescription>
        <FlexRow style={{ width: '100%', flexWrap: 'wrap' }}>
          <ValidateSection
            onClick={() => setFormStep(1)}
            data={values}
            type="personalInformation"
          />
          <ValidateSection
            onClick={() => setFormStep(1)}
            data={values}
            type="state"
          />
          <ValidateSection
            onClick={() => setFormStep(1)}
            data={values}
            type="timestamps"
          />
          <ValidateSection
            onClick={() => setFormStep(1)}
            data={values}
            type="sequenceOfEvents"
          />
          <ValidateSection
            onClick={() => setFormStep(1)}
            data={values}
            type="injury"
          />
          <ValidateSection
            onClick={() => setFormStep(2)}
            data={values}
            type="investigationAndMonitoring"
          />
          <ValidateSection
            onClick={() => setFormStep(2)}
            data={values}
            type="treatedBy"
          />
          <ValidateSection
            onClick={() => setFormStep(2)}
            data={values}
            type="transport"
          />
          <ValidateSection
            onClick={() => setFormStep(2)}
            data={values}
            type="checklist"
          />
        </FlexRow>
        <FlexRow style={{ justifyContent: 'flex-end', padding: '20px 0' }}>
          <RowItem>
            <div style={{ marginBottom: '10px' }}>
              <Button
                style={{ marginRight: '10px' }}
                type="button"
                label="goBack"
                variant="default"
                onClick={handleBack}
                icon={<IconArrowBack fill="#fff" />}
              />
            </div>
            <Button
              type="submit"
              label="continue"
              variant="primary"
              icon={<IconArrowRight />}
            />
          </RowItem>
        </FlexRow>
      </ValidationContainer>
    </>
  )
}

export default ReportFormStep3
