import { IconErrorCircle } from 'assets/images'
import { t } from 'i18n'
import { SectionKey } from 'store/ducks'
import ImageItem from './ImageItem'
import MemoItem from './MemoItem'
import RecordingItem from './RecordingItem'
import {
  FormSectionButtons,
  FormSectionHeader,
  FormSectionTitle
} from './styled'

interface IFormTitleProps {
  title: string
  hasDisclaimer?: boolean
  sectionKey?: SectionKey
}

const FormTitle = ({ title, hasDisclaimer, sectionKey }: IFormTitleProps) => {
  return (
    <FormSectionHeader>
      <FormSectionTitle>
        {hasDisclaimer && <IconErrorCircle />}
        {t(title)}
      </FormSectionTitle>
      {sectionKey && (
        <FormSectionButtons>
          <RecordingItem sectionKey={sectionKey} />
          <MemoItem sectionKey={sectionKey} />
          <ImageItem sectionKey={sectionKey} />
        </FormSectionButtons>
      )}
    </FormSectionHeader>
  )
}

export default FormTitle
