import { ReactNode } from 'react'
import { BackButton } from '../BackButton'
import {
  PageHeaderContainer,
  PageHeaderContent,
  PageHeaderTextWrap
} from './styled'

interface IPageHeaderProps {
  title: string
  children?: ReactNode
  nowrap?: boolean
  backAction?: () => void
}

const PageHeader = ({
  title,
  children,
  nowrap = false,
  backAction
}: IPageHeaderProps) => {
  return (
    <PageHeaderContainer>
      <PageHeaderContent>
        <PageHeaderTextWrap nowrap={nowrap}>{title}</PageHeaderTextWrap>
      </PageHeaderContent>
      <PageHeaderContent vertical>
        {backAction && <BackButton goBack={backAction} />}
        {children}
      </PageHeaderContent>
    </PageHeaderContainer>
  )
}

export { PageHeader }
