import {
  CommentItemContainer,
  CommentItemDescription,
  CommentItemTitle
} from './styled'

interface Props {
  title?: string
  description?: string
}

const CommentItem = ({ title, description }: Props) => {
  return (
    <CommentItemContainer>
      <CommentItemTitle>{title}</CommentItemTitle>
      <CommentItemDescription>{description}</CommentItemDescription>
    </CommentItemContainer>
  )
}

export { CommentItem }
