import { t } from 'i18n'
import { If } from 'react-extras'
import {
  ModalCardContent,
  ModalCardDescription,
  ModalCardFooter,
  ModalCardHeader,
  ModalConfirmButton,
  ModalContainer,
  ModalDismissButton,
  ModalOverlay
} from './styled'

interface IModalProps {
  type: 'ERROR' | 'CONFIRM' | 'SUCCESS' | 'WARNING'
  title: string
  description: string
  onConfirm?: () => void
  onDismiss?: () => void
}

const Modal = ({
  type,
  title,
  description,
  onDismiss,
  onConfirm
}: IModalProps) => {
  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalCardHeader>{title}</ModalCardHeader>
        <ModalCardContent>
          <ModalCardDescription>{description}</ModalCardDescription>
        </ModalCardContent>
        <ModalCardFooter>
          <If condition={type === 'CONFIRM'}>
            <ModalDismissButton onClick={onDismiss}>
              <span>{t('dismiss')}</span>
            </ModalDismissButton>
            <ModalConfirmButton onClick={onConfirm}>
              <span>{t('confirm')}</span>
            </ModalConfirmButton>
          </If>
        </ModalCardFooter>
      </ModalContainer>
    </ModalOverlay>
  )
}

export { Modal }
