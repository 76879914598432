// Action Types

import { Resort, Seasons } from '../report/types'

export enum AuthTypes {
  LOGIN_REQUEST = '@login/LOGIN_REQUEST',
  LOGIN_SUCCESS = '@login/LOGIN_SUCCESS',
  LOGIN_FAILURE = '@login/LOGIN_FAILURE',
  USER_INFO = '@login/USER_INFO',
  USER_INFO_SUCCESS = '@login/USER_INFO_SUCCESS',
  USER_INFO_FAILURE = '@login/USER_INFO_FAILURE',
  LOGOFF_REQUEST = '@login/LOGOFF_REQUEST',
  LOGOFF_SUCCESS = '@login/LOGOFF_SUCCESS',
  LOGOFF_FAILURE = '@login/LOGOFF_FAILURE',
  UPDATE_USER_INFO = '@login/UPDATE_USER_INFO',
  UPDATE_USER_INFO_SUCCESS = '@login/UPDATE_USER_INFO_SUCCESS',
  UPDATE_USER_INFO_ERROR = '@login/UPDATE_USER_INFO_ERROR',
  UPDATE_RESORT_REQUEST = '@login/UPDATE_RESORT_REQUEST',
  UPDATE_RESORT_SUCCESS = '@login/UPDATE_RESORT_SUCCESS',
  UPDATE_RESORT_FAILURE = '@login/UPDATE_RESORT_FAILURE',
  CHANGE_RESORT_REQUEST = '@login/CHANGE_RESORT_REQUEST',
  CHANGE_RESORT_SUCCESS = '@login/CHANGE_RESORT_SUCCESS',
  CHANGE_RESORT_FAILURE = '@login/CHANGE_RESORT_FAILURE'
}

export enum Difficulties {
  GREEN = 'Green',
  BLUE = 'Blue',
  RED = 'Red',
  BLACK = 'Black',
  NS = 'Not Specified'
}

// Data Types

export interface Slope {
  id: string
  name: string
  difficulty: Difficulties
  types: Seasons[]
}

export interface Coordinate {
  x: number
  y: number
}

interface UserAccess {
  role?: string
  startsOn?: Date
  endsOn?: Date
}

export interface UserInfo {
  id?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  email?: string
  skiResort?: string
  resortId?: string
  userStatus?: string
  isLogged?: boolean
  resort?: Resort
  access?: UserAccess[]
}

// State Type

export interface AuthState {
  readonly data: UserInfo
  readonly loading: boolean
  readonly error: boolean
  readonly errorStack: any
}
