import { AxiosResponse } from 'axios'
import qs from 'qs'
import {
  IReportListData,
  RelatedReport,
  Report,
  ReportStatus,
  Seasons,
  Therapist
} from 'store/ducks'
import api from './baseApi'

export const getReport = async (id: number): Promise<Report> => {
  const response: AxiosResponse<Report> = await api.get(`report/${id}`)
  return response.data
}

export const getReportPdf = async (id: number): Promise<{ url: string }> => {
  const response: AxiosResponse<{ url: string }> = await api.get(
    `report/${id}/pdf`
  )
  return response.data
}

export const postReport = async (formData: Report): Promise<Report> => {
  const response: AxiosResponse<Report> = await api.post('report', formData)
  return response.data
}

export const createNewVersion = async (formData: Report): Promise<Report> => {
  const response: AxiosResponse<Report> = await api.post(
    'report/new-version',
    formData
  )
  return response.data
}

//TODO:: Adjust this with matching api call
export const getReportsByKey = async (key: string): Promise<Report[]> => {
  const response: AxiosResponse<Report[]> = await api.get(
    `report?searchKey=${key}`
  )
  return response.data
}

export const getReportsRelated = async ({
  patientName,
  reportId
}: {
  patientName?: string
  reportId?: number
}) => {
  const response: AxiosResponse<RelatedReport[]> = await api.get(
    `report/related?page=1${patientName ? `&patientName=${patientName}` : ''}${
      reportId ? `&reportId=${reportId}` : ''
    }`
  )

  return response.data
}

export const getMyRecentReports = async (): Promise<IReportListData[]> => {
  const response: AxiosResponse<IReportListData[]> = await api.get(
    `report/my/recent/list`
  )
  return response.data
}

export const GetReportById = async (id: number, version?: number) => {
  let query = ''
  if (version) {
    query = `?version=${version}`
  }

  const response: AxiosResponse<Report> = await api.get(
    `/report/${id.toString()}${query}`
  )
  return response.data
}

export const GetTherapistsList = async () => {
  const response: AxiosResponse<Therapist[]> = await api.get(
    '/report/therapists/list'
  )
  return response.data
}

export const GetReports = async ({
  patientName,
  pageSize = 100,
  reportId,
  reportStatus,
  filterDate
}: {
  patientName?: string
  reportId?: number
  pageSize?: number
  reportStatus?: string
  filterDate?: Date[]
}) => {
  try {
    let dateQS = ''
    if (filterDate) {
      dateQS = qs.stringify(filterDate).replace('&1=', '%261=')
    }
    const response: AxiosResponse<IReportListData[]> = await api.get(
      `/report?pageSize=${pageSize}${
        patientName ? `&patientName=${patientName}` : ''
      }${reportId ? `&reportId=${reportId}` : ''}${
        reportStatus ? `&reportStatus=${reportStatus}` : ''
      }${dateQS.length > 0 ? `&filterDate=${dateQS.toString()}` : ''}`
    )
    return response.data
  } catch (error: any) {
    console.error(error.config.url)
    return error
  }
}

interface ReportFilterParams {
  reportId?: string
  patientNAme?: string
  reportStatus?: ReportStatus
  filterDate?: string
  filterSeason?: Seasons
}

export const getAllReports = async (params?: ReportFilterParams) => {
  try {
    const res: AxiosResponse<IReportListData[]> = await api.get('report', {
      params
    })
    return res.data
  } catch (error) {
    console.error(error)
  }
}

export const deleteReport = async (reportId: number) => {
  try {
    await api.delete(`/report/${reportId}`)
  } catch (error) {
    console.error(error)
  }
}
