import styled from 'styled-components'
import { theme } from 'styles/theme'
import IconCameraSrc from '../../assets/images/camera.svg'

const ImageGalleryItemContainer = styled.img`
  cursor: pointer;
  height: 120px;
  width: 120px;
  margin: 10px 5px 10px 0;
`

const EmptyImageContainer = styled.div`
  position: relative;
  height: 120px;
  width: 120px;
  background-color: ${theme.colors.lighterGray};
  opacity: 0.4;
  margin: 10px 5px 10px 0;

  &::after {
    width: 24px;
    height: 24px;
    background: url(${IconCameraSrc});
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

const FullSizeContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  img {
    max-width: 600px;
    max-height: 600px;
  }

  svg {
    position: absolute;
    top: 10px;
    right: 5px;
    fill: ${theme.colors.gray} !important;
  }
`

export { EmptyImageContainer, FullSizeContainer, ImageGalleryItemContainer }
