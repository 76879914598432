import moment from 'moment'

const formatDateTime = (date?: string | Date) =>
  moment(date).format('DD.MM.YY HH:mm')

const formatDate = (date?: string | Date) => moment(date).format('DD.MM.YY')

const formatTime = (date?: string | Date) => moment(date).format('HH:mm')

export { formatDate, formatDateTime, formatTime }
