import styled from 'styled-components'

export const LoaderContainer = styled.div<{ height?: string }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: ${props => props.height ?? '100vh'};
`
