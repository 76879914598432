import { Auth } from '@aws-amplify/auth'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18n-js'
import useAuthSession from './useAuthSession'
import useUser from './useUser'

interface LoginParams {
  username: string
  password: string
}

async function login({ username, password }: LoginParams): Promise<void> {
  const user = await Auth.signIn(username, password)
  if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
    const translatedError = t('temporaryPasswordDetected')
    throw new Error(translatedError)
  }
}

const useSignIn = () => {
  const { loadAuthSession } = useAuthSession()
  const { refetch: loadUser } = useUser()

  const loginMutation = useMutation({
    mutationKey: ['login'],
    mutationFn: login,
    onSuccess: () => {
      loadAuthSession()
      loadUser()
      window.location.href = '/'
    }
  })

  return loginMutation
}

export default useSignIn
