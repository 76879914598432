import { IconArrowLeft } from 'assets/images'
import { t } from 'i18n'
import { BackButtonContainer } from './styled'

interface Props {
  goBack: () => void
}

const BackButton = ({ goBack }: Props) => {
  return (
    <BackButtonContainer onClick={goBack}>
      {t`goBack`}
      <IconArrowLeft width={24} />
    </BackButtonContainer>
  )
}

export { BackButton }
