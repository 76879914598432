import { IconArrowRight, IconRefresh } from 'assets/images'
import { Button, DateInput, RadioButton } from 'components'
import { t } from 'i18n'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { resetPassword, updateUserById } from 'services'
import { Role, User, UserAccess } from 'store/ducks'
import {
  ResetPasswordContainer,
  Separator,
  UserEditAccessDate,
  UserEditFormContainer,
  UserEditLabel,
  UserProfileOptions
} from './styled'

interface Props {
  user?: User
}

const UserEditForm = ({ user }: Props) => {
  const [access, setAccess] = useState<UserAccess>(
    undefined as unknown as UserAccess
  )
  const navigate = useNavigate()

  const setRole = (value: Role) => {
    const _access = {
      ...access,
      role: value
    } as UserAccess

    setAccess(_access)
  }

  const setStartDate = (value: Date) => {
    const _access = {
      ...access,
      startsOn: value
    } as UserAccess
    setAccess(_access)
  }

  const setEndDate = (value: Date) => {
    const _access = {
      ...access,
      endsOn: value
    } as UserAccess
    setAccess(_access)
  }

  const saveInformation = async () => {
    if (user) {
      if (user.userStatus === 'PENDING') {
        user.userStatus = 'ACTIVE'
      }

      if (!user?.access) {
        user.access = [access]
      } else {
        user.access[0] = access
      }
    }

    await updateUserById(user?.id, user)
    navigate(`/users`)
  }

  const handleResetUserPassword = async () => {
    const res = await resetPassword(user?.id)
    if (res?.message === 'Success') {
      toast.success(t('passwordSuccessfullyReset', { value: user?.id }))
    }
  }

  useEffect(() => {
    const defaultAccess =
      user && user?.access && user?.access.find(x => x.default === true)

    if (defaultAccess) {
      setAccess(defaultAccess)
    } else {
      if (user && user.access) {
        setAccess(user?.access[0])
      }
    }
  }, [user])

  return (
    <UserEditFormContainer>
      <UserEditLabel>{t('userSetting')}</UserEditLabel>
      <UserProfileOptions>
        <RadioButton
          label="User"
          checked={access && access.role === Role.USER}
          handleCheckedChange={() => setRole(Role.USER)}
        />
        <RadioButton
          label={t(Role.RESORT_ADMINISTRATOR)}
          checked={access && access.role === Role.RESORT_ADMINISTRATOR}
          handleCheckedChange={() => setRole(Role.RESORT_ADMINISTRATOR)}
        />
      </UserProfileOptions>
      <UserEditLabel>{t('timePeriod')}</UserEditLabel>
      <UserEditAccessDate>
        <DateInput
          label="start"
          name="access.startsOn"
          minDate={new Date(access?.startsOn ?? moment().toDate())}
          onChange={(name, value) => setStartDate(value)}
          value={access?.startsOn ? new Date(access?.startsOn) : new Date()}
        />
        <Separator />
        <DateInput
          label="end"
          name="access?.endsOn"
          minDate={moment().toDate()}
          onChange={(name, value) => setEndDate(value)}
          value={access?.endsOn ? new Date(access?.endsOn) : new Date()}
        />
      </UserEditAccessDate>
      <ResetPasswordContainer>
        <Button
          type="button"
          variant="default"
          label={'resetPassword'}
          icon={<IconRefresh />}
          onClick={handleResetUserPassword}
        />
      </ResetPasswordContainer>
      <Button
        type="button"
        label={user?.userStatus === 'PENDING' ? 'Approve' : 'saveInformation'}
        icon={<IconArrowRight />}
        onClick={saveInformation}
      />
    </UserEditFormContainer>
  )
}

export { UserEditForm }
