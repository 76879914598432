import styled from 'styled-components'

const WeatherCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const WeatherCardRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
  justify-content: space-between;
`

const WeatherCardCol = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`

export { WeatherCardCol, WeatherCardContainer, WeatherCardRow }
