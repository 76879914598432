import { IconArrowLeft } from 'assets/images'
import { t } from 'i18n'
import { formatDate, formatTime } from 'services'
import {
  FlexColumn,
  FlexRowEnd,
  FlexRowSpaceBetween,
  ReportHeaderDateItem
} from '../../../../Report/Landing/LandingReportHeader/styled'
import { BackButtonContainer, H1 } from '../styled'

interface IHeaderSectionProps {
  title: string
  goBack: () => void
  reportDate?: string | Date
}

const HeaderSection = ({ title, goBack, reportDate }: IHeaderSectionProps) => {
  return (
    <FlexColumn style={{ margin: '40px 60px' }}>
      <FlexRowSpaceBetween>
        <H1>{t(title)}</H1>
        <BackButtonContainer onClick={goBack}>
          {t`goBack`}
          <IconArrowLeft />
        </BackButtonContainer>
      </FlexRowSpaceBetween>
      <FlexRowEnd style={{ margin: 20 }}>
        <ReportHeaderDateItem style={{ marginRight: 40 }}>
          <small>{t`time`}:</small>
          <strong>{formatTime(reportDate)}</strong>
        </ReportHeaderDateItem>
        <ReportHeaderDateItem>
          <small>{t`date`}:</small>
          <strong>{formatDate(reportDate)}</strong>
        </ReportHeaderDateItem>
      </FlexRowEnd>
    </FlexColumn>
  )
}

export default HeaderSection
