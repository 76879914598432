const INJURY_REPORT_NUMBER = '014 - 00001'

const DISABLED_ROUTES = [
  '/report-overview',
  '/reports/edit',
  '/new-user',
  '/user/'
]

export { DISABLED_ROUTES, INJURY_REPORT_NUMBER }
