import { Reducer } from 'redux'
import {
  ClearReportTypes,
  CreateReportTypes,
  GetReportTypes,
  Report,
  ReportState
} from './types'

const INITIAL_STATE: ReportState = {
  data: {} as Report,
  loading: false,
  error: false,
  errorStack: undefined
}

const reducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CreateReportTypes.CREATE_REPORT_REQUEST:
    case GetReportTypes.GET_REPORT_REQUEST:
      return { ...state, loading: true, error: false, errorStack: undefined }
    case CreateReportTypes.CREATE_REPORT_SUCCESS:
    case GetReportTypes.GET_REPORT_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        loading: false,
        error: false,
        errorStack: undefined
      }
    case CreateReportTypes.CREATE_REPORT_FAILURE:
    case GetReportTypes.GET_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorStack: action.payload.errorStack
      }
    case ClearReportTypes.CLEAR_REPORT_SUCCESS:
      return {
        data: {},
        loading: false,
        error: false,
        errorStack: null
      }
    case GetReportTypes.GET_REPORT_REQUEST_OVERRIDE:
      return { ...state, loading: true, error: false, errorStack: undefined }
    case GetReportTypes.GET_REPORT_SUCCESS_OVERRIDE:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        errorStack: undefined
      }
    case GetReportTypes.GET_REPORT_FAILURE_OVERRIDE:
      return {
        ...state,
        loading: false,
        error: true,
        errorStack: action.payload.errorStack
      }
    case ClearReportTypes.CLEAR_REPORT_HEATMAP_LOCATION:
      return {
        ...state,
        data: {
          ...state.data,
          heatmap: undefined
        }
      }
    default:
      return state
  }
}

export default reducer
