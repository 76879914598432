import { IconClose, IconMicrophone } from 'assets/images'
import { AudioPlayer } from 'components'
import { useFormikContext } from 'formik'
import { t } from 'i18n'
import { useState } from 'react'
import { Report, SectionKey } from 'store/ducks'
import { ModalOverlay } from '../../../../../styles/global'
import { ModalCloseIcon, RecordModal } from './styled'

interface IRecordingItemProps {
  sectionKey: SectionKey
}

const RecordingItem = ({ sectionKey }: IRecordingItemProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const { values } = useFormikContext<Report>()

  const toggleOpen = () => {
    setOpen(!open)
  }

  const getRecordings = () => {
    switch (sectionKey) {
      case SectionKey.SEQUENCE_OF_EVENTS:
        return values?.sequenceOfEvents?.recordings
      case SectionKey.INJURY:
        return values?.injury?.recordings
      default:
        return values?.audio?.filter(audio => audio.section === sectionKey)
    }
  }

  const sectionRecords = getRecordings()

  return (
    <div>
      <IconMicrophone onClick={toggleOpen} />
      {open && (
        <ModalOverlay>
          <RecordModal>
            <h2>{t(sectionKey)}</h2>
            <ModalCloseIcon style={{ top: 5 }} onClick={toggleOpen}>
              <IconClose />
            </ModalCloseIcon>
            {sectionRecords && sectionRecords.length > 0 ? (
              sectionRecords.map((record, index) => (
                <AudioPlayer key={index} audio={record} />
              ))
            ) : (
              <p>{t`noRecordings`}</p>
            )}
          </RecordModal>
        </ModalOverlay>
      )}
    </div>
  )
}

export default RecordingItem
