import { IconMinus, IconPlusCircle, IconSave } from 'assets/images'
import { Button, SelectInput, TextInput } from 'components'
import { RELATIVE_OPTIONS } from 'constants/options'
import { t } from 'i18n'
import { ChangeEvent, useState } from 'react'
import { Relative } from 'store/ducks'
import { v4 as uuid } from 'uuid'
import { PhoneInput } from './PhoneInput'
import { InputCollapsableContainer, InputGroup } from './styled'

interface IRelativeInputProps {
  label: string
  patientName: string
  add: (relative: Relative) => void
  update: (index: number, relative: Relative) => void
  remove: (index: number) => void
  relatives: Relative[]
}

const initialRelative: Relative = {
  role: '',
  fullName: '',
  email: '',
  phoneNumber: ''
}

const RelativeInput = ({
  label,
  patientName,
  relatives,
  add,
  remove,
  update
}: IRelativeInputProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [newRelative, setNewRelative] = useState<Relative>(initialRelative)
  const [editRelative, setEditRelative] = useState<Relative>(
    undefined as unknown as Relative
  )
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false)

  const handleChangeNewRelative = (
    key: string,
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const {
      target: { value }
    } = event
    setNewRelative({
      id: newRelative.id ?? uuid(),
      ...newRelative,
      [key]: value
    })
  }
  const handleChangeEditRelative = (
    key: string,
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const {
      target: { value }
    } = event

    setEditRelative({
      ...editRelative,
      [key]: value
    })
  }

  const toggleOpen = () => {
    setOpen(!open)
  }

  const handleSave = () => {
    setOpen(false)
    add(newRelative)
    setNewRelative(initialRelative)
  }
  const handleEditSave = (index: number) => {
    setIsEditOpen(false)
    update(index, editRelative)
    setEditRelative(initialRelative)
  }

  const handleEditRelative = (relative: Relative) => {
    setIsEditOpen(!isEditOpen)
    if (!isEditOpen) {
      setEditRelative(relative)
    } else {
      setEditRelative(undefined as unknown as Relative)
    }
  }

  return (
    <InputGroup>
      <InputCollapsableContainer active={open} onClick={toggleOpen}>
        {open ? (
          <>
            <p>
              {t`patient`}: {patientName}
            </p>
            <IconMinus />
          </>
        ) : (
          <>
            <p>{t(label)}</p>
            <IconPlusCircle />
          </>
        )}
      </InputCollapsableContainer>
      {open && (
        <>
          <SelectInput
            label="relativeRole"
            options={RELATIVE_OPTIONS}
            // @ts-expect-error: TO DO: fix this
            placeholder="Select"
            name="role"
            onChange={event => handleChangeNewRelative('role', event)}
          />

          <TextInput
            type="text"
            label="fullName"
            name="fullName"
            onChange={event => handleChangeNewRelative('fullName', event)}
          />

          <PhoneInput
            label="phoneNumber"
            name="phoneNumber"
            onChange={event => handleChangeNewRelative('phoneNumber', event)}
          />

          <TextInput
            type="email"
            label="email"
            name="email"
            onChange={event => handleChangeNewRelative('email', event)}
          />

          <Button
            type="button"
            label="addRelatives"
            textSize="small"
            onClick={handleSave}
            icon={<IconSave />}
          />
        </>
      )}
      {relatives.map((relative, index) => {
        return (
          <div key={index}>
            <InputCollapsableContainer
              onClick={() => handleEditRelative(relative)}
              key={index}
              active={false}
            >
              <p>{relative.fullName}</p>
              <IconMinus onClick={() => remove(index)} />
            </InputCollapsableContainer>

            {isEditOpen && relative.id === editRelative?.id && (
              <>
                <SelectInput
                  label="relativeRole"
                  options={RELATIVE_OPTIONS}
                  // @ts-expect-error: TO DO: fix this
                  placeholder="Select"
                  name="role"
                  value={editRelative?.role || undefined}
                  onChange={event => handleChangeEditRelative('role', event)}
                />
                <TextInput
                  type="text"
                  label="fullName"
                  name="fullName"
                  value={editRelative?.fullName}
                  onChange={event =>
                    handleChangeEditRelative('fullName', event)
                  }
                />

                <PhoneInput
                  label="phoneNumber"
                  name="phoneNumber"
                  value={editRelative?.phoneNumber}
                  onChange={event =>
                    handleChangeEditRelative('phoneNumber', event)
                  }
                />
                <TextInput
                  type="email"
                  label="email"
                  name="email"
                  value={editRelative?.email}
                  onChange={event => handleChangeEditRelative('email', event)}
                />
                <Button
                  type="button"
                  label="saveInformation"
                  textSize="small"
                  onClick={() => handleEditSave(index)}
                  icon={<IconSave />}
                />
              </>
            )}
          </div>
        )
      })}
    </InputGroup>
  )
}

export { RelativeInput }
