import styled from 'styled-components'
import { theme } from 'styles/theme'

const AudioPlayerContainer = styled.div`
  padding: 10px;
  margin: 15px 0;
  box-shadow: rgb(0 0 0 / 10%) 0px 0 5px;

  h3 {
    display: inline-block;
    color: ${theme.colors.gray};
    margin: 0 0 7px 20px;
    font-size: ${theme.textSizes.small};
    font-weight: 600;
  }

  small {
    float: right;
    margin: 5px 0;
    color: ${theme.colors.lightGray};
  }
`

const AudioPlayerItem = styled.div`
  display: flex;
  align-items: center;
`

const PlayPauseButton = styled.div<{ isPlaying: boolean }>`
  box-sizing: border-box;
  height: 15px;
  width: 15px;
  margin-right: 5px;
  border-color: transparent transparent transparent ${theme.colors.gray};
  transition: 100ms all ease;
  will-change: border-width;
  cursor: pointer;

  border-style: ${({ isPlaying }) => (isPlaying ? 'double' : 'solid')};
  border-width: ${({ isPlaying }) =>
    isPlaying ? '0px 0 0px 10px' : '7px 0 7px 10px'};

  &:hover {
    border-color: transparent transparent transparent ${theme.colors.lightGray};
  }
`

const AudioTracker = styled.input`
  height: 2px;
  -webkit-appearance: none;
  width: 100%;
  margin-bottom: 5px;
  background: ${theme.colors.gray};
  cursor: pointer;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 15px;
    width: 2px;
    background: ${theme.colors.red};
  }
`

export { AudioPlayerContainer, AudioPlayerItem, AudioTracker, PlayPauseButton }
