import { Chart as ChartJs, ChartTypeRegistry, registerables } from 'chart.js'
import { useEffect, useRef } from 'react'
import { IDataSet } from '../../interfaces/data-set.interface'
import {
  ChartContainer,
  ChartSubTitle,
  ChartTitle,
  ChartTitleContainer
} from './styled'

ChartJs.register(...registerables)

interface IChartProps {
  type?: keyof ChartTypeRegistry
  title?: string
  dataSet?: IDataSet
  fillColor?: string
}

const AreaChart = ({
  type = 'line',
  dataSet,
  fillColor,
  title
}: IChartProps) => {
  const chartEl = useRef<HTMLCanvasElement>(null)

  const totals =
    dataSet &&
    dataSet.data &&
    dataSet.data.map(x => x).reduce((acc, val) => acc + val)

  useEffect(() => {
    const ctx: CanvasRenderingContext2D = chartEl.current?.getContext(
      '2d'
    ) as CanvasRenderingContext2D

    const chart = new ChartJs(ctx, {
      type: type,
      data: {
        labels: dataSet?.labels,
        datasets: [
          {
            data: dataSet?.data,
            borderJoinStyle: 'bevel',
            tension: 0.1,
            fill: true,
            backgroundColor: [fillColor],
            borderColor: [fillColor],
            borderWidth: 2
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        layout: {
          padding: {
            top: 65
          }
        },
        plugins: {
          title: {
            display: false
          },
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              display: false
            },
            grid: {
              display: false
            }
          },
          x: {
            beginAtZero: true,
            grid: {
              display: false
            }
          }
        }
      }
    })

    return () => {
      chart.destroy()
    }
  }, [dataSet?.data, dataSet?.labels, fillColor, type])

  return (
    <ChartContainer>
      <ChartTitleContainer>
        <ChartTitle>{title}</ChartTitle>
        <ChartSubTitle>{totals}</ChartSubTitle>
      </ChartTitleContainer>
      <canvas id={Math.random().toString()} height={180} ref={chartEl} />
    </ChartContainer>
  )
}

export { AreaChart }
