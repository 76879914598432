import { BackButton, Button, Container, TextInput } from 'components'
import { PageTitle } from 'components/PageHeader/styled'
import { t } from 'i18n'
import { isEmpty, xor } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  deleteSpecificSlope,
  getSpecificSlope,
  updateSpecificSlope
} from 'services'
import { Difficulties, Seasons, Slope } from 'store/ducks'
import { PageHeaderWrapper } from '../Create/styled'
import {
  BigLabel,
  DeleteBtn,
  FilledInput,
  FormContainer,
  RadioInputLabel,
  SlopeTypeContainer
} from './styled'

interface FormDataProps {
  name: string
  difficulty: Difficulties
  types: Seasons[]
}

const SlopeUpdatePage = () => {
  const defaultData = {
    name: '',
    difficulty: '' as Difficulties,
    types: []
  }
  const [slope, setSlope] = useState<Slope>()
  const [formData, setFormData] = useState<FormDataProps>(defaultData)

  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()

  const getSlope = useCallback(async () => {
    const slope = await getSpecificSlope(id as string)
    setSlope(slope)
    setFormData(slope)
  }, [id])

  useEffect(() => {
    getSlope()
  }, [getSlope])

  const isDataValid =
    formData.name.length && formData.types.length && formData.difficulty?.length

  const isNotUpdated =
    slope?.name === formData.name &&
    isEmpty(xor(slope.types, formData.types)) &&
    slope.difficulty === formData.difficulty

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, type: inputType, name } = e.currentTarget

    if (inputType === 'radio') {
      return setFormData({
        ...formData,
        difficulty: name as Difficulties
      })
    }

    if (inputType === 'checkbox') {
      const index = formData.types.indexOf(value as Seasons)

      if (index < 0) {
        return setFormData({
          ...formData,
          types: [...formData.types, value as Seasons]
        })
      }

      return setFormData({
        ...formData,
        types: [
          ...formData.types.slice(0, index),
          ...formData.types.slice(index + 1)
        ]
      })
    }

    return setFormData({ ...formData, [name]: value })
  }

  const handleOnSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (isNotUpdated || !isDataValid) return null

    try {
      await updateSpecificSlope(id as string, { ...formData })
      getSlope()
      toast.success(t('updateSuccess'))
    } catch (error) {
      console.error(error)
      toast.error(t('updateFailed'))
    }
  }

  const handleOnDelete = async () => {
    await deleteSpecificSlope(id as string)
    toast.success(t('slopeDeleted'))
    navigate('/slopes')
  }

  if (!slope) return null

  const difficultiesArr = [
    {
      name: Difficulties.GREEN,
      label: t('green')
    },
    {
      name: Difficulties.BLUE,
      label: t('blue')
    },
    {
      name: Difficulties.RED,
      label: t('red')
    },
    {
      name: Difficulties.BLACK,
      label: t('black')
    },
    {
      name: Difficulties.NS,
      label: t('notSpecified')
    }
  ]

  return (
    <Container>
      <PageHeaderWrapper>
        <PageTitle>{slope.name}</PageTitle>
        <BackButton goBack={() => navigate('/slopes')} />
      </PageHeaderWrapper>
      <FormContainer onSubmit={handleOnSubmit}>
        <TextInput
          type="text"
          name="name"
          label="name"
          value={formData.name}
          onChange={handleOnChange}
          placeholder={`${t('name')} (e.g. Bruløypa)`}
        />
        <SlopeTypeContainer>
          <BigLabel>
            {t('season')}
            {t('s')}
          </BigLabel>
          <FilledInput
            className={formData.types.includes(Seasons.SUMMER) ? `active` : ''}
          >
            <input
              type="checkbox"
              name={Seasons.SUMMER}
              id={Seasons.SUMMER}
              value={Seasons.SUMMER}
              onChange={handleOnChange}
            />
            {t('summer')}
          </FilledInput>
          <FilledInput
            className={formData.types.includes(Seasons.WINTER) ? `active` : ''}
          >
            <input
              type="checkbox"
              name={Seasons.WINTER}
              id={Seasons.WINTER}
              value={Seasons.WINTER}
              onChange={handleOnChange}
            />
            {t('winter')}
          </FilledInput>
        </SlopeTypeContainer>
        <SlopeTypeContainer>
          <BigLabel>{t('difficulty')}</BigLabel>
          {difficultiesArr.map(diff => (
            <RadioInputLabel
              key={diff.name}
              $bgColor={
                diff.name === Difficulties.NS ? null : diff.name.toLowerCase()
              }
              className={formData.difficulty === diff.name ? `active` : ''}
            >
              <input
                type="radio"
                name={diff.name}
                id={diff.name}
                onClick={handleOnChange}
              />
              {diff.label}
            </RadioInputLabel>
          ))}
        </SlopeTypeContainer>
        <Button
          type="submit"
          label="update"
          disabled={isNotUpdated || !isDataValid}
        />
      </FormContainer>
      <DeleteBtn type="button" label="delete" onClick={handleOnDelete} />
    </Container>
  )
}

export default SlopeUpdatePage
