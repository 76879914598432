import { useQuery } from '@tanstack/react-query'
import { t } from 'i18n-js'
import { useDispatch } from 'react-redux'
import api from 'services/baseApi'
import { User } from 'store/ducks'
import { userInfoSuccess } from 'store/ducks/auth/actions'

const useUser = () => {
  const dispatch = useDispatch()
  return useQuery<User>({
    queryKey: ['user'],
    queryFn: async () => {
      const { data } = await api.get('user')
      dispatch(userInfoSuccess(data))
      return data
    },
    throwOnError(error) {
      throw Error(t(error.name))
    },
    enabled: false,
    retry: 3
  })
}
export default useUser
