import { AxiosResponse } from 'axios'
import api from './baseApi'

interface SignedUrl {
  url: string
}

export const getSignedUrl = async (keyName: string): Promise<SignedUrl> => {
  const response: AxiosResponse<SignedUrl> = await api.get(
    `/files/signed-url?keyName=${keyName}`
  )
  return response.data
}

export const getBlob = async (fileUri: any) => {
  const response = await fetch(fileUri)
  const imageBody = await response.blob()
  return imageBody
}

export const putFileS3 = async (
  uri: any,
  signedUrl: string
): Promise<number> => {
  const response = await fetch(signedUrl, {
    method: 'PUT',
    body: uri
  })

  return response.status
}

export const getFileUrl = async (uri: string) => {
  const { data } = await api.get(`/files/get-file-url?uri=${uri}`)
  return data.url
}
