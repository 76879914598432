import { Button } from 'components'
import styled from 'styled-components'
import { theme } from 'styles/theme'

export const InputLabel = styled.label`
  input[type='text'] {
    width: 300px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 25px;
    margin-top: 5px;
    padding: 1rem;
  }
`

export const DeleteBtn = styled(Button)`
  background-color: transparent;
  border: 1px solid ${theme.colors.warning};
  color: ${theme.colors.warning};
  margin-top: 2rem;
  width: auto;

  &:hover {
    background-color: ${theme.colors.warning};
    color: white;
  }
`

export const FilledInput = styled.label`
  cursor: pointer;
  border-radius: 4px;
  margin-right: 25px;
  margin-top: 5px;
  padding: 1rem;
  transition: 0.3s;
  border: 1px solid ${theme.colors.black};

  input[type='radio'],
  input[type='checkbox'] {
    display: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
  }

  &.active {
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
    border: 1px solid ${theme.colors.primary};
  }
`
export const FormContainer = styled.form`
  display: grid;
  gap: 1rem;
`

export const SlopeTypeContainer = styled.div`
  margin-bottom: 2rem;
`

export const BigLabel = styled.p`
  font-size: 20px;
  font-weight: 500;
  color: #2b2b2b;
  margin-bottom: 2rem;
  margin-top: 0;
`
interface RadioInputLabelProps {
  $bgColor: string | undefined | null
}

export const RadioInputLabel = styled.label<RadioInputLabelProps>`
  cursor: pointer;
  border-radius: 4px;
  margin-right: 25px;
  margin-top: 5px;
  padding: 1rem;
  transition: 0.3s;
  border: 1px solid ${theme.colors.black};

  input[type='radio'],
  input[type='checkbox'] {
    display: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
  }

  &.active {
    background-color: ${({ $bgColor }) => $bgColor || theme.colors.primary};
    color: ${theme.colors.white};
    border-color: ${({ $bgColor }) => $bgColor || theme.colors.primary};
  }
`
