import { zodResolver } from '@hookform/resolvers/zod'
import { IconArrowRight } from 'assets/images'
import useAuthSession from 'hooks/useAuthSession'
import { useNewPassword } from 'hooks/useForgotPassword'
import { t } from 'i18n-js'
import { useEffect, useState } from 'react'
import { If } from 'react-extras'
import { Controller, useForm } from 'react-hook-form'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { z } from 'zod'
import { phoneNumberSchema } from '../../../schema/common.schema'
import {
  ErrorMessage,
  Input,
  InputWrapper,
  Label,
  LinkWrapper,
  SubmitButton
} from '../styled'
import { newPasswordSchema } from './forgotPassword.schema'
import { CodeInput, ForgotPasswordFormContainer } from './styled'

type NewPasswordFormDataProps = z.infer<typeof newPasswordSchema>

const NewPasswordForm = () => {
  const FormHook = useForm<NewPasswordFormDataProps>({
    resolver: zodResolver(newPasswordSchema)
  })

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = FormHook

  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const [apiError, setApiError] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const username = searchParams.get('userName')

  const { mutate: changePassword } = useNewPassword()
  const { isAuthenticated } = useAuthSession()

  useEffect(() => {
    async function valdatePhoneNumber() {
      const phoneValidation = await phoneNumberSchema.safeParse(username)
      if (!phoneValidation.success) {
        navigate('/forgot-password')
      }
    }
    valdatePhoneNumber()
  }, [searchParams, navigate, username])

  // Watch for changes in the form
  watch(() => setApiError(''))
  const { code } = watch()

  const onSubmit = handleSubmit(async data => {
    setIsSubmitting(true)

    const phoneValidation = await phoneNumberSchema.safeParse(username)
    if (!phoneValidation.success) {
      return setApiError(
        'Phone number is either invalid or not registered. Please request a new code.'
      )
    }

    const phoneNumber = username as string

    changePassword(
      {
        username: phoneNumber,
        code: data.code,
        newPassword: data.newPassword
      },
      {
        onSuccess: () => {
          setSearchParams({
            ...searchParams,
            step: '3',
            userName: phoneNumber
          })
          setIsSubmitting(false)
        },
        onError: error => {
          setApiError(error.message)
          setIsSubmitting(false)
        }
      }
    )
  })

  return (
    <ForgotPasswordFormContainer>
      <form onSubmit={onSubmit}>
        <InputWrapper>
          <Label>
            <p>
              {t('code')}{' '}
              <If condition={!!errors.code}>
                <ErrorMessage>{errors.code?.message}</ErrorMessage>
              </If>
            </p>

            <Controller
              name="code"
              defaultValue={''}
              control={control}
              render={({ field }) => {
                return (
                  <CodeInput
                    fields={6}
                    type="number"
                    inputMode="numeric"
                    placeholder={t('code')}
                    {...field}
                  />
                )
              }}
            />
          </Label>
        </InputWrapper>
        <If condition={code?.length === 6}>
          <InputWrapper>
            <Label>
              {t('newPassword')}{' '}
              <If condition={!!errors.newPassword}>
                <ErrorMessage>{errors.newPassword?.message}</ErrorMessage>
              </If>
              <Input
                $isError={!!errors.newPassword}
                placeholder={t('newPassword')}
                {...register('newPassword')}
                type="password"
                defaultValue={''}
              />
            </Label>
          </InputWrapper>
          <If condition={!!apiError}>
            <ErrorMessage $textAlign="center" $width="100%" $noWrap>
              {apiError}
            </ErrorMessage>
          </If>
          <SubmitButton type="submit" disabled={!code || isSubmitting}>
            {t('next')}
            <IconArrowRight />
          </SubmitButton>
        </If>

        <LinkWrapper>
          <Link to="/forgot-password">{t('requestNewCode')}</Link>
          <If condition={!isAuthenticated}>
            <Link to="/sign-in">{t('loginHere')}</Link>
          </If>
        </LinkWrapper>
      </form>
    </ForgotPasswordFormContainer>
  )
}

export default NewPasswordForm
