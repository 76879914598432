import { IconCircleX, IconSearch } from 'assets/images'
import { t } from 'i18n'
import { ChangeEvent, useState } from 'react'
import { RelatedReport } from 'store/ducks'
import { useDebouncedCallback } from 'use-debounce'
import {
  InputContainer,
  InputGroup,
  InputIconContainer,
  ReportOptionsList,
  ReportOptionsListItem,
  SelectedReportsList,
  SelectedReportsListItem
} from './styled'

interface IReportSelectorProps {
  foundReports?: RelatedReport[]
  selectedReports?: RelatedReport[]
  searchReports: (searchKey: string) => void
  add: (witness: RelatedReport) => void
  remove: (index: number) => void
}

const ReportsSelector = ({
  foundReports,
  selectedReports,
  searchReports,
  add,
  remove
}: IReportSelectorProps) => {
  const [filter, setFilter] = useState<string>('')

  const handleUpdateFilters = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = event
    setFilter(value)
    searchReports(value)
  }

  const debouncedUpdateFIlters = useDebouncedCallback(event => {
    handleUpdateFilters(event)
  }, 300)

  const handleAddReport = (report: RelatedReport) => {
    const { reportId, PK, SK } = report
    const relatedReport: RelatedReport = {
      reportId,
      PK,
      SK,
      type: 'RELATED'
    }
    add(relatedReport)
  }

  return (
    <div>
      <InputGroup>
        <InputContainer
          placeholder={t`findReport`}
          onChange={debouncedUpdateFIlters}
        />
        <InputIconContainer>
          <IconSearch height={19} />
        </InputIconContainer>
      </InputGroup>
      {filter && foundReports && foundReports.length > 0 && (
        <ReportOptionsList>
          {foundReports.map(report => (
            <ReportOptionsListItem
              key={report.SK}
              onClick={() => handleAddReport(report)}
            >
              <b>
                {report.SK} - {report.name}
              </b>
            </ReportOptionsListItem>
          ))}
        </ReportOptionsList>
      )}
      <SelectedReportsList>
        {selectedReports?.map((report, index) => (
          <SelectedReportsListItem key={index}>
            <b>{report.reportId}</b>
            <p>{report.SK}</p>
            <IconCircleX onClick={() => remove(index)} />
          </SelectedReportsListItem>
        ))}
      </SelectedReportsList>
    </div>
  )
}

export { ReportsSelector }
