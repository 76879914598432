import { put } from 'redux-saga/effects'
import { getReportsByKey } from 'services'
import { Report } from '../report/types'
import {
  getReportsFailure,
  getReportsRequest,
  getReportsSuccess
} from './actions'

export function* getReportsByKeySaga({
  payload
}: ReturnType<typeof getReportsRequest>) {
  try {
    const response: Report[] = yield getReportsByKey(payload)
    yield put(getReportsSuccess(response))
  } catch (err: any) {
    yield put(getReportsFailure(err))
  }
}
