import { IconArrowRight } from 'assets/images'
import { Button, TextInput } from 'components'
import { Form, Formik } from 'formik'
import { useRole } from 'hooks'
import { connect } from 'react-redux'
import { ApplicationState } from 'store'
import { Role, UserInfo } from 'store/ducks'
import { PageWrapper } from './styled'

const ResortInformationPage = ({ resort }: { resort: UserInfo['resort'] }) => {
  const initialValues = {
    name: resort?.name,
    address: resort?.address
  }

  const handleSubmit = () => {
    console.log('handleSubmit')
  }

  const isAdmin = useRole([Role.RESORT_ADMINISTRATOR, Role.SYS_ADMIN])
  return (
    <PageWrapper>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        // validationSchema={ChangePasswordValidationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          // errors,
          // touched,
          handleChange,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            <TextInput
              type="text"
              name="name"
              label="name"
              value={values.name}
              onChange={handleChange}
              disabled={!isAdmin}
              // meta={{
              //   error: errors.code,
              //   touched: touched.code
              // }}
            />
            <TextInput
              type="text"
              name="address"
              label="address"
              value={values.address}
              onChange={handleChange}
              disabled={!isAdmin}
              // meta={{
              //   error: errors.newPassword,
              //   touched: touched.newPassword
              // }}
            />
            <Button
              style={{ marginTop: '1rem' }}
              type="submit"
              label="update"
              disabled={true}
              icon={<IconArrowRight />}
            />
          </Form>
        )}
      </Formik>
    </PageWrapper>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  resort: state.auth.data?.resort
})

export default connect(mapStateToProps, null)(ResortInformationPage)
