interface CreatePaginationProps {
  items: []
  maxItem?: number
}

const createPagination = ({
  items,
  maxItem
}: CreatePaginationProps): { items: any[]; pages: number } => {
  const perChunk = maxItem || 10

  if (items?.length <= perChunk)
    return {
      items,
      pages: 1
    }

  const result = items.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk)

    if (!resultArray[chunkIndex]) {
      // @ts-expect-error: TO DO: fix this
      resultArray[chunkIndex] = [] // start a new chunk
    }

    // @ts-expect-error: TO DO: fix this
    resultArray[chunkIndex].push(item)

    return resultArray as []
  }, [])

  return {
    items: result,
    pages: result.length
  }
}

export { createPagination }
