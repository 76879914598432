import { Loading } from 'components'
import { PageTitle } from 'components/PageHeader/styled'
import { t } from 'i18n'
import { useContext } from 'react'
import { If } from 'react-extras'
import { Seasons } from 'store/ducks'
import styled from 'styled-components'
import Charts from '../../containers/Statistics/Charts'
import SimpleStatistic from '../../containers/Statistics/Simple'
import StatisticTable from '../../containers/Statistics/Table'
import { StatisticPageContext } from '../../context/StatisticPageContext'
import Heatmap from './heatmap'

const SimpleStatisticContainer = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0;
  padding: 40px;
`

const ContentContainer = styled.div`
  padding: 1rem;
  border: 2px solid #2b478e;
`

const TabContainer = styled.ul`
  display: flex;
  padding: 0;
  list-style: none;
  margin-bottom: -2px;
`

const Tab = styled.li`
  padding: 1rem;
  cursor: pointer;
  background-color: #2b478e;
  border: 2px solid #2b478e;
  color: white;
  border-bottom: 0;
  &.active {
    background-color: white;
    color: #2b478e;
  }
  &:first-child {
    border-top-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
  }
`

const StatisticsPage = () => {
  const { renderFilters, isLoading, statistics, activeTab, setActiveTab } =
    useContext(StatisticPageContext)

  const commonPlace = statistics?.[activeTab]?.commonPlace
  const totalInjuries = statistics?.[activeTab]?.totalInjuries
  const injuriesBySlope = statistics?.[activeTab]?.injuriesBySlope
  const injuriesInSlopesData = injuriesBySlope
    ?.sort((a, b) => b.total - a.total)
    .map((item, index) => ({
      ...item,
      index: index + 1,
      commonInjury: t(item.commonInjury)
    }))

  const injuriesInSlopesColumns = [
    {
      path: 'index'
    },
    {
      path: 'name',
      label: t('slope')
    },
    {
      path: 'commonInjury',
      label: t('commonInjury')
    },
    {
      path: 'total',
      label: t('totalInjuries')
    }
  ]

  const handleOnClick = (season: Seasons) => {
    return setActiveTab(season)
  }

  return (
    <Wrapper>
      <PageTitle>{t('statistics')}</PageTitle>

      {renderFilters()}
      <TabContainer>
        <If condition={!!statistics?.[Seasons.WINTER].totalInjuries}>
          <Tab
            className={activeTab === Seasons.WINTER ? `active` : ''}
            onClick={() => handleOnClick(Seasons.WINTER)}
          >
            {t('winter')}
          </Tab>
        </If>
        <If condition={!!statistics?.[Seasons.SUMMER].totalInjuries}>
          <Tab
            className={activeTab === Seasons.SUMMER ? `active` : ''}
            onClick={() => handleOnClick(Seasons.SUMMER)}
          >
            {t('summer')}
          </Tab>
        </If>
      </TabContainer>
      <If
        condition={
          !!statistics?.[Seasons.WINTER].totalInjuries ||
          !!statistics?.[Seasons.SUMMER].totalInjuries
        }
      >
        <ContentContainer>
          <If condition={isLoading}>
            <Loading />
          </If>
          <If condition={activeTab === Seasons.WINTER}>
            <Heatmap season={Seasons.WINTER.toLowerCase()} />
          </If>
          <If condition={activeTab === Seasons.SUMMER}>
            <Heatmap season={Seasons.SUMMER.toLowerCase()} />
          </If>
          <If condition={!isLoading}>
            <SimpleStatisticContainer>
              <SimpleStatistic
                label={t('totalInjuries')}
                value={totalInjuries || 0}
              />
              <If condition={!!commonPlace}>
                <SimpleStatistic
                  label={t('commonAccidentSite')}
                  value={t(commonPlace)}
                />
              </If>
            </SimpleStatisticContainer>
            <Charts />
            <If condition={injuriesInSlopesData?.length ? true : false}>
              <StatisticTable
                header={t('injuriesInSlope')}
                subheader={`${t('top')} ${injuriesInSlopesData?.length}`}
                data={injuriesInSlopesData}
                columns={injuriesInSlopesColumns}
              />
            </If>
          </If>
        </ContentContainer>
      </If>
      <If
        condition={
          !statistics?.[Seasons.WINTER].totalInjuries &&
          !statistics?.[Seasons.SUMMER].totalInjuries
        }
      >
        <h3>{t('noAvailableStatistics')}</h3>
      </If>
    </Wrapper>
  )
}

export default StatisticsPage
