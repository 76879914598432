import moment from 'moment'
import { Role } from 'store/ducks'
import useUser from './useUser'

export const useRole = (roles: Role[]) => {
  const { data: user } = useUser()

  const userRoles =
    (user &&
      user?.access?.filter(
        access =>
          moment().isAfter(moment(access.startsOn).toDate()) &&
          moment().isBefore(moment(access.endsOn).toDate())
      )) ||
    []

  if (userRoles.length && userRoles.find(x => roles.includes(x.role as Role))) {
    return true
  }
  return false
}
