import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import { IconCog, IconSnowFlake, IconSunnyDay } from 'assets/images'
import { ModalConfirmButton, ModalDismissButton } from 'components/Modal/styled'
import { DISABLED_ROUTES } from 'constants/common'
import { useRole } from 'hooks'
import { t } from 'i18n'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import { Dispatch as ReduxDispatch, bindActionCreators } from 'redux'
import { Role, Seasons, UserInfo } from 'store/ducks'
import * as AuthActions from 'store/ducks/auth/actions'
import { ApplicationState } from '../../store'
import DisabledTooltip from './disabled-tooltip'
import { CogWrapper, DefaultSeasonWrapper, RadioInputLabel } from './styled'

interface Props {
  user: UserInfo
  expanded: boolean
  updateResortRequest: (resortId: string, seasonName: Seasons) => void
}

const SetDefaultSeason = ({ user, updateResortRequest, expanded }: Props) => {
  const { resort, resortId } = user || {}
  const [defaultSeasonValue, setDefaultSeasonValue] = useState<
    Seasons | undefined
  >(resort?.defaultSeason)
  const [dialogData, setDialogData] = useState<{
    open: boolean
    season: Seasons
  }>({ open: false, season: Seasons.WINTER })
  const isAdmin = useRole([Role.RESORT_ADMINISTRATOR, Role.SYS_ADMIN])
  const { pathname } = useLocation()

  useEffect(() => {
    setDefaultSeasonValue(resort?.defaultSeason)
  }, [resort?.defaultSeason])

  const handleSetDefaultSeason = (seasonName: Seasons) => {
    if (resortId) {
      setDefaultSeasonValue(seasonName)
      updateResortRequest(resortId, seasonName)
      setDialogData({ open: false, season: seasonName })
    }
  }

  const handleShowModal = (seasonName: Seasons) => {
    if (defaultSeasonValue !== seasonName) {
      setDialogData({ open: true, season: seasonName })
    }
  }

  const disabledRoutes = DISABLED_ROUTES.some(item => pathname.includes(item))
  const disabledStyle = { cursor: disabledRoutes ? 'not-allowed' : 'initial' }
  return (
    <DefaultSeasonWrapper $expanded={expanded}>
      <DisabledTooltip disabled={disabledRoutes}>
        <div style={disabledStyle}>
          <RadioInputLabel
            className={defaultSeasonValue === Seasons.WINTER ? `active` : ''}
            $disabled={!isAdmin || disabledRoutes}
            $expanded={expanded}
          >
            <IconSnowFlake width={20} />
            <input
              type="radio"
              name={Seasons.WINTER}
              onClick={() => handleShowModal(Seasons.WINTER)}
            />
            {expanded && t('winter')}
          </RadioInputLabel>
          <RadioInputLabel
            className={defaultSeasonValue === Seasons.SUMMER ? `active` : ''}
            $disabled={!isAdmin || disabledRoutes}
            $expanded={expanded}
          >
            <IconSunnyDay width={20} />
            <input
              type="radio"
              name={Seasons.SUMMER}
              onClick={() => handleShowModal(Seasons.SUMMER)}
            />
            {expanded && t('summer')}
          </RadioInputLabel>
        </div>
        <Dialog
          open={dialogData.open}
          onClose={() => setDialogData({ open: false, season: Seasons.WINTER })}
        >
          <DialogTitle>{t('changeDefaultSeason')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('youAreChangingTheDefaultSeason')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ModalDismissButton
              onClick={() =>
                setDialogData({ open: false, season: Seasons.WINTER })
              }
            >
              <span>{t('cancel')}</span>
            </ModalDismissButton>
            <ModalConfirmButton
              onClick={() => handleSetDefaultSeason(dialogData.season)}
              autoFocus
            >
              <span>Ok</span>
            </ModalConfirmButton>
          </DialogActions>
        </Dialog>
      </DisabledTooltip>
      <CogWrapper $expanded={expanded}>
        <NavLink to="/settings">
          <IconCog width={20} />
        </NavLink>
      </CogWrapper>
    </DefaultSeasonWrapper>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.auth.data
})

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
  bindActionCreators(AuthActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SetDefaultSeason)
