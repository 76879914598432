import { Auth } from '@aws-amplify/auth'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18n-js'

interface ReqCodeParams {
  username: string
}

interface ChangePasswordParams {
  username: string
  code: string
  newPassword: string
}

async function requestCode({ username }: ReqCodeParams): Promise<void> {
  try {
    await Auth.forgotPassword(username)
  } catch (error: any) {
    const translatedError = t(error.name)
    throw new Error(translatedError)
  }
}
async function changePassword({
  username,
  code,
  newPassword
}: ChangePasswordParams): Promise<void> {
  try {
    await Auth.forgotPasswordSubmit(username, code, newPassword)
  } catch (error: any) {
    const translatedError = t(error.name)
    throw new Error(translatedError)
  }
}

const useRequestCode = () => {
  const reqCodeMutation = useMutation({
    mutationKey: ['reqCode'],
    mutationFn: requestCode
  })

  return reqCodeMutation
}

const useNewPassword = () => {
  const resetPassword = useMutation({
    mutationKey: ['resetPassword'],
    mutationFn: changePassword
  })

  return resetPassword
}

export { useNewPassword, useRequestCode }
