import { IconClose, IconPaperclip } from 'assets/images'
import { AudioPlayer, Button, ImageGalleryItem } from 'components'
import { useFormikContext } from 'formik'
import { t } from 'i18n'
import { useState } from 'react'
import { Media, Report, SectionKey } from 'store/ducks'
import { ModalOverlay } from '../../../../../styles/global'
import { ModalCloseIcon } from '../../form-components/FormTitle/styled'
import {
  AllFilesContainer,
  AllFilesModalContainer,
  AllFilesSection
} from './styled'

const AllFiles = () => {
  const [open, setOpen] = useState<boolean>(false)
  const { values } = useFormikContext<Report>()

  const toggleOpen = () => {
    setOpen(!open)
  }

  const getImages = (sectionKey: SectionKey) => {
    switch (sectionKey) {
      case SectionKey.SEQUENCE_OF_EVENTS:
        return values.sequenceOfEvents?.pictures
      case SectionKey.INJURY:
        return values.injury?.pictures
      default:
        return values.pictures?.filter(
          picture => picture.section === sectionKey
        )
    }
  }

  const getRecordings = (sectionKey: SectionKey) => {
    switch (sectionKey) {
      case SectionKey.SEQUENCE_OF_EVENTS:
        return values?.sequenceOfEvents?.recordings
      case SectionKey.INJURY:
        return values?.injury?.recordings
      default:
        return values?.audio?.filter(audio => audio.section === sectionKey)
    }
  }

  const renderImageGallery = (images?: Media[]) => {
    if (!images || images.length === 0) return <h3>{t`noImages`}</h3>
    return (
      <>
        {images.map((image, index) => (
          <ImageGalleryItem key={index} path={image.path} />
        ))}
      </>
    )
  }

  const renderRecordings = (recordings?: Media[]) => {
    if (!recordings || recordings.length === 0)
      return <h3>{t`noRecordings`}</h3>
    return (
      <>
        {recordings.map((recording, index) => (
          <AudioPlayer key={index} audio={recording} />
        ))}
      </>
    )
  }

  const sequenceOfEventsImages = getImages(SectionKey.SEQUENCE_OF_EVENTS)
  const sequenceOfEventsRecordings = getRecordings(
    SectionKey.SEQUENCE_OF_EVENTS
  )

  const injuryImages = getImages(SectionKey.INJURY)
  const injuryRecordings = getRecordings(SectionKey.INJURY)

  const generalImages = getImages(SectionKey.GENERAL)
  const generalRecordings = getImages(SectionKey.GENERAL)

  const renderSectionGallery = (
    title: string,
    images?: Media[],
    recordings?: Media[]
  ) => {
    return (
      <>
        <h2>{t(title)}</h2>
        <AllFilesContainer>
          <AllFilesSection style={{ flexWrap: 'wrap' }}>
            {renderImageGallery(images)}
          </AllFilesSection>
          <AllFilesSection>{renderRecordings(recordings)}</AllFilesSection>
        </AllFilesContainer>
      </>
    )
  }

  return (
    <>
      <Button
        type="button"
        variant="secondary"
        textSize="small"
        label="showAddedFiles"
        icon={<IconPaperclip />}
        onClick={toggleOpen}
      />
      {open && (
        <ModalOverlay>
          <AllFilesModalContainer>
            <ModalCloseIcon>
              <IconClose onClick={toggleOpen} />
            </ModalCloseIcon>
            {renderSectionGallery(
              'sequenceOfEvents',
              sequenceOfEventsImages,
              sequenceOfEventsRecordings
            )}
            {renderSectionGallery('injury', injuryImages, injuryRecordings)}
            {renderSectionGallery(
              'generalInfo',
              generalImages,
              generalRecordings
            )}
          </AllFilesModalContainer>
        </ModalOverlay>
      )}
    </>
  )
}

export default AllFiles
