import { Table } from 'components'
import styled from 'styled-components'

export const Container = styled.div`
  margin: 4rem 0;
  width: 100%;
`

export const Header = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  margin: 0 0 0.3rem;
  font-weight: 400;
  font-size: 1.5rem;
`

export const Subheader = styled.h4`
  color: ${({ theme }) => theme.colors.primary};
  margin: 0;
  font-weight: 300;
`

export const StyledTable = styled(Table)`
  margin-top: 1rem;
  th {
    font-weight: 500;

    &:last-child {
      text-align: right;
    }
  }

  tr {
    td {
      font-weight: 300;

      &:first-child {
        font-weight: 700;
      }

      &:last-child {
        font-weight: 700;
        text-align: right;
      }
    }
  }
`
