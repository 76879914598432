import { t } from 'i18n'
import { memo } from 'react'
import { Doughnut } from 'react-chartjs-2'
import styled from 'styled-components'
import ChartContainer from './ChartContainer'

const StyledDoughnutChart = styled(Doughnut)`
  margin-top: 2rem;
  width: 100% !important;
  max-height: 400px;
`

interface DoughnutChartProps {
  labels: string[]
  data: number[]
  title: string
}

const DoughnutChart = ({ labels, data, title }: DoughnutChartProps) => {
  if (!data) return null

  const colors = ['#FC5A56', '#385EBF', '#389FBF', '#9CBF38', '#38BF86']
  const options = {
    responsive: true,
    scales: {
      y: {
        display: false
      },
      x: {
        display: false
      }
    },

    plugins: {
      datalabels: {
        color: '#fff',
        display: 'auto'
      },
      legend: {
        position: 'right' as const,
        labels: {
          padding: 16,
          font: {
            size: 16
          },
          pointStyle: 'circle',
          usePointStyle: true
        }
      },
      title: {
        display: false
      }
    }
  }

  const defaultData = {
    labels: labels.map(label => t(label)),
    datasets: [
      {
        data,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1
      }
    ]
  }

  return (
    <ChartContainer title={title}>
      <StyledDoughnutChart data={defaultData} options={options} />
    </ChartContainer>
  )
}

export default memo(DoughnutChart)
