import { Auth } from '@aws-amplify/auth'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { t } from 'i18n-js'
import { useState } from 'react'
import { toast } from 'react-toastify'
import useAuthSession from './useAuthSession'
import useUser from './useUser'

async function logout(): Promise<void> {
  await Auth.signOut()
}

const useLogout = () => {
  const [isLoggingOut, setIsLoggingOut] = useState(false)
  const { loadAuthSession } = useAuthSession()
  const { refetch: loadUser } = useUser()
  const queryClient = useQueryClient()

  const loginMutation = useMutation({
    mutationKey: ['logout'],
    mutationFn: () => {
      setIsLoggingOut(true)
      return logout()
    },
    onSuccess: () => {
      loadAuthSession()
      loadUser()
      localStorage.clear()
      queryClient.clear()
      queryClient.removeQueries({ queryKey: ['user', 'authSession'] })
      setIsLoggingOut(false)
    },
    onError: error => {
      loadAuthSession()
      loadUser()
      localStorage.clear()
      queryClient.clear()
      queryClient.removeQueries({ queryKey: ['user', 'authSession'] })
      setIsLoggingOut(false)

      const translatedError = t(error.name)
      toast.error(translatedError)
    }
  })

  return { ...loginMutation, isLoggingOut }
}

export default useLogout
