import { SagaIterator } from 'redux-saga'
import { all, takeLatest } from 'redux-saga/effects'
import {
  changeResortSaga,
  logoffSaga,
  updateUserDataSaga,
  updateUserInfoSaga,
  userInfoSaga,
  userLogin
} from './auth/sagas'
import { AuthTypes } from './auth/types'
import {
  clearReportSaga,
  getReportSaga,
  getReportSagaOverride,
  postReportSaga
} from './report/sagas'
import {
  ClearReportTypes,
  CreateReportTypes,
  GetReportTypes
} from './report/types'
import { getReportsByKeySaga } from './reports/sagas'
import { GetReportsTypes } from './reports/types'
import { getUsersSaga } from './users/sagas'
import { GetUsersTypes } from './users/types'

export default function* rootSaga(): SagaIterator {
  return yield all([
    takeLatest(AuthTypes.LOGIN_REQUEST, userLogin),
    takeLatest(AuthTypes.USER_INFO, userInfoSaga),
    takeLatest(AuthTypes.LOGOFF_REQUEST, logoffSaga),
    takeLatest(AuthTypes.UPDATE_USER_INFO, updateUserInfoSaga),
    takeLatest(GetReportTypes.GET_REPORT_REQUEST, getReportSaga),
    takeLatest(CreateReportTypes.CREATE_REPORT_REQUEST, postReportSaga),
    takeLatest(ClearReportTypes.CLEAR_REPORT, clearReportSaga),
    takeLatest(GetReportsTypes.GET_REPORTS_REQUEST, getReportsByKeySaga),
    takeLatest(GetUsersTypes.GET_USERS_REQUEST, getUsersSaga),
    takeLatest(AuthTypes.UPDATE_RESORT_REQUEST, updateUserDataSaga),
    takeLatest(AuthTypes.CHANGE_RESORT_REQUEST, changeResortSaga),
    takeLatest(
      GetReportTypes.GET_REPORT_REQUEST_OVERRIDE,
      getReportSagaOverride
    )
  ])
}
