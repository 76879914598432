import { AxiosResponse } from 'axios'
import { Difficulties, Resort, Seasons, Slope } from 'store/ducks'
import api from './baseApi'

interface SlopeData {
  name: string
  difficulty: Difficulties
  types: Seasons[]
}

interface SignedUrl {
  url: string
  operationType: string
  expiresIn: number
  storagePath: string
}

export const getSlopesList = async () => {
  const response: AxiosResponse<Slope[]> = await api.get('resort/slopes')
  return response.data
}

export const getSpecificSlope = async (id: string) => {
  const response: AxiosResponse<Slope> = await api.get(`resort/slope/${id}`)
  return response.data
}

export const createSlope = async (data: SlopeData) => {
  const response: AxiosResponse<Slope> = await api.post(`resort/slope`, {
    ...data
  })
  return response.data
}

export const updateSpecificSlope = async (id: string, data: SlopeData) => {
  const response: AxiosResponse<Slope> = await api.put(`resort/slope/${id}`, {
    ...data
  })
  return response.data
}

export const deleteSpecificSlope = async (id: string) => {
  const response: AxiosResponse<Slope> = await api.delete(`resort/slope/${id}`)
  return response.data
}

export const setDefaultSeason = async ({
  resortId,
  seasonName
}: {
  resortId: string
  seasonName: Seasons
}) => {
  const response: AxiosResponse<Resort> = await api.patch(
    `resort/default-season/${resortId}`,
    { season: seasonName }
  )
  return response.data
}

export const uploadHeatmap = async (body: any): Promise<SignedUrl> => {
  const { data } = await api.post<SignedUrl>('resort/heatmap', body)
  return data
}
