import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material'
import { t } from 'i18n'

interface Props {
  children: React.ReactNode
  disabled: boolean
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))

const DisabledTooltip = ({ children, disabled }: Props) => {
  return (
    <LightTooltip
      title={t('pleaseFinishYourCurrentActivity')}
      disableHoverListener={!disabled}
    >
      <div>{children}</div>
    </LightTooltip>
  )
}

export default DisabledTooltip
