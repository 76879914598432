import { DateTimePickerContainer } from './styled'
interface IDateTimePickerProps {
  value?: Date
  dateFormat?: string
  timeOnly?: boolean
  timeIntervals?: number
  onChange: (value: Date) => void
}

const DateTimePicker = ({
  value,
  dateFormat = 'dd/MM/yyyy, HH:mm',
  timeOnly = false,
  timeIntervals = 15,
  onChange
}: IDateTimePickerProps) => {
  return (
    <DateTimePickerContainer
      showTimeSelect
      showTimeSelectOnly={timeOnly}
      timeIntervals={timeIntervals}
      onChange={(value: Date) => onChange(value)}
      selected={value ? new Date(value) : new Date()}
      timeFormat="HH:mm"
      dateFormat={dateFormat}
      customInput={null}
    />
  )
}

export { DateTimePicker }
