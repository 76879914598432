import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import auth from './auth'
import report from './report'
import reports from './reports'
import users from './users'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'report', 'users', 'reports']
}

const reducers = combineReducers({
  auth,
  report,
  reports,
  users
})

export default persistReducer(persistConfig, reducers)
