import { IconCircleX } from 'assets/images'
import { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { GetTherapistsList } from 'services'
import { Creator, Therapist, UsersState } from 'store/ducks'
import * as UsersActions from 'store/ducks/users/actions'
import { v4 as uuid } from 'uuid'
import { ApplicationState } from '../../store'
import {
  DropdownUserContainer,
  DropdownUserListContainer,
  DropdownUserListItem,
  InputGroup,
  SelectedUserItem
} from './styled'

interface IUsersSelectorProps {
  users: UsersState
  add: (user: Creator) => void
  remove: (index: number) => void
  value?: Creator[]
}

const UsersSelector = ({ add, remove, value }: IUsersSelectorProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: any) => {
    if (wrapperRef && !wrapperRef?.current?.contains(event.target)) {
      setOpen(false)
    }
  }

  const addUser = (newUser: Creator) => {
    const alreadyIn = value?.some(user => user.numericId === newUser.numericId)
    if (alreadyIn) return
    add(newUser)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const [allUsers, setAllUsers] = useState<Therapist[]>()
  const [loading, setLoading] = useState<boolean>(false)

  const getUsersRequest = async () => {
    setLoading(true)
    const _users: Therapist[] = await GetTherapistsList()
    setAllUsers(_users)
    setLoading(false)
  }

  useEffect(() => {
    getUsersRequest()
  }, [])

  if (loading) return null

  return (
    <InputGroup ref={wrapperRef}>
      <DropdownUserContainer onClick={() => setOpen(!open)}>
        {open && (
          <DropdownUserListContainer>
            {allUsers &&
              allUsers.map(item => (
                <DropdownUserListItem
                  key={item.id}
                  onClick={() =>
                    addUser({
                      numericId: item.numericId,
                      fullName: `${item.fullName}`
                    })
                  }
                >
                  {item.fullName}
                </DropdownUserListItem>
              ))}
          </DropdownUserListContainer>
        )}
      </DropdownUserContainer>
      {value &&
        value.map((item, index) => (
          <SelectedUserItem key={uuid()}>
            <div>
              {item.fullName} - {item.numericId}
            </div>
            <IconCircleX onClick={() => remove(index)} />
          </SelectedUserItem>
        ))}
    </InputGroup>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  users: state.users
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(UsersActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(UsersSelector)
