import styled from 'styled-components'
import { theme } from 'styles/theme'

const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: ${theme.textSizes.small};
  color: ${theme.colors.gray};
  margin: 20px;

  svg {
    fill: ${theme.colors.gray} !important;
    width: 16px;
    height: 14px;
    margin-left: 5px;
  }
`

export { BackButtonContainer }
