import { IconPlusCircle } from 'assets/images'
import { Button, EventMonitoring } from 'components'
import { useFormikContext } from 'formik'
import moment from 'moment'
import { useState } from 'react'
import { If } from 'react-extras'
import {
  Monitoring,
  MonitoringExamination,
  MonitoringState,
  Report
} from 'store/ducks'
import { v4 as uuid } from 'uuid'
import MonitoringForm from '../../../../../containers/MonitoringForm'
import FormTitle from '../FormTitle'
import { FlexRow, RowItem } from '../styled'

const SurveillanceSection = () => {
  const [open, setOpen] = useState<boolean>(false)
  const {
    values: { investigationAndMonitoring }
  } = useFormikContext<Report>()

  const [currentMonitoring, setCurrentMonitoring] = useState<Monitoring>(
    {} as unknown as Monitoring
  )

  const startNewMonitoring = () => {
    const newMonitoring: Monitoring = {
      id: uuid(),
      breathFrequency: '',
      monitoringHour: moment().toDate(),
      state: '' as MonitoringState,
      conductedExamination: '' as MonitoringExamination,
      time: moment().toDate(),
      glasgowScore: '',
      pulse: '',
      bloodPressure: ''
    }

    setCurrentMonitoring(newMonitoring)
    setOpen(true)
  }

  const saveMonitoring = () => {
    const isUpdate = investigationAndMonitoring.monitoring.find(
      m => m.id === currentMonitoring.id
    )

    if (isUpdate) {
      for (let mon of investigationAndMonitoring.monitoring) {
        if (mon.id === currentMonitoring.id) {
          mon = {
            ...currentMonitoring
          }
        }
      }
    } else {
      investigationAndMonitoring?.monitoring.push(currentMonitoring)
    }

    setOpen(false)
  }

  const openMonitoring = (monitoring: Monitoring) => {
    setCurrentMonitoring(monitoring)
    setOpen(true)
  }

  return (
    <>
      <FormTitle title="monitoring" />
      <FlexRow>
        <RowItem>
          {investigationAndMonitoring.monitoring &&
            investigationAndMonitoring.monitoring.map((monitoring, index) => (
              <EventMonitoring
                key={index}
                monitoring={monitoring}
                openMonitoring={openMonitoring}
              />
            ))}
        </RowItem>
      </FlexRow>
      <If condition={!open}>
        <FlexRow>
          <RowItem>
            <Button
              style={{ maxWidth: '21vw', marginTop: 20 }}
              icon={<IconPlusCircle width={25} height={25} fill="#2b2b2b" />}
              variant="default"
              label={'startNewMonitoring'}
              onClick={startNewMonitoring}
              type="button"
            />
          </RowItem>
        </FlexRow>
      </If>
      <If condition={open && currentMonitoring ? true : false}>
        <MonitoringForm
          onChangeData={(_monitoring: Monitoring) => {
            setCurrentMonitoring(_monitoring)
          }}
          saveMonitoring={saveMonitoring}
          monitoring={currentMonitoring}
        />
      </If>
    </>
  )
}

export default SurveillanceSection
