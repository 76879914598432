import { useFormikContext } from 'formik'
import { t } from 'i18n'
import { useState } from 'react'
import { formatDate, formatTime } from 'services'
import { Report } from 'store/ducks'
import AllFiles from './AllFiles'
import ReportHeaderDateTimePicker from './ReportHeaderDateTimePicker'
import {
  FlexColumn,
  ReportHeaderBaseInfoContainer,
  ReportHeaderContainer,
  ReportHeaderDateItem,
  ReportHeaderInjuryNumber,
  ReportHeaderInjuryTitle,
  ReportHeaderTitle,
  ReportStepperContainer,
  StepItem
} from './styled'

interface IReportHeaderProps {
  formStep: number
  injuryReportNumber?: string
}

const ReportHeader = ({ formStep, injuryReportNumber }: IReportHeaderProps) => {
  const [openCalendar, setOpenCalendar] = useState<boolean>(false)
  const {
    values: { reportDate },
    setFieldValue
  } = useFormikContext<Report>()

  const toggleOpenCalendar = () => {
    setOpenCalendar(!openCalendar)
  }

  return (
    <ReportHeaderContainer>
      <ReportHeaderBaseInfoContainer>
        <div>
          <ReportHeaderTitle>{t`myReport`}</ReportHeaderTitle>
          <ReportHeaderInjuryTitle>{t`injuryReportNumber`}</ReportHeaderInjuryTitle>
          <ReportHeaderInjuryNumber>
            {injuryReportNumber}
          </ReportHeaderInjuryNumber>
        </div>
        <FlexColumn>
          <ReportHeaderDateItem>
            <small>{t`time`}:</small>
            <strong>{formatTime(reportDate)}</strong>
          </ReportHeaderDateItem>
          <ReportHeaderDateItem
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={toggleOpenCalendar}
          >
            <small>{t`date`}:</small>
            <strong>{formatDate(reportDate)}</strong>
          </ReportHeaderDateItem>
          {/* TODO:: This component was created when api was down, please make sure this works properly when api is back */}
          <ReportHeaderDateTimePicker
            open={openCalendar}
            toggleOpen={toggleOpenCalendar}
            value={reportDate}
            onChange={value =>
              setFieldValue('reportDate', new Date(value).toISOString())
            }
          />
        </FlexColumn>
      </ReportHeaderBaseInfoContainer>
      <ReportStepperContainer>
        <div>
          <StepItem active={formStep === 1}>{t`generalInfo`}</StepItem>
          <StepItem active={formStep === 2}>{t`followUp`}</StepItem>
        </div>
        <div>
          <AllFiles />
        </div>
      </ReportStepperContainer>
    </ReportHeaderContainer>
  )
}

export default ReportHeader
