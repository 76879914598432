import { Auth } from '@aws-amplify/auth'
import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

api.interceptors.request.use(async request => {
  try {
    const session = await Auth.currentSession()

    if (session) {
      if (request.headers) {
        request.headers.Authorization = `Bearer ${session
          .getAccessToken()
          .getJwtToken()}`
      }
    }
    return request
  } catch (error) {
    if (error === 'No current user') window.location.href = '/sign-in'
  }
})

export default api
