import Layout from 'Layout'
import { Loading } from 'components'
import { useRole } from 'hooks'

import useAuthSession from 'hooks/useAuthSession'
import useUser from 'hooks/useUser'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { Role } from 'store/ducks'

interface Props {
  children: React.ReactNode
  roles?: Role[]
}

const checkRoutes = (pathname: string, routes: string[]): boolean => {
  return routes.some(route => {
    const isMatch = pathname.startsWith(route)
    if (isMatch && route.endsWith('*')) {
      return true
    }

    return isMatch
  })
}

const RouteMiddleware = ({ children, roles }: Props) => {
  const location = useLocation()

  const { isAuthenticated, isAuthLoading, isAuthPlaceholder } = useAuthSession()
  const { data: user, isLoading: isUserLoading } = useUser()
  const isRoleValid = useRole(roles ?? [])

  const nonAdminRoutes = [
    '/',
    '/reports',
    '/reports/*',
    '/report-overview',
    '/pending-account'
  ]
  const authRoutes = ['/sign-in']
  const commonRoutes = ['/forgot-password', '/forgot-password/*']

  const isAuthRoute = checkRoutes(location.pathname, authRoutes)
  const isPublicRoute = checkRoutes(location.pathname, [
    ...commonRoutes,
    ...authRoutes
  ])
  const isNonAdminRoute = checkRoutes(location.pathname, nonAdminRoutes)

  const isPendingPage = location.pathname === '/pending-account'

  if (isAuthLoading || isAuthPlaceholder) return <Loading />

  if (!isAuthenticated) {
    if (!isPublicRoute) return <Navigate to="/sign-in" />
    return <React.Fragment>{children}</React.Fragment>
  }

  if (isUserLoading) {
    return <Loading />
  }

  const isUserPending = user?.userStatus === 'PENDING'

  if (isUserPending) {
    if (!isPendingPage) return <Navigate to="/pending-account" />
    return <React.Fragment>{children}</React.Fragment>
  }

  if (isAuthRoute || isPendingPage) return <Navigate to="/" />
  if (!isRoleValid && !isNonAdminRoute) return <Navigate to="/" />
  return <Layout>{children}</Layout>
}

export default RouteMiddleware
