import { AxiosResponse } from 'axios'
import { User } from 'store/ducks'
import api from './baseApi'

export const getUsers = async (): Promise<User[]> => {
  const response: AxiosResponse<User[]> = await api.get<User[]>(`admin/users`)
  return response.data
}

export const getUserById = async (id?: string): Promise<User> => {
  const response: AxiosResponse<User> = await api.get<User>(`admin/user/${id}`)
  return response.data
}

export const getUserToExport = async (): Promise<any[]> => {
  const response: AxiosResponse<any[]> =
    await api.get<any[]>(`admin/users/export`)
  return response.data
}

export const deleteUserById = async (id?: string): Promise<User> => {
  const response: AxiosResponse<User> = await api.delete<User>(
    `admin/user/${id}`
  )
  return response.data
}

export const resetPassword = async (id?: string): Promise<any> => {
  const response: AxiosResponse<User> = await api.patch<User>(
    `admin/user/reset-pass/${id}`
  )
  return response.data
}

export const updateUserById = async (
  id?: string,
  payload?: User
): Promise<User> => {
  const response: AxiosResponse<User> = await api.patch<User>(
    `admin/user/${id}`,
    payload
  )
  return response.data
}

export const adminCreateUser = async (payload?: User): Promise<User> => {
  const response: AxiosResponse<User> = await api.post<User>(
    `admin/user`,
    payload
  )
  return response.data
}

export const updateUserResort = async (resortId: string): Promise<User> => {
  const response: AxiosResponse<User> = await api.patch<User>(`admin/user`, {
    resortId
  })

  return response.data
}
