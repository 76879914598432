import styled from 'styled-components'
import theme from 'styles/theme'

const InputWrapper = styled.div`
  margin-bottom: 1rem;
  font-size: 1rem;
`

const Label = styled.label`
  font-size: 0.8rem;
`

interface ErrorMessageProps {
  $textAlign?: 'center' | 'left' | 'right'
  $width?: 'auto' | '100%'
  $noWrap?: boolean
}

const ErrorMessage = styled.span<ErrorMessageProps>`
  display: inline-block;
  color: ${theme.colors.red};
  font-size: 0.8rem;
  text-align: ${({ $textAlign }) => $textAlign || 'left'};

  width: ${({ $width }) => $width || 'auto'};
  &::before {
    content: ${({ $noWrap }) => ($noWrap ? '' : '(')};
  }

  &::after {
    content: ${({ $noWrap }) => ($noWrap ? '' : ')')};
  }
`

export { ErrorMessage, InputWrapper, Label }
