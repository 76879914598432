import { Modal } from '@mui/material'
import styled from 'styled-components'

type ButtonProps = {
  $bgColor: BUTTON_COLOR
  $bordered?: boolean
}

export enum BUTTON_COLOR {
  RED = 'red',
  BLUE = 'primary'
}

export const FlexContainer = styled.div`
  display: flex;
`
export const ColumnTitle = styled.h3`
  font-size: 1rem;
  margin-bottom: 1.5rem;
  margin-top: 4rem;
`

export const SmallTitle = styled.h4`
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`

export const ColumnContainer = styled.div`
  width: 300px;
  margin-right: 5rem;

  p {
    font-weight: 300;
    font-size: 0.8rem;
    margin: 0;
  }
`

export const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  button {
    margin: 0;
  }
`

export const Button = styled.button<ButtonProps>`
  width: 100%;
  padding: 1rem;
  text-align: left;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
  cursor: pointer;
  transition: 0.5s;

  background-color: ${({ theme, $bgColor, $bordered }) =>
    $bordered ? 'transparent' : theme.colors[$bgColor]};
  color: ${({ theme, $bordered, $bgColor }) =>
    $bordered ? theme.colors[$bgColor] : theme.colors.white};
  border: ${({ theme, $bgColor }) => `2px solid ${theme.colors[$bgColor]}`};

  &:hover {
    background-color: ${({ theme, $bgColor, $bordered }) =>
      $bordered ? theme.colors[$bgColor] : 'transparent'};
    color: ${({ theme, $bordered, $bgColor }) =>
      $bordered ? theme.colors.white : theme.colors[$bgColor]};
    border: ${({ theme, $bgColor }) => `2px solid ${theme.colors[$bgColor]}`};

    svg {
      * {
        fill: ${({ theme, $bordered, $bgColor }) =>
          $bordered ? theme.colors.white : theme.colors[$bgColor]};
      }
    }
  }

  svg {
    height: 20px;
    * {
      fill: ${({ theme, $bordered, $bgColor }) =>
        $bordered ? theme.colors[$bgColor] : theme.colors.white};
      border: none;
    }
  }
`

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Box = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 2rem;
  max-width: 500px;

  h3 {
    margin-top: 0;
  }
`
