import { IconArrowBack, IconArrowRight } from 'assets/images'
import { Button } from 'components'
import HeaderSection from '../Step3/HeaderSection'
import { FlexRow, RowItem } from '../styled'
import ConfirmationSection from './ConfirmationSection'

interface IReportFormStep4 {
  setFormStep: (step: number) => void
  handleBack?: () => void
}

const ReportFormStep4 = ({ setFormStep, handleBack }: IReportFormStep4) => {
  return (
    <div>
      <HeaderSection title="confirmation" goBack={() => setFormStep(3)} />
      <ConfirmationSection />
      <FlexRow style={{ justifyContent: 'flex-end', padding: '20px 40px' }}>
        <RowItem>
          <div style={{ marginBottom: '10px' }}>
            <Button
              style={{ marginRight: '10px' }}
              type="button"
              label="goBack"
              variant="default"
              onClick={handleBack}
              icon={<IconArrowBack fill="#fff" />}
            />
          </div>
          <Button
            type="submit"
            label="continue"
            variant="primary"
            icon={<IconArrowRight />}
          />
        </RowItem>
      </FlexRow>
    </div>
  )
}

export default ReportFormStep4
