import styled from 'styled-components'

export const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const TableRow = styled.tr`
  padding-bottom: 10px;
  height: 50px;
`

export const DataCell = styled.td`
  font-size: 16px;
  color: #2b2b2b;
  text-align: left;
  border-bottom: 1px solid #d2d2d2;
`
export const TableHeader = styled.th`
  font-size: 16px;
  color: #2b2b2b;
  text-align: left;
  border-bottom: 1px solid #d2d2d2;
`
