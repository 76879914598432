import { IconSave } from 'assets/images'
import { Button, ReportsSelector, TextInput, UsersSelector } from 'components'
import { FieldArray, useFormikContext } from 'formik'
import { translate as t } from 'i18n'
import { remove } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { getReportsRelated } from 'services'
import { Creator, RelatedReport, Report } from 'store/ducks'
import * as ReportsActions from 'store/ducks/reports/actions'
import { v4 as uuid } from 'uuid'
import { ApplicationState } from '../../../../../store'
import {
  ConfirmationSectionContainer,
  ConfirmationSectionItem
} from '../styled'

const ConfirmationSection = () => {
  const [relatedList, setRelatedList] = useState<RelatedReport[]>()
  const [notRegistered, setNotRegistered] = useState<Creator>(
    undefined as unknown as Creator
  )

  const {
    values: { mainCreator, additionalCreators, reportId, relatedReports },
    setFieldValue
  } = useFormikContext<Report>()

  const handleSearch = useCallback(
    async (text?: string) => {
      const patientName = isNaN(Number(text)) ? text : ''
      const _reportId = !isNaN(Number(text)) ? Number(text) : undefined
      const _reports: RelatedReport[] = await getReportsRelated({
        patientName,
        reportId: _reportId
      })
      remove(_reports, _report => {
        return _report.reportId === reportId
      })
      setRelatedList(_reports)
    },
    [reportId]
  )

  useEffect(() => {
    handleSearch()
  }, [handleSearch])

  const handleAddTherapist = () => {
    const creators: Creator[] = additionalCreators
      ? additionalCreators.concat([notRegistered])
      : [notRegistered]

    setFieldValue('additionalCreators', creators)
    setNotRegistered(undefined as unknown as Creator)
  }

  return (
    <ConfirmationSectionContainer>
      <ConfirmationSectionItem>
        <b>{t`therapist`}</b>
        <TextInput
          type="text"
          label="headTherapist"
          value={mainCreator?.fullName}
          name="mainCreator.fullName"
          readOnly
        />
        <TextInput
          type="text"
          label="therapistId"
          value={mainCreator?.numericId}
          name="mainCreator.numericId"
          readOnly
        />
      </ConfirmationSectionItem>
      <ConfirmationSectionItem>
        <b>{t`addMoreTherapist`}</b>
        <FieldArray
          name="additionalCreators"
          render={arrayHelpers => (
            <UsersSelector
              add={arrayHelpers.push}
              remove={arrayHelpers.remove}
              value={additionalCreators}
            />
          )}
        />
        <b>{t`additionalNotRegisteredCreator`}</b>
        <TextInput
          type="text"
          label=""
          value={notRegistered ? notRegistered?.fullName : ''}
          name="mainCreator.fullName"
          onChange={e => {
            setNotRegistered({
              id: uuid(),
              numericId: 0,
              fullName: e.target.value
            })
          }}
        />
        <Button
          type="button"
          variant="default"
          onClick={handleAddTherapist}
          label="save"
          icon={<IconSave />}
        />
        <h3 style={{ fontSize: 20 }}>{t`linkReports`}</h3>
        <FieldArray
          name="relatedReports"
          render={arrayHelpers => (
            <ReportsSelector
              foundReports={relatedList}
              searchReports={handleSearch}
              selectedReports={relatedReports}
              add={arrayHelpers.push}
              remove={arrayHelpers.remove}
            />
          )}
        />
      </ConfirmationSectionItem>
    </ConfirmationSectionContainer>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  reports: state.reports
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(ReportsActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationSection)
