import { Auth } from '@aws-amplify/auth'
import { t } from 'i18n-js'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import useUser from './useUser'

const useAuthSession = () => {
  const { refetch: loadUser } = useUser()

  const [isAuthLoading, setIsAuthLoading] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isAuthPlaceholder, setIsAuthPlaceholder] = useState(true)

  const loadAuthSession = useCallback(async () => {
    setIsAuthLoading(true)
    try {
      const session = await Auth.currentSession()

      if (session.isValid()) {
        loadUser()
        setIsAuthenticated(() => session.isValid())
      }
      setIsAuthPlaceholder(false)
      return setIsAuthLoading(false)
    } catch (error: any) {
      const translatedError = t(error.name)
      toast.error(translatedError)
      setIsAuthPlaceholder(false)
      return setIsAuthLoading(false)
    }
  }, [loadUser])

  useEffect(() => {
    loadAuthSession()
  }, [loadAuthSession])

  return {
    isAuthLoading,
    isAuthenticated,
    loadAuthSession,
    isAuthPlaceholder
  }
}
export default useAuthSession
