import styled from 'styled-components'
import { theme } from 'styles/theme'

const ReportHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 70px 160px 120px 70px;
`

const ReportHeaderTitle = styled.h1`
  font-size: ${theme.textSizes.h1};
  color: ${theme.colors.gray};
  max-width: 390px;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const FlexRowEnd = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const FlexRowSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ReportHeaderDateItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 6px 0;

  small {
    font-size: ${theme.textSizes.smaller};
    text-align: right;
    color: ${theme.colors.gray};
  }

  strong {
    font-size: ${theme.textSizes.normal};
    font-weight: 600;
    text-align: right;
    color: ${theme.colors.gray};
  }
`

export {
  FlexColumn,
  FlexRowEnd,
  FlexRowSpaceBetween,
  ReportHeaderContainer,
  ReportHeaderDateItem,
  ReportHeaderTitle
}
