import { IconArrowRight } from 'assets/images'
import useAuthSession from 'hooks/useAuthSession'
import { t } from 'i18n'
import { Link } from 'react-router-dom'
import { SubmitButton } from '../styled'
import { PasswordChangedSuccessContainer } from './styled'

const PasswordChangedSuccess = () => {
  const { isAuthenticated } = useAuthSession()

  if (isAuthenticated) return null

  return (
    <PasswordChangedSuccessContainer>
      <Link to="/sign-in">
        <SubmitButton type="button">
          {t('login')}
          <IconArrowRight />
        </SubmitButton>
      </Link>
    </PasswordChangedSuccessContainer>
  )
}

export default PasswordChangedSuccess
