import { IconLogo } from 'assets/images'
import { t } from 'i18n'
import { useEffect, useState } from 'react'
import { If } from 'react-extras'
import { useSearchParams } from 'react-router-dom'
import { theme } from 'styles/theme'
import { phoneNumberSchema } from '../../../schema/common.schema'
import ConfirmCodeForm from './ConfirmPasswordForm'
import ForgotPasswordForm from './ForgotPasswordForm'
import PasswordChangedSuccess from './PasswordChangeSuccess'
import {
  CenterContainer,
  ForgotPasswordContainer,
  ForgotPasswordImage,
  ForgotPasswordImageText,
  Message,
  StepHeading
} from './styled'

const ForgotPasswordPage = () => {
  const [stepForm, setStepForm] = useState<string>('1')
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const userName = searchParams.get('userName')
    const step = searchParams.get('step')

    async function valdatePhoneNumber() {
      const phoneValidation = await phoneNumberSchema.safeParse(userName)
      if (!phoneValidation.success) return false
      return true
    }
    const isPhoneValid = valdatePhoneNumber()

    if (!isPhoneValid) {
      setStepForm('1')
    }

    setStepForm(step ?? '1')
  }, [searchParams])

  const isFirstStep = stepForm === '1'
  const isSecondStep = stepForm === '2'
  const isThirdStep = stepForm === '3'

  return (
    <CenterContainer>
      <ForgotPasswordContainer>
        <ForgotPasswordImage>
          <CenterContainer>
            <IconLogo />
            <ForgotPasswordImageText>
              {t`appIntroTitle`}
            </ForgotPasswordImageText>
            <ForgotPasswordImageText color={theme.colors.primary}>
              {t`appIntroSubtitle`}
            </ForgotPasswordImageText>
          </CenterContainer>
        </ForgotPasswordImage>
        <If condition={isFirstStep}>
          <StepHeading>{t`forgotPassword`}</StepHeading>
          <ForgotPasswordForm />
        </If>
        <If condition={isSecondStep}>
          <StepHeading>{t`codeSent`}</StepHeading>
          <Message>{t('kindlyCheckYourEmail')}</Message>
          <ConfirmCodeForm />
        </If>
        <If condition={isThirdStep}>
          <StepHeading>{t`passwordChanged`}</StepHeading>
          <Message>{t('passwordChangedSuccessfully')}</Message>
          <PasswordChangedSuccess />
        </If>
      </ForgotPasswordContainer>
    </CenterContainer>
  )
}

export default ForgotPasswordPage
