import { t } from 'i18n'
import React, { ReactNode } from 'react'
import { ButtonContainer } from './styled'

export type VariantType =
  | 'primary'
  | 'secondary'
  | 'outlined'
  | 'light'
  | 'default'

export type TextSizeType = 'small' | 'normal'

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string
  icon?: ReactNode
  variant?: VariantType
  textSize?: TextSizeType
  buttonStyle?: {
    borderRadius: number | string
  }
}

const Button = ({ label, icon, buttonStyle, ...props }: IButtonProps) => {
  return (
    <ButtonContainer style={buttonStyle} {...props}>
      {label ? t(label) : ''}
      {icon}
    </ButtonContainer>
  )
}

export { Button }
