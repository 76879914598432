import { action } from 'typesafe-actions'
import { GetUsersTypes, User } from './types'

export const getUsersRequest = () => action(GetUsersTypes.GET_USERS_REQUEST)

export const getUsersSuccess = (data: User[]) =>
  action(GetUsersTypes.GET_USERS_SUCCESS, data)

export const getUsersFailure = (error: Error) =>
  action(GetUsersTypes.GET_USERS_FAILURE, { errorStack: error })
