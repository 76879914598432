import { Amplify } from '@aws-amplify/core'
import ReactDOM from 'react-dom/client'
import App from './App'
import awsConfig from './config/aws-config'
import { setI18nConfig } from './i18n'
import reportWebVitals from './reportWebVitals'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'

setI18nConfig()

Amplify.configure(awsConfig)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
