import { t } from 'i18n'
import moment from 'moment'
import { Monitoring } from 'store/ducks'
import { toCamelCase } from '../../utils/formatters'
import {
  ColContainer,
  MonitoringCardContainer,
  ReportOverviewInfoGroup,
  ReportOverviewLabel,
  RowContainer
} from './styled'

interface Props {
  monitoring: Monitoring
}

const MonitoringCard = ({ monitoring }: Props) => {
  return (
    <MonitoringCardContainer>
      <RowContainer>
        <ColContainer>
          <RowContainer>
            <ReportOverviewInfoGroup>
              <ReportOverviewLabel
                padding={'5px 0 10px 0'}
                size={18}
                weight={600}
              >
                {t('time')}
              </ReportOverviewLabel>
              <ReportOverviewLabel size={16}>
                {monitoring.monitoringHour &&
                  moment(monitoring.monitoringHour).format('HH:mm')}
              </ReportOverviewLabel>
            </ReportOverviewInfoGroup>
          </RowContainer>
          <RowContainer>
            <ReportOverviewInfoGroup>
              <ReportOverviewLabel
                padding={'5px 0 10px 0'}
                size={18}
                weight={600}
              >
                {t('state')}
              </ReportOverviewLabel>
              <ReportOverviewLabel size={16}>
                {t(toCamelCase(monitoring.state))}
              </ReportOverviewLabel>
            </ReportOverviewInfoGroup>
          </RowContainer>
          <RowContainer>
            <ReportOverviewInfoGroup>
              <ReportOverviewLabel
                padding={'5px 0 10px 0'}
                size={18}
                weight={600}
              >
                {t('conductedPassiveExamination')}
              </ReportOverviewLabel>
              <ReportOverviewLabel size={16}>
                {t(toCamelCase(monitoring.conductedExamination))}
              </ReportOverviewLabel>
            </ReportOverviewInfoGroup>
          </RowContainer>
          <RowContainer>
            <ReportOverviewInfoGroup>
              <ReportOverviewLabel
                padding={'5px 0 10px 0'}
                size={18}
                weight={600}
              >
                {t('patientTemperatureOverview')}
              </ReportOverviewLabel>
              <ReportOverviewLabel size={16}>
                {monitoring.bodyTemperature?.toFixed(1) || '-'}˚C
              </ReportOverviewLabel>
            </ReportOverviewInfoGroup>
          </RowContainer>
        </ColContainer>
        <ColContainer>
          <RowContainer>
            <ReportOverviewInfoGroup>
              <ReportOverviewLabel
                padding={'5px 0 10px 0'}
                size={18}
                weight={600}
              >
                {t('respiratoryRate')}
              </ReportOverviewLabel>
              <ReportOverviewLabel size={16}>
                {monitoring.breathFrequency}
              </ReportOverviewLabel>
            </ReportOverviewInfoGroup>
            <ReportOverviewInfoGroup>
              <ReportOverviewLabel
                padding={'5px 0 10px 0'}
                size={18}
                weight={600}
              >
                {t('glasgowScore')}
              </ReportOverviewLabel>
              <ReportOverviewLabel size={16}>
                {monitoring.glasgowScore}
              </ReportOverviewLabel>
            </ReportOverviewInfoGroup>
          </RowContainer>
          <RowContainer>
            <ReportOverviewInfoGroup>
              <ReportOverviewLabel
                padding={'5px 0 10px 0'}
                size={18}
                weight={600}
              >
                {t('pulseSP02')}
              </ReportOverviewLabel>
              <ReportOverviewLabel size={16}>
                {monitoring.pulse}
              </ReportOverviewLabel>
            </ReportOverviewInfoGroup>
            <ReportOverviewInfoGroup>
              <ReportOverviewLabel
                padding={'5px 0 10px 0'}
                size={18}
                weight={600}
              >
                {t('bloodPressure')}
              </ReportOverviewLabel>
              <ReportOverviewLabel size={16}>
                {monitoring.bloodPressure}
              </ReportOverviewLabel>
            </ReportOverviewInfoGroup>
          </RowContainer>
          <RowContainer>
            <ReportOverviewInfoGroup>
              <ReportOverviewLabel
                padding={'5px 0 10px 0'}
                size={18}
                weight={600}
              >
                {t('pain')}
              </ReportOverviewLabel>
              <ReportOverviewLabel size={16}>
                {monitoring.pain}
              </ReportOverviewLabel>
            </ReportOverviewInfoGroup>
          </RowContainer>
        </ColContainer>
      </RowContainer>
    </MonitoringCardContainer>
  )
}

export default MonitoringCard
