import { t } from 'i18n'
import { ChangeEvent } from 'react'
import {
  CheckboxGroupContainer,
  CheckboxGroupItemLabel,
  CheckboxGroupLabel,
  CheckboxWrapContainer
} from './styled'

type OptionItem = {
  id: string
  label: string | number
}

interface ICheckboxGroupInputProps {
  name: string
  value?: any
  label?: string
  options: OptionItem[]
  onChange: (name: string, value: string | boolean) => void
}

const CheckboxGroupInput = ({
  name,
  value,
  label,
  onChange,
  options
}: ICheckboxGroupInputProps) => {
  const handleCheck = (
    event: ChangeEvent<HTMLInputElement>,
    key: string
  ): void => {
    const {
      target: { checked }
    } = event
    onChange(`${name}.${key}`, checked)
  }

  return (
    <CheckboxGroupContainer>
      {label && <CheckboxGroupLabel>{t(label)}</CheckboxGroupLabel>}
      <CheckboxWrapContainer>
        {options.map(({ id, label }, index) => (
          <CheckboxGroupItemLabel
            htmlFor={`${name}.${id}`}
            key={index}
            active={(value && value[id]) ?? false}
          >
            <input
              id={`${name}.${id}`}
              type="checkbox"
              name={name}
              onChange={e => handleCheck(e, id)}
              checked={(value && value[id]) ?? false}
            />
            <span className="checkmark" />
            <p>{t(label)}</p>
          </CheckboxGroupItemLabel>
        ))}
      </CheckboxWrapContainer>
    </CheckboxGroupContainer>
  )
}

export { CheckboxGroupInput }
