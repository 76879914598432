import { configureStore } from '@reduxjs/toolkit'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistStore
} from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import { UsersState } from 'store/ducks'
import sagas from 'store/ducks/rootSaga'
import { AuthState } from './ducks/auth/types'
import { ReportState } from './ducks/report/types'
import { ReportsState } from './ducks/reports/types'
import reducers from './ducks/rootReducer'

export interface ApplicationState {
  auth: AuthState
  report: ReportState
  reports: ReportsState
  users: UsersState
}

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: reducers,
  enhancers: [],
  devTools: process.env.NODE_ENV === 'development' ? true : false,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat([sagaMiddleware])
})

sagaMiddleware.run(sagas)

const persistor = persistStore(store)

export default store

export { persistor, store }
