import DateTimePicker from 'react-datepicker'
import styled from 'styled-components'
import { theme } from 'styles/theme'

const ReportHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
  padding: 20px 60px;
`

const ReportHeaderBaseInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ReportHeaderTitle = styled.h1`
  font-size: ${theme.textSizes.h1};
`

const ReportHeaderInjuryTitle = styled.span`
  font-size: ${theme.textSizes.small};
`

const ReportHeaderInjuryNumber = styled.span`
  display: block;
  margin-top: 5px;
  font-size: ${theme.textSizes.large};
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const ReportHeaderDateItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 25px 0;

  small {
    font-size: ${theme.textSizes.smaller};
    text-align: right;
  }

  strong {
    font-size: ${theme.textSizes.normal};
    font-weight: 600;
    text-align: right;
  }
`

const ReportStepperContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
`

const DateTimePickerContainer = styled(DateTimePicker)`
  display: none;
`

const StepItem = styled.div<{ active: boolean }>`
  display: inline-block;
  font-size: 20px;
  padding: 10px 25px;
  margin: 0 5px;
  text-align: center;
  border-radius: 4px;
  background-color: ${({ active }) =>
    active ? theme.colors.white : theme.colors.primary};
  color: ${({ active }) =>
    active ? theme.colors.primary : theme.colors.white};
`

export {
  DateTimePickerContainer,
  FlexColumn,
  ReportHeaderBaseInfoContainer,
  ReportHeaderContainer,
  ReportHeaderDateItem,
  ReportHeaderInjuryNumber,
  ReportHeaderInjuryTitle,
  ReportHeaderTitle,
  ReportStepperContainer,
  StepItem
}
