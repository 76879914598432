import { translate as t } from '../i18n'

export const getInitials = (name: string) => {
  if (!name) return ''

  const names = name && name.split(' ')
  let initials = ''

  if (names) {
    initials =
      names.length > 1
        ? names[0].substring(0, 1).toUpperCase() +
          names[1].substring(0, 1).toUpperCase()
        : names[0].substring(0, 1).toUpperCase()
  }

  return initials
}

export const getPainLevel = (value: number) => {
  const _painLevel = parseInt(value.toFixed(0), 10)
  switch (_painLevel) {
    case 0:
      return t('noPain')

    case 1:
    case 2:
    case 3:
      return t('low')

    case 4:
    case 5:
    case 6:
      return t('moderate')

    case 7:
    case 8:
    case 9:
    case 10:
      return t('loud')
  }
}

export const toCamelCase = (value?: string) => {
  let formatted = ''

  if (value && value.length > 0) {
    const aux = value.replaceAll(',', '').split(' ')
    if (aux.length > 0) {
      for (const key of aux) {
        formatted +=
          (aux.indexOf(key) === 0
            ? key.substring(0, 1).toLowerCase()
            : key.substring(0, 1).toUpperCase()) + key.substring(1, key.length)
      }
    } else {
      formatted =
        value.substring(0, 1).toLowerCase() + value.substring(1, value.length)
    }
  }

  return formatted
}

interface FilterProps {
  [key: string]: any
  keyName: string
}

export const filterPropsByValue = (
  obj: any,
  value: string | boolean | number
) => {
  const fields: FilterProps[] = []

  if (!obj) return fields

  const other = {
    keyName: 'other',
    other: null as unknown as string | boolean | number
  }

  for (const key of Object.keys(obj)) {
    if (obj[key] === value) {
      if (key === 'other') {
        other[key] = value
      } else {
        fields.push({
          [key]: value,
          keyName: key
        })
      }
    }
  }
  if (other.other) {
    fields.push(other)
  }

  return fields
}
