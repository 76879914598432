import styled from 'styled-components'

const PageHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  width: 100%;
`

const PageHeaderContent = styled.div<{ vertical?: boolean }>`
  display: flex;
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};
  align-items: ${props => (props.vertical ? 'flex-end' : 'center')};
  justify-content: flex-start;
`

const PageHeaderTextWrap = styled.div<{ nowrap?: boolean }>`
  ${props => (props.nowrap ? '' : 'flex-basis: 70%;')}
  text-align: left;
  font-size: 37px;
  font-weight: 600;
  color: #2b2b2b;
`

const PageTitle = styled.h1`
  font-size: 37px;
  margin-top: 0;
  margin-bottom: 2rem;
`

export { PageHeaderContainer, PageHeaderContent, PageHeaderTextWrap, PageTitle }
