import { t } from 'i18n'
import { SelectHTMLAttributes } from 'react'
import { InputError, InputGroup, InputLabel, SelectContainer } from './styled'

type OptionItem = {
  id: string | number
  label: string | number
}

interface ISelectInputProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label: string
  options: OptionItem[]
  translateText?: boolean
  labelStyle?: any
  meta?: {
    error?: string
    touched?: boolean
  }
}

const SelectInput = ({
  label,
  options,
  translateText = true,
  labelStyle,
  meta,
  ...props
}: ISelectInputProps) => {
  return (
    <InputGroup>
      <InputLabel style={labelStyle} htmlFor={props.name}>
        {t(label)}
      </InputLabel>
      <SelectContainer {...props}>
        <option value="" disabled hidden>
          {t('select')}
        </option>
        {options.map(({ id, label }) => (
          <option key={id} value={id}>
            {translateText ? t(label) : label}
          </option>
        ))}
      </SelectContainer>
      {meta?.touched && meta?.error && <InputError>{t(meta.error)}</InputError>}
    </InputGroup>
  )
}

export { SelectInput }
