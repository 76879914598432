import { RadioGroupInput, TextInput } from 'components'
import { ChangeEvent } from 'react'
import { If } from 'react-extras'
import { BikeEquipment, BikeEquipmentType } from 'store/ducks'
import { CommentContainer, InputGroup } from './styled'

type OptionItem = {
  id: string | boolean
  label: string | number
}

interface IBikeEquipmentInputProps {
  name: string
  value?: BikeEquipment
  options: OptionItem[]
  onChange: (name: string, value: string | boolean | null) => void
}

const BikeEquipmentInput = ({
  name,
  value,
  options,
  onChange
}: IBikeEquipmentInputProps) => {
  return (
    <>
      <RadioGroupInput
        name={`${name}.type`}
        value={value?.type}
        options={options}
        onChange={(name: string, value: string | boolean) => {
          onChange(`${name}.otherBikeEquipment`, null)
          onChange(name, value)
        }}
      />
      <If condition={value?.type === BikeEquipmentType.OTHER}>
        <CommentContainer>
          <InputGroup>
            <TextInput
              type="text"
              name="otherBikeEquipment"
              label="other"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                onChange(`${name}.otherBikeEquipment`, null)
                onChange(`${name}.otherBikeEquipment`, event.target.value)
              }}
              value={value?.otherBikeEquipment || ''}
            />
          </InputGroup>
        </CommentContainer>
      </If>
    </>
  )
}

export { BikeEquipmentInput }
