import { Skeleton } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { IconChevronBottom, IconChevronTop } from 'assets/images'
import { DISABLED_ROUTES } from 'constants/common'
import { useRole } from 'hooks'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getResortList } from 'services'
import { Role } from 'store/ducks'
import DisabledTooltip from './disabled-tooltip'
import {
  ResortItem,
  ResortWrapper,
  SelectedResort,
  TogglerContainer
} from './styled'

interface Props {
  name: string | undefined
  expanded: boolean
  changeResortRequest: (resortId: string) => void
}

const ChangeResort = ({ name, expanded, changeResortRequest }: Props) => {
  const [resortName, setResortName] = useState<string | undefined>(name)
  const [open, setOpen] = useState<boolean>(false)
  const { pathname } = useLocation()
  const isSuperAdmin = useRole([Role.SYS_ADMIN])
  const disabledRoutes = DISABLED_ROUTES.some(item => pathname.includes(item))

  const { isLoading, data = [] } = useQuery({
    queryKey: ['getResortList'],
    queryFn: () => getResortList()
  })

  useEffect(() => {
    if (!expanded && open) {
      setOpen(false)
    }
  }, [expanded, open])

  const selectResort = async (name: string, SK: string) => {
    if (disabledRoutes) return null
    setResortName(name)
    setOpen(false)
    if (resortName !== name) {
      changeResortRequest(SK)
    }
  }

  if (isLoading)
    return (
      <Skeleton
        sx={{ bgcolor: '#183176', marginBottom: '94px' }}
        variant="rectangular"
        width="100%"
        height={81}
        animation="wave"
      />
    )

  if (!isSuperAdmin)
    return (
      <div style={{ marginBottom: '94px' }}>
        <SelectedResort>
          {expanded ? resortName : resortName?.substring(0, 1)}
        </SelectedResort>
      </div>
    )

  return (
    <div style={{ marginBottom: '94px' }}>
      <DisabledTooltip disabled={disabledRoutes}>
        <SelectedResort
          onClick={
            expanded && !disabledRoutes ? () => setOpen(!open) : () => {}
          }
          style={{
            cursor: disabledRoutes
              ? 'not-allowed'
              : expanded
                ? 'pointer'
                : 'default'
          }}
        >
          {expanded ? resortName : resortName?.substring(0, 1)}
          {expanded && (
            <TogglerContainer>
              {open ? <IconChevronTop /> : <IconChevronBottom />}
            </TogglerContainer>
          )}
        </SelectedResort>
        <ResortWrapper $open={open}>
          {data.map(({ SK, name }) => (
            <ResortItem
              key={SK}
              onClick={() => selectResort(name, SK)}
              $active={name === resortName}
              $disabled={disabledRoutes}
            >
              {name}
            </ResortItem>
          ))}
        </ResortWrapper>
      </DisabledTooltip>
    </div>
  )
}

export default ChangeResort
