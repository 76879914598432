import styled from 'styled-components'
import { theme } from 'styles/theme'

const ErrorModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.lightGray};
  padding: 20px;
  width: 80%;
  text-align: center;

  svg {
    fill: ${theme.colors.red};
  }
`

export { ErrorModalContainer }
