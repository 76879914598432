import styled from 'styled-components'

const ContainerHorizontalWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 50px 0;
`

const HorizontalContentContainer = styled.div`
  display: flex;
  width: 100%;
  overflow-y: auto;
  flex-direction: row;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 0;
  }
  &::-webkit-scrollbar {
    width: 0; /* Mostly for vertical scrollbars */
    height: 0; /* Mostly for horizontal scrollbars */
  }
`

const ButtonScroll = styled.button`
  border: none;
  height: 160px;
  width: 40px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: transparent;
  cursor: pointer;
`

export { ButtonScroll, ContainerHorizontalWrapper, HorizontalContentContainer }
