import ForgotPasswordPage from 'pages/Auth/ForgotPassword'
import SignInPage from 'pages/Auth/SignIn'
import { ResortInformationPage } from 'pages/ResortInformation'
import { ReactNode } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Role } from 'store/ducks'
import StatisticPageProvider from '../context/StatisticPageContext'
import PendingAccountPage from '../pages/Auth/PendingAccount'
import NewUserPage from '../pages/NewUser'
import NotFoundPage from '../pages/NotFound'
import ReportArchive from '../pages/Report/Archive'
import ReportEdit from '../pages/Report/Edit'
import ReportLandingPage from '../pages/Report/Landing'
import ReportOverviewPage from '../pages/ReportOverview'
import SlopesPage from '../pages/Slopes'
import SlopeCreatePage from '../pages/Slopes/Create'
import SlopeUpdatePage from '../pages/Slopes/Update'
import StatisticsPage from '../pages/Statistics'
import UserEditPage from '../pages/UserEdit'
import UsersPage from '../pages/Users'
import SettingsPage from '../pages/settings'

const homeRoute = {
  path: '/',
  element: <Navigate to="/reports" />,
  index: true
}

const statisticsRoute = {
  path: 'statistics',
  element: (
    <StatisticPageProvider>
      <StatisticsPage />
    </StatisticPageProvider>
  ),
  roles: [Role.RESORT_ADMINISTRATOR, Role.SYS_ADMIN]
}
const reportsRoute = {
  path: 'reports/*',
  element: (
    <Routes>
      <Route index element={<ReportLandingPage />} />
      <Route path="edit/:reportId" element={<ReportEdit />} />
      <Route path="archive/:reportId" element={<ReportArchive />} />
    </Routes>
  )
}
const reportOverviewRoute = {
  path: 'report-overview/:reportId',
  element: <ReportOverviewPage />,
  roles: [Role.RESORT_ADMINISTRATOR, Role.SYS_ADMIN]
}
const userRoute = {
  path: 'user/:id',
  element: <UserEditPage />,
  roles: [Role.RESORT_ADMINISTRATOR, Role.SYS_ADMIN]
}
const usersRoute = {
  path: 'users',
  element: <UsersPage />,
  roles: [Role.RESORT_ADMINISTRATOR, Role.SYS_ADMIN]
}
const slopesRoute = {
  path: 'slopes/*',
  element: (
    <Routes>
      <Route index element={<SlopesPage />} />
      <Route path="create" element={<SlopeCreatePage />} />
      <Route path="edit/:id" element={<SlopeUpdatePage />} />
    </Routes>
  ),
  roles: [Role.RESORT_ADMINISTRATOR, Role.SYS_ADMIN]
}
const settingsRoute = {
  path: 'settings',
  element: <SettingsPage />
}
const pendingAccountRoute = {
  path: 'pending-account',
  element: <PendingAccountPage />
}
const newUserRoute = { path: 'new-user', element: <NewUserPage /> }
const resortInformationRoute = {
  path: 'resort-information',
  element: <ResortInformationPage />,
  roles: [Role.RESORT_ADMINISTRATOR, Role.SYS_ADMIN]
}
const signInRoute = { path: 'sign-in', element: <SignInPage />, isPublic: true }
const forgotPasswordRoute = {
  path: 'forgot-password',
  element: <ForgotPasswordPage />,
  isPublic: true
}
const notFoundRoute = { path: '*', element: <NotFoundPage />, isPublic: true }

interface RouteDataProps {
  path: string
  element: ReactNode
  roles?: Role[]
  index?: boolean
  isPublic?: boolean | false
}

const routeData: RouteDataProps[] = [
  homeRoute,
  statisticsRoute,
  reportsRoute,
  reportOverviewRoute,
  userRoute,
  usersRoute,
  slopesRoute,
  settingsRoute,
  pendingAccountRoute,
  newUserRoute,
  resortInformationRoute,
  signInRoute,
  forgotPasswordRoute,
  notFoundRoute
]

export default routeData
