import { IconClose, IconComment, IconSave } from 'assets/images'
import { Button, TextAreaInput, TextInput } from 'components'
import { useFormikContext } from 'formik'
import { t } from 'i18n'
import { ChangeEvent, useState } from 'react'
import { Comment, Report, SectionKey } from 'store/ducks'
import { v4 as uuid } from 'uuid'
import { ModalOverlay } from '../../../../../styles/global'
import { MemoList, MemoListItem, MemoModal, ModalCloseIcon } from './styled'

interface IMemoItemProps {
  sectionKey: SectionKey
}

const MemoItem = ({ sectionKey }: IMemoItemProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [title, setTitle] = useState<string>('')
  const [comment, setComment] = useState<string>('')

  const { values, setFieldValue } = useFormikContext<Report>()

  const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = event
    setTitle(value)
  }

  const handleChangeComment = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const {
      target: { value }
    } = event
    setComment(value)
  }

  const handleSave = () => {
    const commentObject = {
      id: uuid(),
      source: sectionKey,
      subsection: '',
      title,
      comment
    }

    if (values?.comments) {
      setFieldValue('comments', [...values.comments, commentObject])
    } else {
      setFieldValue('comments', [commentObject])
    }

    setOpen(false)
    setTitle('')
    setComment('')
  }

  const renderComments = () => {
    const commentFilter = (comment: Comment) => comment.section === sectionKey

    switch (sectionKey) {
      case SectionKey.INJURY:
        return (
          <MemoList>
            {values?.comments
              ?.filter(commentFilter)
              .map(({ title, comment }, index) => (
                <MemoListItem key={index}>
                  <h4>{title}</h4>
                  {comment}
                </MemoListItem>
              ))}
          </MemoList>
        )
      case SectionKey.SEQUENCE_OF_EVENTS:
        return (
          <MemoList>
            {values?.comments
              ?.filter(commentFilter)
              .map(({ title, comment }, index) => (
                <MemoListItem key={index}>
                  <h4>{title}</h4>
                  {comment}
                </MemoListItem>
              ))}
          </MemoList>
        )
      default:
        return (
          <MemoList>
            {values?.comments
              ?.filter(commentFilter)
              .map(({ title, comment }, index) => (
                <MemoListItem key={index}>
                  <h4>{title}</h4>
                  {comment}
                </MemoListItem>
              ))}
          </MemoList>
        )
    }
  }

  const toggleOpen = () => {
    setOpen(!open)
  }

  return (
    <div>
      <IconComment onClick={toggleOpen} />
      {open && (
        <ModalOverlay>
          <MemoModal>
            <h2>{t(sectionKey)}</h2>
            <ModalCloseIcon onClick={toggleOpen}>
              <IconClose />
            </ModalCloseIcon>
            <TextInput
              type="text"
              label="title"
              value={title}
              onChange={handleChangeTitle}
            />
            <TextAreaInput
              label="writeComment"
              value={comment}
              onChange={handleChangeComment}
            />
            <Button
              type="button"
              label="saveInformation"
              icon={<IconSave />}
              onClick={handleSave}
            />
            {renderComments()}
          </MemoModal>
        </ModalOverlay>
      )}
    </div>
  )
}

export default MemoItem
