import { IconArrowRight, IconCamera, IconCircleX } from 'assets/images'
import { Button, ImageInput } from 'components'
import { useFormikContext } from 'formik'
import { t } from 'i18n'
import { ChangeEvent } from 'react'
import { If } from 'react-extras'
import { PersonalInformationConsent, Report, SectionKey } from 'store/ducks'
import { FlexRow, RowItem } from '../../styled'
import {
  ConsentPersonalInfoButtonContainer,
  ConsentPersonalInfoContainer,
  ConsentTextArea
} from './styled'

const ConsentPersonalInfoSection = () => {
  const {
    values: { personalInformation, SK },
    setFieldValue
  } = useFormikContext<Report>()

  const { personalInformationConsent } = personalInformation

  const handleConsent = (consentType: PersonalInformationConsent) => {
    setFieldValue('personalInformation.personalInformationConsent', consentType)

    switch (consentType) {
      case PersonalInformationConsent.PERMISSION_CONSENTED:
      case PersonalInformationConsent.CONSENT_TO_WRITTEN_REPORT:
        setFieldValue('personalInformation.permissionDenied', false)
        break
      case PersonalInformationConsent.NOT_RESPONSIVE_CANT_GET_CONSENT:
      case PersonalInformationConsent.PATIENT_HAS_NOT_GIVEN_CONSENT:
        setFieldValue('personalInformation.permissionDenied', true)
        break
    }
  }

  const handleConsentComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setFieldValue(
      'personalInformation.personalInformationDeniedExplanation',
      e.target.value
    )

    setFieldValue(
      'personalInformation.personalInformationDeniedExplanation',
      e.target.value
    )
  }

  return (
    <ConsentPersonalInfoContainer>
      <ConsentPersonalInfoContainer>
        <ConsentPersonalInfoButtonContainer>
          <Button
            type="button"
            icon={<IconArrowRight />}
            variant={
              personalInformationConsent ===
              PersonalInformationConsent.PERMISSION_CONSENTED
                ? 'primary'
                : 'default'
            }
            onClick={() =>
              handleConsent(PersonalInformationConsent.PERMISSION_CONSENTED)
            }
            label={'consentReceived'}
          />
        </ConsentPersonalInfoButtonContainer>
        <ConsentPersonalInfoButtonContainer>
          <Button
            type="button"
            variant={
              personalInformationConsent ===
              PersonalInformationConsent.NOT_RESPONSIVE_CANT_GET_CONSENT
                ? 'primary'
                : 'default'
            }
            icon={<IconCircleX />}
            onClick={() =>
              handleConsent(
                PersonalInformationConsent.NOT_RESPONSIVE_CANT_GET_CONSENT
              )
            }
            label={'notResponsiveCantGetConsent'}
          />
        </ConsentPersonalInfoButtonContainer>
        <ConsentPersonalInfoButtonContainer>
          <Button
            type="button"
            variant={
              personalInformationConsent ===
              PersonalInformationConsent.CONSENT_TO_WRITTEN_REPORT
                ? 'primary'
                : 'default'
            }
            icon={<IconCamera />}
            onClick={() =>
              handleConsent(
                PersonalInformationConsent.CONSENT_TO_WRITTEN_REPORT
              )
            }
            label={'consentToWrittenReport'}
          />
          <If
            condition={
              personalInformationConsent ===
              PersonalInformationConsent.CONSENT_TO_WRITTEN_REPORT
                ? true
                : false
            }
          >
            <FlexRow>
              <RowItem width={15}>
                <ImageInput
                  name="personalInformation.writtenConsent"
                  label="uploadPicture"
                  sectionKey={SectionKey.INJURY}
                  reportId={SK}
                  images={personalInformation.writtenConsent}
                  setImage={pictures =>
                    setFieldValue(
                      'personalInformation.writtenConsent',
                      pictures
                    )
                  }
                />
              </RowItem>
            </FlexRow>
          </If>
        </ConsentPersonalInfoButtonContainer>
        <ConsentPersonalInfoButtonContainer>
          <Button
            type="button"
            icon={<IconCircleX />}
            variant={
              personalInformationConsent ===
              PersonalInformationConsent.PATIENT_HAS_NOT_GIVEN_CONSENT
                ? 'primary'
                : 'default'
            }
            onClick={() =>
              handleConsent(
                PersonalInformationConsent.PATIENT_HAS_NOT_GIVEN_CONSENT
              )
            }
            label={'patientHasNotGivenConsent'}
          />
          <If
            condition={
              personalInformationConsent ===
              PersonalInformationConsent.PATIENT_HAS_NOT_GIVEN_CONSENT
                ? true
                : false
            }
          >
            <small>{t('')}</small>
            <ConsentTextArea
              value={personalInformation.personalInformationDeniedExplanation}
              onChange={handleConsentComment}
            />
          </If>
        </ConsentPersonalInfoButtonContainer>
      </ConsentPersonalInfoContainer>
    </ConsentPersonalInfoContainer>
  )
}

export default ConsentPersonalInfoSection
