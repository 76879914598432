import { z } from 'zod'

export const passwordSchema = z
  .string()
  .min(8, 'must be minimum 8 characters')
  .regex(/\d/, { message: 'must contain 1 number' })
  .regex(/[A-Z]/, { message: 'must contain 1 uppercase letter' })
  .regex(/[a-z]/, { message: 'must contain 1 lowercase letter' })
  .regex(/[!@#$%^&*(),.?":{}|<>]/, {
    message:
      'must contain one of this: ! @ # $ % ^ & * (  ) , . ? " : {  } | < >'
  })

export const phoneNumberSchema = z
  .string()
  .min(7, 'contains at least seven digits')
  .max(15, 'contains at most fifteen digits')

export const nameSchema = z
  .string()
  .min(2, 'must be minimum 2 characters')
  .max(50, 'must be maximum 50 characters')
  .regex(/^[\p{L}\s]+$/u, 'must contain only letters')

export const emailSchema = z
  .string()
  .min(1, 'is required')
  .email('is invalid email address')
