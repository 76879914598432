import { z } from 'zod'
import {
  passwordSchema,
  phoneNumberSchema
} from '../../../schema/common.schema'

export const signInSchema = z.object({
  phoneNumber: phoneNumberSchema,
  password: passwordSchema
})
