import { get, uniqueId } from 'lodash'
import React from 'react'
import { DataCell, TableRow } from './styles'
import { ColumnProps } from './types'

interface TableBodyProps {
  data: any
  columns: ColumnProps[]
}

interface RenderCellProps {
  item: any
  column: ColumnProps
}

const TableBody = ({ data, columns }: TableBodyProps) => {
  const renderCell: React.FC<RenderCellProps> = (item, column) => {
    if (column.content) return column.content(item)

    return get(item, column.path) ? get(item, column.path) : ''
  }

  const createId = () => {
    return uniqueId('dataCell_')
  }

  return (
    <tbody>
      {data.map((item: any, index: number) => (
        <TableRow key={index}>
          {columns.map(column => {
            return (
              <DataCell key={createId()}>
                <span>{renderCell(item, column)}</span>
              </DataCell>
            )
          })}
        </TableRow>
      ))}
    </tbody>
  )
}

export default TableBody
