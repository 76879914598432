import { Reducer } from 'redux'
import { GetReportsTypes, ReportsState } from './types'

const INITIAL_STATE: ReportsState = {
  data: [],
  loading: false,
  error: false,
  errorStack: undefined
}

const reducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GetReportsTypes.GET_REPORTS_REQUEST:
      return { ...state, loading: true, error: false, errorStack: undefined }
    case GetReportsTypes.GET_REPORTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        errorStack: undefined
      }
    case GetReportsTypes.GET_REPORTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorStack: action.payload.errorStack
      }
    default:
      return state
  }
}

export default reducer
