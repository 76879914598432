import {
  AppBar,
  Button,
  Dialog,
  Grid,
  IconButton,
  InputBase,
  Toolbar
} from '@mui/material'
import { IconClose, IconEdit, IconPlusCircle } from 'assets/images'
import { Container, Loading, MaterialModal, Table } from 'components'
import { ColumnProps } from 'components/Table/types'
import useUser from 'hooks/useUser'
import { t } from 'i18n'
import { ButtonContainer, ColumnTitle } from 'pages/settings/styled'
import React, { useCallback, useEffect, useState } from 'react'
import { If } from 'react-extras'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Dispatch, bindActionCreators } from 'redux'
import { getSlopesList, putFileS3, uploadHeatmap } from 'services'
import { Seasons, Slope, UserInfo } from 'store/ducks'
import * as AuthActions from 'store/ducks/auth/actions'
import { theme } from 'styles/theme'
import { ApplicationState } from '../../store'
import {
  AddSlopeButton,
  AddSlopeButtonLabel,
  ButtonsContainer,
  EditButton,
  ImageWrapper,
  InputLabel,
  NoImageBox,
  PageTitle,
  RadioInputLabel,
  SmallImgPreview,
  StyledLoading
} from './styled'

interface Props {
  user: UserInfo
}

enum Key {
  NAME = 'name',
  TYPES = 'types'
}

const PreviewImage = ({
  preview,
  season
}: {
  preview: string
  season: string
}) => {
  const [fullSize, setFullSize] = useState(false)

  if (!preview)
    return (
      <NoImageBox>
        <span>{t('noImage')}</span>
      </NoImageBox>
    )

  return (
    <>
      <SmallImgPreview
        src={preview}
        alt={t('preview')}
        onClick={() => setFullSize(true)}
      />
      <Dialog open={fullSize} fullScreen>
        <AppBar
          sx={{
            position: 'relative',
            flexDirection: 'row',
            paddingLeft: '2rem'
          }}
        >
          <h2>{t('heatmap', { season: t(season) })}</h2>
          <Toolbar style={{ marginLeft: 'auto' }}>
            <IconButton
              autoFocus
              edge="start"
              color="inherit"
              onClick={() => setFullSize(false)}
              aria-label="close"
            >
              <IconClose />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div
          style={{
            display: 'flex',
            margin: '0 auto',
            position: 'relative'
          }}
        >
          <img
            alt={t('preview')}
            src={preview}
            style={{
              maxHeight: '100%',
              maxWidth: '100%'
            }}
          />
        </div>
      </Dialog>
    </>
  )
}

const HandleUploadImage = ({
  fileName,
  season
}: {
  fileName: string | undefined
  season: string
}) => {
  const [preview, setPreview] = useState('')
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const handleUpload = useCallback(async () => {
    if (fileName) {
      setLoading(true)
      try {
        const body = {
          fileName: fileName || '',
          type: 'GET_OBJECT',
          relatedRecordType: 'RESORT',
          season
        }
        const data = await uploadHeatmap(body)
        setPreview(data.url)
      } catch (error: any) {
        toast.error(error.response.data.message, {
          position: 'top-right'
        })
      } finally {
        setLoading(false)
      }
    }
  }, [fileName, season])

  useEffect(() => {
    const handleUploadData = async () => {
      await handleUpload()
    }

    handleUploadData().catch(error => {
      console.error(error)
    })
  }, [handleUpload])

  const handleFileUpload = async (e: any) => {
    const file = e?.target?.files?.[0]

    if (e.target.files && e.target.files.length) {
      setPreview(URL.createObjectURL(file))

      const body = {
        fileName: file?.name || '',
        type: 'PUT_OBJECT',
        relatedRecordType: 'RESORT',
        season
      }
      const data = await uploadHeatmap(body)

      await putFileS3(file, data.url)

      setModalOpen(false)
    }
  }

  const handleImage = (flag?: boolean) => {
    return (
      <Button
        variant="contained"
        component="label"
        disabled={loading}
        sx={{
          color: 'white',
          height: '40px',
          backgroundColor: `${theme.colors.primary}`,
          boxShadow: 'none',
          textTransform: 'none',
          '&:hover': {
            boxShadow: 'none'
          }
        }}
      >
        {flag ? t('changeImage') : t('uploadImage')}
        <input
          type="file"
          hidden
          onChange={handleFileUpload}
          accept="image/png, image/jpg, image/jpeg, image/bmp, image/gif, image/webp"
        />
      </Button>
    )
  }

  return (
    <div>
      <h4>{t('heatmap', { season: t(season) })}</h4>
      <Grid container direction="row" alignItems="flex-end" gap={3}>
        <Grid>
          <ImageWrapper>
            {loading && <StyledLoading />}
            <PreviewImage preview={preview} season={season} />
          </ImageWrapper>
          <InputBase
            type="file"
            onChange={handleFileUpload}
            style={{ display: 'none' }}
            inputProps={{ 'aria-label': 'file upload' }}
          />
        </Grid>
        {preview ? (
          <Button
            variant="contained"
            onClick={() => setModalOpen(true)}
            sx={{
              color: 'white',
              height: '40px',
              backgroundColor: `${theme.colors.primary}`,
              boxShadow: 'none',
              textTransform: 'none',
              '&:hover': {
                boxShadow: 'none'
              }
            }}
          >
            {t('changeImage')}
          </Button>
        ) : (
          handleImage()
        )}
        <MaterialModal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
          <ColumnTitle>
            {t('doYouWantToChangeHeatmap', { season: t(season) })}
          </ColumnTitle>
          <p>{t('ifYouUploadNewMap')}</p>
          <ButtonContainer>
            <Button onClick={() => setModalOpen(false)}>{t('cancel')}</Button>
            {handleImage(true)}
          </ButtonContainer>
        </MaterialModal>
      </Grid>
    </div>
  )
}

const SlopesPage = ({ user }: Props) => {
  const { resort } = user || {}
  const [filterBy, setFilterBy] = useState<Seasons | 'ALL'>('ALL')
  const [searchValue, setSearchValue] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [slopes, setSlopes] = useState<Slope[]>([])
  const { refetch: loadUser } = useUser()

  const columns: ColumnProps[] = [
    {
      path: 'name',
      label: t('name')
    },
    {
      content: ({ types }: Slope) => <>{types.join(', ')}</>,
      label: t('season')
    },
    {
      path: 'difficulty',
      label: t('difficulty')
    },

    {
      content: ({ id }: Slope) => (
        <>
          <EditButton to={`edit/${id}`}>
            <IconEdit fill="#2b2b2b" width={20} />
          </EditButton>
        </>
      ),
      label: t('edit')
    }
  ]

  const getAllSlopes = useCallback(async () => {
    setLoading(true)
    const slopes = await getSlopesList()

    const newSlopes = slopes.map(slope => ({
      ...slope,
      types: slope.types.sort()
    }))

    setSlopes(newSlopes)
    setLoading(false)
  }, [])

  useEffect(() => {
    getAllSlopes()
  }, [getAllSlopes, user.resortId])

  useEffect(() => {
    loadUser()
  }, [loadUser])

  const handleBtnFilterClick = (e: React.FormEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget
    setFilterBy(name as Seasons | 'ALL')
  }

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    setSearchValue(value)
  }

  const handleOnSubmit = (e: React.FormEvent) => {
    e.preventDefault()
  }

  const filterData = (data: Slope[], filter: string, key: Key) => {
    if (key !== Key.TYPES) {
      return data.filter(item =>
        item[key].toLowerCase().includes(filter.toLowerCase())
      )
    }
    return data.filter(item => item[key].includes(filter as Seasons))
  }

  const filteredData =
    filterBy !== 'ALL' ? filterData(slopes, filterBy, Key.TYPES) : slopes

  const dataToRender = searchValue.length
    ? filterData(filteredData, searchValue, Key.NAME)
    : filteredData

  return (
    <Container>
      <PageTitle>{t('resortMap')}</PageTitle>
      <Grid display="flex" gap={4}>
        <HandleUploadImage
          fileName={resort?.heatmapBySeasons?.winter?.fileName}
          season={Seasons.WINTER.toLowerCase()}
        />
        <HandleUploadImage
          fileName={resort?.heatmapBySeasons?.summer?.fileName}
          season={Seasons.SUMMER.toLowerCase()}
        />
      </Grid>
      <PageTitle style={{ marginTop: '3rem' }}>{t('slopesOverview')}</PageTitle>
      <ButtonsContainer>
        <form onSubmit={handleOnSubmit}>
          <InputLabel>
            <input
              type="text"
              name="search"
              id="search"
              value={searchValue}
              onChange={handleOnChange}
              placeholder={t('searchSlope')}
            />
          </InputLabel>
          <RadioInputLabel className={filterBy === 'ALL' ? `active` : ''}>
            <input type="radio" name="ALL" onClick={handleBtnFilterClick} />
            {t('all')}
          </RadioInputLabel>
          <RadioInputLabel
            className={filterBy === Seasons.SUMMER ? `active` : ''}
          >
            <input
              type="radio"
              name={Seasons.SUMMER}
              onClick={handleBtnFilterClick}
            />
            {t('summer')}
          </RadioInputLabel>
          <RadioInputLabel
            className={filterBy === Seasons.WINTER ? `active` : ''}
          >
            <input
              type="radio"
              name={Seasons.WINTER}
              onClick={handleBtnFilterClick}
            />
            {t('winter')}
          </RadioInputLabel>
        </form>
        <AddSlopeButton to="create">
          <AddSlopeButtonLabel>{t('addSlope')}</AddSlopeButtonLabel>
          <IconPlusCircle width={20} fill="#2b2b2b" />
        </AddSlopeButton>
      </ButtonsContainer>
      <If condition={loading}>
        <Loading style={{ height: '20vh' }} />
      </If>
      <If condition={!loading && !!slopes.length}>
        <Table data={dataToRender} columns={columns} />
      </If>
    </Container>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.auth.data
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(AuthActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SlopesPage)
