import { IconPlusCircle } from 'assets/images'
import { t } from 'i18n'
import { ChangeEvent, InputHTMLAttributes, useRef, useState } from 'react'
import { getSignedUrl, putFileS3 } from 'services'
import { Media, SectionKey } from 'store/ducks'
import { v4 as uuid } from 'uuid'
import {
  InputCollapsableContainer,
  InputContainer,
  InputError,
  InputGroup,
  SuccessLabel
} from './styled'

type SignedURL = {
  url: string
}

interface IImageInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  images?: Media[]
  reportId?: string
  setImage: (images: Media[]) => void
  sectionKey: SectionKey
  meta?: {
    error?: string
    touched?: boolean
  }
}

const ImageInput = ({
  label,
  images,
  reportId,
  meta,
  setImage,
  sectionKey,
  ...props
}: IImageInputProps) => {
  const [imageUploaded, setImageUploaded] = useState<boolean>(false)

  const imageRef = useRef<HTMLInputElement>(null)

  const handleOpen = () => {
    setImageUploaded(false)
    imageRef?.current?.click()
  }

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0]
    try {
      if (!file || !reportId) return
      const imagePath = `images/${reportId}/${file?.name}`
      const signedUrl: SignedURL = await getSignedUrl(imagePath)
      await putFileS3(file, signedUrl.url)
      const newImage: Media = {
        id: uuid(),
        fileName: file?.name,
        type: 'PICTURE',
        fileSize: file?.size,
        path: imagePath,
        uploaded: true,
        section: sectionKey
      }
      if (!images) {
        setImage([newImage])
      } else {
        setImage([...images, newImage])
      }
      setImageUploaded(true)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <InputGroup>
      <InputCollapsableContainer active={false} onClick={handleOpen}>
        <p>{t(label)}</p>
        <IconPlusCircle />
      </InputCollapsableContainer>
      <InputContainer
        type="file"
        accept=".png, .jpg, .jpeg"
        ref={imageRef}
        onChange={handleUpload}
        {...props}
      />
      {imageUploaded && <SuccessLabel>{t`pictureUploaded`}</SuccessLabel>}
      {meta?.touched && meta?.error && <InputError>{t(meta.error)}</InputError>}
    </InputGroup>
  )
}

export { ImageInput }
