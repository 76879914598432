import styled from 'styled-components'

type ButtonProps = {
  $bgColor: BUTTON_COLOR
  $bordered?: boolean
}

export enum BUTTON_COLOR {
  RED = 'red',
  BLUE = 'blue'
}

const ReportOverViewContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ReportOverviewActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media only screen {
    padding: 0 200px 40px 40px;
  }
`

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  width: 400px;
`

export const StyledButton = styled.button<ButtonProps>`
  width: 100%;
  padding: 1rem;
  text-align: left;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  cursor: pointer;
  transition: 0.5s;

  background-color: ${({ theme, $bgColor, $bordered }) =>
    $bordered ? 'transparent' : theme.colors[$bgColor]};
  color: ${({ theme, $bordered, $bgColor }) =>
    $bordered ? theme.colors[$bgColor] : theme.colors.white};
  border: ${({ theme, $bgColor }) => `2px solid ${theme.colors[$bgColor]}`};

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    background-color: ${({ theme, $bgColor, $bordered }) =>
      $bordered ? theme.colors[$bgColor] : 'transparent'};
    color: ${({ theme, $bordered, $bgColor }) =>
      $bordered ? theme.colors.white : theme.colors[$bgColor]};
    border: ${({ theme, $bgColor }) => `2px solid ${theme.colors[$bgColor]}`};

    svg {
      * {
        fill: ${({ theme, $bordered, $bgColor }) =>
          $bordered ? theme.colors.white : theme.colors[$bgColor]};
      }
    }
  }

  svg {
    height: 20px;
    * {
      fill: ${({ theme, $bordered, $bgColor }) =>
        $bordered ? theme.colors[$bgColor] : theme.colors.white};
      border: none;
    }
  }
`

const Button = styled.button`
  border: none;
  outline: none;
  background: transparent;
  height: 35px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

const PrintReportButton = styled(Button)`
  border: 1px solid #2b2b2b;
  border-radius: 4px;
`

const ReportOverviewButtonLabel = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #2d2020;
`

const ReportOverviewHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen {
    padding: 0 200px 0 40px;
  }
`

const ReportOverviewContentContainer = styled.div<{ bgColor?: string }>`
  background-color: ${props => props.bgColor ?? 'transparent'};
  display: flex;
  flex-direction: row;
  @media only screen {
    padding: 40px 200px 40px 40px;
  }
`

const ReportOverviewVerticalContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  &:nth-child(1) {
    flex-grow: 1;
    padding: 0;
    margin-left: 0;
  }
`

const ReportOverviewLabel = styled.div<{
  color?: string
  size?: number
  weight?: number
  padding?: number | string
}>`
  color: ${props => props.color ?? '#2b2b2b'};
  font-size: ${props => props.size ?? '16'}px;
  font-weight: ${props => props.weight ?? 'normal'};
  padding: ${props => props.padding ?? '5px 0'};
  word-wrap: break-word;
`

const ReportOverviewInfoGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @media only screen {
    margin-top: 15px;
  }
`
const ReportOverviewBodyContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen {
    padding: 40px 200px 40px 40px;
  }

  @media only print {
    padding: 40px 0 !important;
  }
`

const GoogleMapContainer = styled.div`
  height: 90%;
  padding: 25px;
  width: 90%;
  border: 1px solid red;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
`
const ColContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const ReportOverviewBodyContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50%;
  flex-grow: 3;
  &:nth-child(1) {
    margin-right: 40px;
  }
`

const ReportOverviewCardContainer = styled.div`
  &:not(:nth-child(1)) {
    margin-top: 40px;
  }
`
const ReportOverviewCardHeaderContainer = styled.div`
  border-bottom: 2px solid #2b2b2b;
  @media only print {
    border-bottom: 1px solid #2b2b2b !important;
  }
`

const ReportOverviewCardContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const ReportOverViewPrintContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const PageBreak = styled.div`
  @media only print {
    display: block !important;
    page-break-before: always !important;
  }
`

const MonitoringCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  &:not(:first-child) {
    border-top: 1px dashed black;
    margin-top: 10px;
  }
`

const PrintButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  &:last-child() {
    margin-right: 10px;
    border: 1px solid red;
  }
`

const WitnessContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export {
  ColContainer,
  GoogleMapContainer,
  MonitoringCardContainer,
  PageBreak,
  PrintButtonContainer,
  PrintReportButton,
  ReportOverViewContainer,
  ReportOverViewPrintContainer,
  ReportOverviewActionContainer,
  ReportOverviewBodyContainer,
  ReportOverviewBodyContentContainer,
  ReportOverviewButtonLabel,
  ReportOverviewCardContainer,
  ReportOverviewCardContentContainer,
  ReportOverviewCardHeaderContainer,
  ReportOverviewContentContainer,
  ReportOverviewHeaderContainer,
  ReportOverviewInfoGroup,
  ReportOverviewLabel,
  ReportOverviewVerticalContent,
  RowContainer,
  WitnessContainer
}
