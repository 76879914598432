const {
  REACT_APP_AWS_REGION,
  REACT_APP_COGNITO_IDENTITY_POOL,
  REACT_APP_USER_POOL_ID,
  REACT_APP_CLIENT_ID
} = process.env

const awsConfig = {
  aws_project_region: REACT_APP_AWS_REGION,
  aws_cognito_identity_pool_id: REACT_APP_COGNITO_IDENTITY_POOL,
  aws_cognito_region: REACT_APP_AWS_REGION,
  aws_user_pools_id: REACT_APP_USER_POOL_ID,
  aws_user_pools_web_client_id: REACT_APP_CLIENT_ID,
  oauth: {}
}

export default awsConfig
