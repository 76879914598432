import {
  CheckboxGroupInput,
  ImageGalleryItem,
  ImageInput,
  TextAreaInput,
  WitnessInput
} from 'components'
import {
  INJURY_LOCATION_OPTIONS,
  INJURY_LOCATION_SUMMER_OPTIONS,
  SEQUENCE_OF_EVENTS_SUMMER,
  SEQUENCE_OF_EVENTS_WINTER
} from 'constants/options'
import { FieldArray, useFormikContext } from 'formik'
import { t } from 'i18n'
import { Report, Seasons, SectionKey } from 'store/ducks'
import { ImageGalleryContainer } from '../FormTitle/styled'
import FormTitle from '../common/../FormTitle'
import { FlexRow, FormSectionSubtitle, RowItem } from '../styled'

const SequenceOfEventsSection = () => {
  const {
    values: { SK, sequenceOfEvents, personalInformation, season },
    setFieldValue
  } = useFormikContext<Report>()

  const patientName =
    personalInformation?.firstName && personalInformation?.lastName
      ? `${personalInformation?.firstName} ${personalInformation?.lastName}`
      : ''

  return (
    <>
      <FormTitle
        title="sequenceOfEvents"
        sectionKey={SectionKey.SEQUENCE_OF_EVENTS}
        hasDisclaimer
      />
      <FormSectionSubtitle>{t`uploadPicture`}</FormSectionSubtitle>
      <FlexRow>
        <RowItem width={15}>
          <ImageInput
            name="sequenceOfEvents.pictures"
            label="uploadPicture"
            sectionKey={SectionKey.SEQUENCE_OF_EVENTS}
            reportId={SK}
            images={sequenceOfEvents?.pictures}
            setImage={pictures =>
              setFieldValue('sequenceOfEvents.pictures', pictures)
            }
          />
        </RowItem>
      </FlexRow>
      <FlexRow>
        <RowItem>
          <ImageGalleryContainer>
            {sequenceOfEvents?.pictures?.map((image, index) => (
              <ImageGalleryItem key={index} path={image.path} />
            ))}
          </ImageGalleryContainer>
        </RowItem>
      </FlexRow>
      <FormSectionSubtitle>{t`whereDidTheInjuryOccur`}</FormSectionSubtitle>
      <FlexRow>
        <RowItem>
          <CheckboxGroupInput
            name="sequenceOfEvents.injuryLocation"
            value={sequenceOfEvents?.injuryLocation}
            options={
              season === Seasons.WINTER
                ? INJURY_LOCATION_OPTIONS
                : INJURY_LOCATION_SUMMER_OPTIONS
            }
            onChange={setFieldValue}
          />
        </RowItem>
      </FlexRow>
      <FlexRow>
        <RowItem width={20}>
          <TextAreaInput
            name="sequenceOfEvents.eventExplanationLocation"
            value={sequenceOfEvents?.eventExplanationLocation}
            label="descriptionOfInjurySite"
            onChange={e =>
              setFieldValue(
                'sequenceOfEvents.eventExplanationLocation',
                e.target.value
              )
            }
            placeholder={t('clickHereToWriteMore')}
          />
        </RowItem>
      </FlexRow>
      <FormSectionSubtitle>{t`howDidTheInjuryOccur`}</FormSectionSubtitle>
      <FlexRow>
        <RowItem>
          <CheckboxGroupInput
            name="sequenceOfEvents"
            value={sequenceOfEvents}
            options={
              season === Seasons.WINTER
                ? SEQUENCE_OF_EVENTS_WINTER
                : SEQUENCE_OF_EVENTS_SUMMER
            }
            onChange={setFieldValue}
          />
        </RowItem>
      </FlexRow>
      <FlexRow>
        <RowItem width={20}>
          <TextAreaInput
            name="sequenceOfEvents.eventExplanation"
            value={sequenceOfEvents?.eventExplanation}
            label="sequenceOfEventsExplanation"
            onChange={e =>
              setFieldValue('sequenceOfEvents.eventExplanation', e.target.value)
            }
            placeholder={t('clickHereToWriteMore')}
          />
        </RowItem>
      </FlexRow>
      <FormSectionSubtitle>{t`witnesses`}</FormSectionSubtitle>
      <FlexRow>
        <RowItem width={20}>
          <FieldArray
            name="sequenceOfEvents.witnesses"
            render={arrayHelpers => (
              <WitnessInput
                label="addWitness"
                patientName={patientName}
                witnesses={sequenceOfEvents?.witnesses || []}
                add={arrayHelpers.push}
                update={arrayHelpers.replace}
                remove={arrayHelpers.remove}
              />
            )}
          />
        </RowItem>
      </FlexRow>
    </>
  )
}

export default SequenceOfEventsSection
