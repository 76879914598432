import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from 'styles/theme'

const SidebarContainer = styled.div<{ expanded: boolean }>`
  position: fixed;
  left: 0;
  width: ${({ expanded }) =>
    expanded ? `${theme.SIDEBAR_WIDTH_MAX}px` : `${theme.SIDEBAR_WIDTH_MIN}px`};
  background-color: ${theme.colors.primary};
  box-shadow: -10px 3px 30px 15px rgba(0, 0, 0, 0.15);
  min-height: 100vh;
  transition: width 0.5s;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 100%;
`

const TogglerContainer = styled.div`
  width: 20px;
`

const SidebarItem = styled(NavLink)`
  display: flex;
  align-items: center;
  min-height: 45px;
  padding: 9px 25px 9px 30px;
  font-size: 1.2rem;
  color: ${theme.colors.white};
  text-decoration: none;
  white-space: nowrap;
  transition: 0.2s;

  svg {
    flex-shrink: 0;
    margin-right: 1rem;
  }

  &.active {
    color: ${theme.colors.primary};
    background: ${theme.colors.white};
    cursor: default;
    svg {
      fill: ${theme.colors.primary} !important;
    }
  }
  &:hover:not(.active) {
    background: rgba(255, 255, 255, 0.2);
  }
`

const SidebarHeader = styled.h2`
  font-size: ${theme.textSizes.largest};
  padding: 25px;
  padding-bottom: 75px;
  color: ${theme.colors.white};
`

const RadioInputLabel = styled.label<{
  $disabled?: boolean
  $expanded: boolean
}>`
  cursor: pointer;
  align-items: center;
  max-height: 38px;
  height: 100%;
  padding: 8px 12px;
  margin-left: 18px;
  white-space: nowrap;
  transition: all 0.2s ease 0s;
  gap: 1rem;
  font-size: ${theme.textSizes.small};
  width: ${({ $expanded }) => ($expanded ? '123px' : 'max-content')};
  background-color: ${theme.colors.primary};
  color: ${theme.colors.white};
  border-radius: 0.5rem;
  display: ${({ $expanded }) => ($expanded ? 'flex' : 'none')};

  input[type='radio'],
  input[type='checkbox'] {
    display: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
  }

  &.active {
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
    border: none;
    cursor: default;
    display: flex;
    height: max-content;
  }

  &:hover:not(.active) {
    background: rgba(255, 255, 255, 0.2);
  }

  ${props =>
    props.$disabled && {
      pointerEvents: 'none',
      opacity: 0.5
    }}
`

const DefaultSeasonWrapper = styled.div<{
  $expanded: boolean
}>`
  display: flex;
  align-items: ${({ $expanded }) => ($expanded ? 'flex-end' : 'initial')};
  width: 100%;
  flex-direction: ${({ $expanded }) => ($expanded ? 'row' : 'column-reverse')};
  margin-top: auto;
  margin-bottom: 19px;
`

const CogWrapper = styled.div<{
  $expanded: boolean
}>`
  margin: ${({ $expanded }) =>
    $expanded ? '0 25px 0 auto' : '0 auto 1rem auto'};
  cursor: pointer;
  border-radius: 8px;
`

const ResortWrapper = styled.div<{
  $open: boolean
}>`
  display: ${({ $open }) => ($open ? 'block' : 'none')};
`

const ResortItem = styled.div<{
  $active: boolean
  $disabled: boolean
}>`
  display: flex;
  align-items: center;
  height: 45px;
  padding: 9px 25px 9px 30px;
  font-size: 1.2rem;
  color: ${theme.colors.white};
  white-space: nowrap;
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    ${({ $active }) => {
      if (!$active) {
        return `
          background: rgba(255, 255, 255, 0.2);
        `
      }
    }};
  }
  ${({ $active }) => {
    if ($active) {
      return `
        background-color: ${theme.colors.white};
        color: ${theme.colors.black};
        cursor: default;
      `
    }
  }}
  ${({ $disabled }) => {
    if ($disabled) {
      return `
        cursor: not-allowed;
      `
    }
  }}
  }};
`

const SelectedResort = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  height: 81px;
  padding: 0 25px 0 30px;
  font-size: 1.2rem;
  color: ${theme.colors.white};
  justify-content: space-between;
  white-space: nowrap;
  gap: 1rem;
  transition: 0.2s;
  background-color: #1e3573;
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : '')};
`

export {
  CogWrapper,
  DefaultSeasonWrapper,
  RadioInputLabel,
  ResortItem,
  ResortWrapper,
  SelectedResort,
  SidebarContainer,
  SidebarHeader,
  SidebarItem,
  TogglerContainer
}
