import React from 'react'
import { TabBarContainer, TabBarList } from './styled'

const Tabs = ({
  children,
  ...props
}: {
  style?: React.CSSProperties
  children: React.ReactNode
}) => {
  return (
    <TabBarContainer {...props}>
      <TabBarList>{children}</TabBarList>
    </TabBarContainer>
  )
}

export default Tabs
