import styled from 'styled-components'

const PendingAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 97vh;
  align-items: center;
  justify-content: center;
  flex: 1 1;
`

const PendingAccountTitle = styled.div`
  font-size: 36px;
  color: #000100;
  font-weight: 500;
  padding: 10px;
`

const PendingAccountSubtitle = styled.div`
  font-size: 18px;
  color: #919191;
  margin-bottom: 75px;
  margin-top: 75px;
  padding: 10px;
`

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
`

const SignOutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
`

export {
  LogoContainer,
  PendingAccountContainer,
  PendingAccountSubtitle,
  PendingAccountTitle,
  SignOutContainer
}
