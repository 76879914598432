import _01d from 'assets/images/01d.png'
import _01n from 'assets/images/01n.png'

import _02d from 'assets/images/02d.png'
import _02n from 'assets/images/02n.png'

import _03d from 'assets/images/03d.png'
import _03n from 'assets/images/03n.png'

import _04d from 'assets/images/04d.png'
import _04n from 'assets/images/04n.png'

import _09d from 'assets/images/09d.png'
import _09n from 'assets/images/09n.png'

import _10d from 'assets/images/10d.png'
import _10n from 'assets/images/10n.png'

import _11d from 'assets/images/11d.png'
import _11n from 'assets/images/11n.png'

import _13d from 'assets/images/13d.png'
import _13n from 'assets/images/13n.png'

import _50d from 'assets/images/50d.png'
import _50n from 'assets/images/50n.png'

import styled from 'styled-components'

const StyledImage = styled.img<{
  marginImg: string
  widthImg: string
  heightImg: string
}>`
  width: ${props => (props.widthImg ? props.widthImg : '55px')};
  height: ${props => (props.heightImg ? props.heightImg : '55px')};
  margin: ${props => (props.marginImg ? props.marginImg : '-10px 0 0 0')};
`

interface Props {
  icon: string
  marginImg: string
  widthImg: string
  heightImg: string
}
const WeatherIcon = ({ icon, marginImg, widthImg, heightImg }: Props) => {
  const getIcon = () => {
    switch (icon) {
      case '01d':
        return _01d

      case '01n':
        return _01n

      case '02d':
        return _02d

      case '02n':
        return _02n

      case '03d':
        return _03d

      case '03n':
        return _03n

      case '04d':
        return _04d

      case '04n':
        return _04n

      case '09d':
        return _09d

      case '09n':
        return _09n

      case '10d':
        return _10d

      case '10n':
        return _10n

      case '11d':
        return _11d

      case '11n':
        return _11n

      case '13d':
        return _13d

      case '13n':
        return _13n

      case '50d':
        return _50d

      case '50n':
        return _50n
    }
  }

  return (
    <StyledImage
      widthImg={widthImg}
      heightImg={heightImg}
      marginImg={marginImg}
      src={getIcon()}
    />
  )
}

export { WeatherIcon }
