import { IconCamera, IconClose, IconSave } from 'assets/images'
import { Button, ImageGalleryItem, ImageInput } from 'components'
import { useFormikContext } from 'formik'
import { t } from 'i18n'
import { useState } from 'react'
import { Report, SectionKey } from 'store/ducks'
import { ModalOverlay } from '../../../../../styles/global'
import { FlexRow } from '../styled'
import { ImageGalleryContainer, ImageModal, ModalCloseIcon } from './styled'

interface IImageItemProps {
  sectionKey: SectionKey
}

const ImageItem = ({ sectionKey }: IImageItemProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const { values, setFieldValue } = useFormikContext<Report>()

  const toggleOpen = () => {
    setOpen(!open)
  }

  const getFieldName = () => {
    switch (sectionKey) {
      case SectionKey.SEQUENCE_OF_EVENTS:
        return 'sequenceOfEvents.pictures'
      case SectionKey.INJURY:
        return 'injury.pictures'
      default:
        return 'pictures'
    }
  }

  const getImages = () => {
    switch (sectionKey) {
      case SectionKey.SEQUENCE_OF_EVENTS:
        return values.sequenceOfEvents?.pictures
      case SectionKey.INJURY:
        return values.injury?.pictures
      default:
        return values.pictures
    }
  }

  const addedImages = getImages()
  const sectionImages = addedImages?.filter(
    image => image.section === sectionKey
  )

  return (
    <div>
      <IconCamera onClick={toggleOpen} />
      {open && (
        <ModalOverlay>
          <ImageModal>
            <h2>{t(sectionKey)}</h2>
            <ModalCloseIcon onClick={toggleOpen}>
              <IconClose />
            </ModalCloseIcon>
            <ImageInput
              label="uploadPicture"
              name={getFieldName()}
              sectionKey={sectionKey}
              reportId={values.SK}
              images={addedImages}
              setImage={pictures => setFieldValue(getFieldName(), pictures)}
            />
            <ImageGalleryContainer>
              {sectionImages?.map((image, index) => (
                <ImageGalleryItem key={index} path={image.path} />
              ))}
            </ImageGalleryContainer>
            <FlexRow>
              <Button
                type="button"
                label="saveInformation"
                textSize="small"
                onClick={toggleOpen}
                icon={<IconSave />}
              />
            </FlexRow>
          </ImageModal>
        </ModalOverlay>
      )}
    </div>
  )
}

export default ImageItem
