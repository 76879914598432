import { uniqueId } from 'lodash'
import { TableHeader as StyledTableHeader, TableRow } from './styles'
import { ColumnProps, ColumnWithPathProps } from './types'

interface TableHeaderProps {
  columns: ColumnProps[]
}

const TableHeader = ({ columns }: TableHeaderProps) => {
  return (
    <thead>
      <TableRow>
        {columns.map(column => (
          <StyledTableHeader
            key={uniqueId(`${(column as ColumnWithPathProps).path}_`)}
          >
            {(column as ColumnWithPathProps).label}
          </StyledTableHeader>
        ))}
      </TableRow>
    </thead>
  )
}

export default TableHeader
