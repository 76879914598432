import moment from 'moment'
import { useEffect, useState } from 'react'

interface ILiveClockProps {
  format?: string
  value?: Date
  staticTime?: boolean
}

const LiveClock = ({
  format = 'HH:mm:ss',
  value,
  staticTime
}: ILiveClockProps) => {
  const [time, setTime] = useState<Date>(new Date())

  useEffect(() => {
    // eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
    let timer: NodeJS.Timeout
    if (staticTime) {
      value && setTime(value)
    } else {
      timer = setTimeout(() => {
        setTime(new Date())
      }, 1000)
    }
    return () => clearTimeout(timer)
  }, [staticTime, value, time])

  return <span>{moment(time).format(format)}</span>
}

export { LiveClock }
