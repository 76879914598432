import { t } from 'i18n'
import { InputHTMLAttributes } from 'react'
import { InputContainer, InputError, InputGroup, InputLabel } from './styled'

interface IPhoneInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  isNorwegian?: boolean
  meta?: {
    error?: string
    touched?: boolean
  }
}

const PhoneInput = ({
  label,
  isNorwegian = false,
  meta,
  ...props
}: IPhoneInputProps) => {
  return (
    <InputGroup>
      <InputLabel htmlFor={props.name}>{t(label)}</InputLabel>
      <InputContainer
        {...props}
        type="tel"
        placeholder={isNorwegian ? '+47 123 45 678' : '+132 56 728 920'}
        // pattern={
        //   isNorwegian
        //     ? '[+][0-9]{2}[ ][0-9]{3}[ ][0-9]{2}[ ][0-9]{3}'
        //     : '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[s./0-9]*$'
        // }
      />
      {meta?.touched && meta?.error && <InputError>{t(meta.error)}</InputError>}
    </InputGroup>
  )
}

export { PhoneInput }
